import React from 'react';
import { observer } from "mobx-react-lite"
import { FormControl, Grid } from "@mui/material"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import PrePaymentTable from "./PrePaymentTable"
import { useInstance } from "react-ioc"
import PrePaymentDataStore from "../../stores/prePayment.data.store"




const PrepaymentTab = observer((props) => {

  const store = useInstance(PrePaymentDataStore)

  return (
    <>
      <Grid container sx={{mb: 2, pr: 1, pl: 1}}>
        <Grid item xs={12}>
          <Typography
            variant={"h6"}
          >Предоплата</Typography>
        </Grid>
      </Grid>

      <Grid container sx={{pl: 1, gap: 2}} maxWidth="md">
        <Grid item xs={12} sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: 2
        }}
        >
          <Typography
            fullWidth
            sx={{maxWidth: {sm: "30%"}}}
          >
            Сумма предоплаты:
          </Typography>

          <TextField
            label={"₽"}
            focused
            sx={{maxWidth: {sm: "30%"}}}
            defaultValue={store.prepSumNew ? store.prepSumNew : 0}
            size="small"
            fullWidth
            type="number"
            inputProps={{max: store.totalSum, min: 0}}
            value={store.prepSumNew}
            onChange={(event) => store.setPrepSum(event.target.value)}
          />
        </Grid>

      </Grid>
      <Grid container sx={{
        pb: 2,
        pl: 1,
        pt: 4,
        boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",

      }}
            maxWidth="md"
      >
        <Grid item xs={12}
        >
          <Typography
            sx={{
              pb: 2
            }}
          >
            Комментарий предоплаты:
          </Typography>

        </Grid>

        <Grid xs={12} sm={12} md={10}>
          <FormControl fullWidth sx={{minWidth: 244}} size="small" focused>
            <TextField
              label={"Комментарий"}
              focused
              defaultValue={""}
              size="small"
              fullWidth
              type="text"
              multiline
              rows={4}
              value={store.prepNewComment}
              onChange={(event) => store.setPrepNewComment(event.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container sx={{pl: 1}}>
        <Grid item xs={12}
              sx={{
                pb: 2,
                pt: 2
              }}
        >
          <Typography variant={"h6"}>
            Планируемые услуги
          </Typography>
        </Grid>

        <Grid
          container sx={{mb: 2}}
          maxWidth="md"
        >

          <Grid item xs={12} sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            flexWrap: "wrap"
          }}>

            <Box>
              Сумма услуг
              <Typography
              component={"span"}
              variant={"subtitle1"}
              sx={{
                pl: 1
              }}
              >
                {/*{store.setTotalSum()}*/}
                {store.totalSum}{'\u00A0'}₽
              </Typography>
            </Box>

            <TextField
              sx={{maxWidth: {sm: "30%", xs: "100%", md: "20%"}, flexShrink: 0}}
              label={"процент предоплаты"}
              focused
              defaultValue={store.prepNewPercent ? store.prepNewPercent : 0}
              size="small"
              fullWidth
              type="text"
              value={store.prepNewPercent}
              onChange={(event) => store.setPrepNewPercent(event.target.value)}
            />

            <Button
              sx={{
                width: {xs: "100%", sm: "40%", md: "30%"}
              }}
              variant={"outlined"}
              onClick={() => {
                store.setSumWithDiscount(store.prepNewPercent)
              }}
            >
              <Typography variant="button" sx={{
                fontSize: "12px !important"
              }}>
                пересчитать предоплату
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>


      {store.allData.length > 0 &&
        <Grid container sx={{mb: 2, pt: 1, pl: 1, pr: 1}}>
          <Grid item xs={12}>
            <PrePaymentTable />
          </Grid>
        </Grid>
      }

    </>
  );
});

export default PrepaymentTab;