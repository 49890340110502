import React, { memo, useEffect } from 'react';
import { observer } from "mobx-react-lite"
import { useInstance } from "react-ioc"
import StoreChat from "./stores/storeChat"
import OneMessage from "./Components/OneMessage"
import SeveralMessages from "./Components/SeveralMessages"
import authStore from "../../_common/stores/auth.store"


const Chat = observer((props) => {

  const storeChat = useInstance(StoreChat)


  useEffect(() => {
    storeChat.getTemplates()

    if (authStore.medicalCenterId) {
      storeChat.getSpecialists(authStore.medicalCenterId)
    }
  }, [storeChat, authStore.medicalCenterId])


  useEffect(() => {
    storeChat.setChatMessages(authStore.user.id)
    storeChat.setChatMessagesEnd(authStore.user.id)
  }, [])


  return (
    <>
      {storeChat?.subscriptionData.length === 1 && <OneMessage />}

      <SeveralMessages />

    </>
  );
});

export default memo(Chat);