import { makeAutoObservable } from "mobx";



export default class StoreBackToCallsSchedule {

  state = {
    // centerId: "1",
    dateStart: undefined,
    dateEnd: undefined,
    // isActive: "",
    redirectFromPatientsPageToSchedule: false,
    currentDate: undefined,
    redirectFromPatient: false,
  };


  constructor() {
    makeAutoObservable(this)
  }

  setRedirectFromPatient(mode){

    this.state.redirectFromPatient = mode
  }

  get redirectFromPatient(){

    return this.state.redirectFromPatient
  }


  setDateStartToRedirect(dateStart){

    if(!!dateStart){
      this.state.dateStart = dateStart
    }
  }

  get dateStartToRedirect(){

    return this.state.dateStart
  }

  setDateEndToRedirect(dateEnd){

    if(!!dateEnd){
      this.state.dateEnd = dateEnd
    }
  }

  setRedirectFromPatientsPageToSchedule(mode){

    this.state.redirectFromPatientsPageToSchedule = mode
  }

  get redirectFromPatientsPageToSchedule(){

    return this.state.redirectFromPatientsPageToSchedule
  }

  get dateEndToRedirect(){

    return this.state.dateEnd
  }

  setResetRedirect() {
    this.state.dateStart = undefined
    this.state.dateEnd = undefined
    this.state.currentDate = undefined
    this.state.redirectFromPatientsPageToSchedule = false
  }

}