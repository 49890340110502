import {gql} from "@apollo/client";

export const STARTED_CALLS_SUBSCRIPTION = gql`
subscription NewCalls{
    callStarted{
      caller
      id
      
      date
      time
      caller
      receiver
      direction
      asteriskId
      
      patient{
        id
        firstName
        lastName
        patronymic
        
        isPrepaidService
      }
      status
    }
  }
`

export const ENDED_CALLS_SUBSCRIPTION = gql`
subscription NewCallsEnded{
    callEnded{
      caller
      id     
      date
      time
      caller
      receiver
      direction
      asteriskId
      
      patient{
        id
        firstName
        lastName
        patronymic
        
        isPrepaidService
      }
      status
    }
  }
`