import {
    smsVariables
} from "../helpValues/smsConstants";
import {
    fullNameString,
    SurnameAndInitialsString,
    NameAndPatronymicString
} from "./nameGenerationString";

export function smsTemplateConverter(
    smsString,
    data = {
        specialist: {
            surname: "",
            name: "",
            patronymic: "",
        },
        patient: {
            surname: "",
            name: "",
            patronymic: "",
        },
        date: "",
        time: ""
    }
) {

    const specialistFullName = new RegExp(smsVariables.specialistFullName, 'g')
    const specialistSurnameInitials = new RegExp(smsVariables.specialistSurnameInitials, 'g')
    const patientFullName = new RegExp(smsVariables.patientFullName, 'g')
    const patientSurnameInitials = new RegExp(smsVariables.patientSurnameInitials, 'g')
    const patientNamePatronymic = new RegExp(smsVariables.patientNamePatronymic, 'g')
    const date = new RegExp(smsVariables.date, 'g')
    const time = new RegExp(smsVariables.time, 'g')

    let smsText = smsString.replace(specialistFullName, fullNameString(data.specialist.surname, data.specialist.name, data.specialist.patronymic))
    smsText = smsText.replace(specialistSurnameInitials, SurnameAndInitialsString(data.specialist.surname, data.specialist.name, data.specialist.patronymic))
    smsText = smsText.replace(patientFullName, fullNameString(data.patient.surname, data.patient.name, data.patient.patronymic))
    smsText = smsText.replace(patientSurnameInitials, SurnameAndInitialsString(data.patient.surname, data.patient.name, data.patient.patronymic))
    smsText = smsText.replace(patientNamePatronymic, NameAndPatronymicString(data.patient.name, data.patient.patronymic))
    smsText = smsText.replace(date, data.date)
    smsText = smsText.replace(time, data.time)

    return smsText
}

export function smsMassTemplateConverter(

    smsStringArray,
    data = {
        specialist: {
            surname: "",
            name: "",
            patronymic: "",
        },
        patient: {
            surname: "",
            name: "",
            patronymic: "",
        },
        date: "",
        time: ""
    }
) {

    const specialistFullName = new RegExp(smsVariables.specialistFullName, 'g')
    const specialistSurnameInitials = new RegExp(smsVariables.specialistSurnameInitials, 'g')
    const patientFullName = new RegExp(smsVariables.patientFullName, 'g')
    const patientSurnameInitials = new RegExp(smsVariables.patientSurnameInitials, 'g')
    const patientNamePatronymic = new RegExp(smsVariables.patientNamePatronymic, 'g')
    const date = new RegExp(smsVariables.date, 'g')
    const time = new RegExp(smsVariables.time, 'g')

    let letNewSmsArray = []
    smsStringArray.forEach((smsString) => {
        let smsText = smsString.replace(specialistFullName, fullNameString(data.specialist.surname, data.specialist.name, data.specialist.patronymic))
        smsText = smsText.replace(specialistSurnameInitials, SurnameAndInitialsString(data.specialist.surname, data.specialist.name, data.specialist.patronymic))
        smsText = smsText.replace(patientFullName, fullNameString(data.patient.surname, data.patient.name, data.patient.patronymic))
        smsText = smsText.replace(patientSurnameInitials, SurnameAndInitialsString(data.patient.surname, data.patient.name, data.patient.patronymic))
        smsText = smsText.replace(patientNamePatronymic, NameAndPatronymicString(data.patient.name, data.patient.patronymic))
        smsText = smsText.replace(date, data.date)
        smsText = smsText.replace(time, data.time)
        letNewSmsArray.push(smsText)
    })

    return letNewSmsArray
}