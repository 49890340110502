export const queryFetch = (query, variables) => {
  return fetch(process.env.REACT_APP_API_HOST, {
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    credentials: 'include',
    body: JSON.stringify({
      query: query,
      variables: variables
    })
  }).then((res) => res.json());
}

export const queryFetchClear = (url, variables) => {
  return fetch(`${process.env.REACT_APP_API_URL}${url}`, {
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    credentials: 'include',
    body: JSON.stringify(variables)
  }).then((res) => res.json());
}