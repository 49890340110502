import React from 'react';
import { useInstance } from "react-ioc";
import { observer } from "mobx-react-lite";
import {
  Button,
  FormControl,
  Grid, InputLabel, MenuItem, Select,
  TextField,
  Typography,
  Switch,
} from "@mui/material";
import { phoneMask, internationalPhoneMasks } from '../../../../../_common/helpValues/inputParametrs';
import TextMaskCustom from '../../../../../_components/TextMaskPhone/TextMaskPhone';
import CreateWriteStore from '../store/CreateWrite.data.store';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';


const NewPatientPhoneSet = observer(() => {
  const store = useInstance(CreateWriteStore)

  const hadleAddPhone = () => {
    store.addNewPhone()
  }

  const handleDeletePhone = (index) => {
    store.deletePhoneFromPhonesByIndex(index)
  }

  const handleSetPhoneNumber = (index, number) => {
    store.setPhoneNumberError(index, false)
    store.setPhoneNumberByIndex(index, number)
  }

  const handleSetForeignCode = (index, code) => {
    store.setPhoneCodeError(index, false)
    store.setPhoneForeignCodeByIndex(index, code)
  }

  const handleSetForeignNumber = (index, number) => {
    store.setPhoneForeignNumberError(index, false)
    store.setPhoneForeignNumberByIndex(index, number)
  }

  const handleSetType = (index, type) => {
    store.setPhoneTypeByIndex(index, { id: type.value, type: type.children })
  }

  const handleSwitchIsRu = (index) => {
    store.setPhoneSwitchIsRuByIndex(index)
  }

  const handleSwitchSmsPermission = (index) => {
    store.setPhoneSwitchSmsPermissionByIndex(index)
  }

  const handleSetComment = (index, number) => {
    store.setPhoneCommentByIndex(index, number)
  }

  return (
    <>
      <Grid container spacing={2} sx={{ p: 1 }}>
        <Grid item xs={12} >
          <Typography>Телефоны</Typography>
        </Grid>
      </Grid>
      {store.phones.length > 0 && store.phones.map((phone, index) => {
        return (
          <Grid container spacing={2} sx={{ p: 1 }} key={index}>
            {phone.isRu ?
              <Grid item xs={12} sm={4} md={3}>
                <TextField
                  error={phone.numberError}
                  value={phone.number && phone.number}
                  focused
                  fullWidth
                  size="small"
                  label="Номер*"
                  onChange={(e) => handleSetPhoneNumber(index, e.target.value)}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                    inputProps: {
                      mask: phoneMask,
                      guide: true,
                      placeholder: "+7(___) ___-__-__"
                    },
                  }}
                />
              </Grid>
              :
              <Grid item xs={12} sm={4} md={3} sx={{ display: "flex", gap: 1 }}>
                <TextField
                error={phone.codeError}
                  // type="number"
                  value={phone.foreignCode && phone.foreignCode}
                  focused
                  size="small"
                  sx={{ width: 100 }}
                  label="Код*"
                  onChange={(e) => handleSetForeignCode(index, e.target.value)}
                  inputProps={{ maxLength: 4 }}
                />
                <TextField
                error={phone.foreignNumberError}
                  value={phone.foreignNumber && phone.foreignNumber}
                  focused
                  fullWidth
                  size="small"
                  label="Номер*"
                  onChange={(e) => handleSetForeignNumber(index, e.target.value)}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                    inputProps: {
                      mask: internationalPhoneMasks.phoneMask,
                      guide: true,
                      placeholder: "(___) ___-__-__"
                    },
                  }}
                />
              </Grid>
            }


            <Grid item xs={12} sm={4} md={3}>
              <FormControl fullWidth focused>
                <InputLabel id="status-write-select-label">Тип телефона*</InputLabel>
                <Select
                  value={phone?.type?.id && phone?.type?.id}
                  onChange={(e, v) => handleSetType(index, v.props)}
                  labelId="status-write-select-label"
                  id="status-write-select"
                  label="Статус записи"
                  size="small"
                >
                  {store.phoneTypes.length > 0 && store.phoneTypes.map((status) => {
                    return (
                      <MenuItem key={status.id} value={status.id}>
                        {status.type}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                value={phone.comment}
                focused
                fullWidth
                size="small"
                label="Комментарий"
                onChange={(e) => handleSetComment(index, e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <FormControl>
                <label htmlFor={`send-is-ru-phone-write-switch-${index}-${phone.number}`}>
                  <Switch size="small" checked={phone.isRu && phone.isRu} id={`send-is-ru-phone-write-switch-${index}-${phone.number}`} onChange={() => handleSwitchIsRu(index)} />
                  <Typography sx={{ display: "inline" }} variant={"subtitle2"}>ru</Typography>
                </label>
              </FormControl>
              <FormControl>
                <label htmlFor={`send-sms-permission-phone-write-switch-${index}-${phone.number}`}>
                  <Switch size="small" checked={phone.smsPermission && phone.smsPermission} id={`send-sms-permission-phone-write-switch-${index}-${phone.number}`} onChange={() => handleSwitchSmsPermission(index)} />
                  <Typography sx={{ display: "inline" }} variant={"subtitle2"}>Отправка смс</Typography>
                </label>
              </FormControl>
            </Grid>
            {store.phones.length >= 2 &&
              <Grid item xs={12}>
                <Button variant="outlined" size="small" fullWidth onClick={() => handleDeletePhone(index)}>
                  <Typography variant="button">
                    Удалить
                  </Typography>
                  <DeleteIcon />
                </Button>
              </Grid>
            }
          </Grid>
        )
      })}
      <Grid container spacing={2} sx={{ p: 1 }}>
        <Grid item xs={12} >
          <Button variant="outlined" size="small" onClick={hadleAddPhone}>
            <Typography variant="button">
              Добавить
            </Typography>
            <AddIcon />
          </Button>
        </Grid>
      </Grid>

    </>
  );
})

export default NewPatientPhoneSet;
