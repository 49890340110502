import React, { useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { useInstance } from "react-ioc";
import {
    Box,
    IconButton,
    Typography,
} from "@mui/material";
import ChangeWriteDataStore from '../store/ChangeWriteData.data.store';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import startPhoneCall from '../../../../../_common/commonRequests/startPhoneCall';
import authStore from '../../../../../_common/stores/auth.store';
import Paper from '@mui/material/Paper';



const CallBtnPopover = (observer(() => {
    const dataStore = useInstance(ChangeWriteDataStore)
    const [callBtnClicked, setCallBtnClicked] = useState(false)
    const [callTimer, setCallTimer] = useState(0)
    const [openHint, setOpenHint] = useState(false)

    const callTimerProcess = () => {
        setCallTimer(5)
        let timer = setInterval(() => setCallTimer(prev => prev - 1), 1000)
        setTimeout(() => {
            clearInterval(timer)
            setCallTimer(0)
            setCallBtnClicked(false)
        }, 5000)
    }

    useEffect(() => {
        if (callBtnClicked) {
            callTimerProcess()
        }
    }, [callBtnClicked])

    const getDefaultPhone = () => {
        const defaultPhone = dataStore?.writeData?.patient?.phones.filter((phone) => phone?.isDefault && phone?.ruNumber)
        return defaultPhone?.length > 0 ? defaultPhone[0] : dataStore?.writeData?.patient?.phones[0] || null
    }

    const handleCall = () => {
        setOpenHint(false)
        setCallBtnClicked(true)
        startPhoneCall(authStore.currentMedicalCenterPhone, getDefaultPhone().id, "id")
    }

    const setIsBtnDisabled = () => {
        if (!getDefaultPhone() || callBtnClicked) {
            return true
        } else {
            return false
        }
    }

    const setHintTxt = (full) => {
        const phone = getDefaultPhone()
        if (phone) {
            const type = phone.type?.type ? phone.type?.type : ""
            const order = phone.hasOrder ? ` ${phone.order}` : ""
            if (full) {
                return (
                    <>
                        <Typography variant="h6" sx={{ fontSize: 16 }}>
                            {type + order}
                        </Typography>
                        <Typography>
                            {phone?.comment ? ` ${phone?.comment}` : ""}
                        </Typography>
                    </>

                )
            } else return type + order

        } else return "Номер отсутствует"
    }

    const handleOpenHint = () => {
        setOpenHint(true)
    }

    const handleCloseHint = () => {
        setOpenHint(false)
    }

    return (
        //tooltip is a facking bitch in styling
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "end"}}>
            <Box sx={{ position: "relative" }}>
                <IconButton
                    onClick={() => handleCall()}
                    disabled={setIsBtnDisabled()}
                    onMouseEnter={handleOpenHint}
                    onMouseLeave={handleCloseHint}
                >
                    {callBtnClicked ?
                        <Typography sx={{ width: 20 }}>
                            {callTimer}
                        </Typography>
                        :
                        <LocalPhoneIcon />
                    }
                </IconButton>
                <Box
                    sx={{
                        position: "absolute",
                        top: "100%",
                        right: 0,
                        display: openHint ? "block" : "none",
                        zIndex: 2,
                        width: 200,

                    }}
                >
                    <Paper sx={{ p: 1, display: "inline-block", float: "right" }}>
                        {setHintTxt(true)}
                    </Paper>
                </Box>
            </Box>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
                {setHintTxt(false)}
            </Box>
        </Box>
    );
}));

export default CallBtnPopover;

