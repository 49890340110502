export const phoneMask = ['+','7','(',/\d/,/\d/,/\d/,')',' ',/\d/,/\d/,/\d/,'-',/\d/,/\d/,'-',/\d/,/\d/]

export const internationalPhoneMasks = {
    codeMask: ['+',/\d/,/\d/,/\d/,/\d/],
    phoneMask: ['(',/\d/,/\d/,/\d/,')',' ',/\d/,/\d/,/\d/,'-',/\d/,/\d/,'-',/\d/,/\d/],
}

export const phoneMaskMasked = ['+','7','(',/\D/,/\D/,/\D/,')',' ',/\D/,/\D/,/\D/,'-',/\d/,/\d/,'-',/\d/,/\d/]


export const namesMaxLength = 30

export const dateOfBirthdayMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]