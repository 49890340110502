import React, {memo} from 'react';
import { Box, Typography } from "@mui/material"
import { SurnameAndInitialsString } from "../../../../src/_common/helpers/nameGenerationString"
import { observer } from "mobx-react-lite"
import { styled } from "@mui/material/styles"
// import DoneIcon from "@mui/icons-material/Done"
// import IconButton from "@mui/material/IconButton"
import { useInstance } from "react-ioc"
import StoreChat from "../stores/storeChat"



const MessageLayout = styled(Box)(({ theme }) => ({
  padding:"10px",
  background: theme.palette.primary.light2,
  borderRadius: "10px"
}));

const StyledMessageTextLayout = styled(Box)(({ theme }) => ({
}));

const NameLayout = styled(Typography)(({ theme }) => ({
 color: theme.palette.primary.main
}));


const MessageItemHeaderLayout = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
}));



const MessageItem = observer((props) => {

  const {text, firstName, lastName, patronymic, id, userId} = props;

  const storeChat = useInstance(StoreChat)


  return (
    <MessageLayout>
      <MessageItemHeaderLayout>
        <NameLayout>
          {SurnameAndInitialsString(lastName,firstName,patronymic)}
        </NameLayout>

        {/*{text !== "Принято" &&  <IconButton onClick={() => {*/}
        {/*  storeChat.sendMessageByDone("Принято", id, userId)*/}
        {/*}}>*/}
        {/*  <DoneIcon sx={{*/}
        {/*    color: "#3F51B5"*/}
        {/*  }}/>*/}
        {/*</IconButton>}*/}

      </MessageItemHeaderLayout>
      <StyledMessageTextLayout>
        {text}
      </StyledMessageTextLayout>
    </MessageLayout>
  );
});

export default memo(MessageItem);