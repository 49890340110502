import React, { useEffect, useState } from "react";
import { Box, Typography, Button, useMediaQuery, useTheme } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import moment from "moment"
import stamp from "../../_common/assets/img/documentSign/podologStamp.png"
import sign from "../../_common/assets/img/documentSign/KrasukTatianaSign.png"
import errorCat from "../../_common/assets/img/errorCat/cute-error-cat.png"
import AdvancePaymentStore from "./store/advancePayment.store";
import { provider, useInstance } from "react-ioc";
import { observer } from "mobx-react-lite";
import { FullPageFallbackProgress } from "../../_components/UI/preloaders/FullPageFallbackProgress";

const AdvancePaymentAgreement = provider(AdvancePaymentStore)(observer(() => {
  const [conditionsRead, setConditionsRead] = useState(false)

  const store = useInstance(AdvancePaymentStore)

  const mainTheme = useTheme();
  const mobileBreakpoint = useMediaQuery(mainTheme.breakpoints.down("sm"));

  useEffect(() => {
    document.title = 'Договор'
    const currentUrl = window.location.href
    const regex = /payment-agreement\/(.+)/;
    const match = currentUrl.match(regex);
    if (match) {
      store.getPaymentData(match[1])
    }
  }, [store])

  const switchConditionsRead = () => {
    setConditionsRead(!conditionsRead)
  }

  const date = new Date()

  const goPay = () => {
    store.sendToPay()
   }

  if (!store.isLoaded && !store.error) {
    return (
      <FullPageFallbackProgress />
    )
  }

  if (store.error) {
    return (
      <Box sx={{mt: 8}}>
        <Typography variant="h3" sx={{ textAlign: "center"}}>
          Возникла ошибка. Попробуйте заново.
        </Typography>
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          mt: 2,
          "& img": {
            maxWidth: 300,
            minWidth: 100,
            maxHeight: 400,
          }
        }}>
          <img src={errorCat} alt={"картинка ошибки"}/>
        </Box>

      </Box>
    )
  }

  return (
    <Box sx={{ p: { xs: 2, sm: 4, md: 8 }, mb: { xs: 16, sm: 0 } }}>
      <Typography variant="h4" sx={{ textAlign: "center" }}>Соглашение об авансовом платеже</Typography>
      <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6">
          г. Москва
        </Typography>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          {date && moment(date).format("DD.MM.YYYY")}
        </Typography>
      </Box>
      <Typography sx={{
        mt: 2,
        "& span": {
          textDecoration: "underline",
          fontWeight: "bold"
        },
      }}>
        ООО «Подолог», в лице генерального директора Красюк Татьяны Николаевны, с одной стороны,
        именуемый далее «Исполнитель», и {"\u00A0"}
        <span>
          {store.patientName ? store.patientName : ""}
        </span>,
        именуемый далее «Заказчик»,  заключили настоящее соглашение о  следующем:
      </Typography>
      <Box sx={{ ml: 3, mt: 2 }}>
        <ol>
          <li>
            <Typography sx={{
              "& span": {
                textDecoration: "underline",
                fontWeight: "bold"
              },
            }}>
              С учетом норм п.2 ст. 711 и ст. 783 Гражданского кодекса РФ Заказчик выдает Исполнителю денежную сумму
              в размере: {"\u00A0"}
              <span>
                {store.paymentAmount ? store.paymentAmount : ""} рублей
              </span>
              {"\u00A0"} в виде разового авансового платежа в счет причитающейся с него денежной суммы по  предстоящему  договору
              оказанию услуг в доказательство заключения договора и в обеспечение его исполнения.
            </Typography>
          </li>
          <li>
            <Typography>
              При прекращении договорного обязательства до начала исполнения по соглашению сторон
              или из-за невозможности исполнения должен быть возвращен Заказчику полностью.
            </Typography>
          </li>
          <li>
            <Typography>
              Если за неисполнение договора будет ответствен Заказчик, авансовый платеж остается у Исполнителя.
            </Typography>
          </li>
          <li>
            <Typography>
              Ответственная за неисполнение договора сторона обязана, возместить другой стороне убытки,
              с зачетом суммы авансового платежа.
            </Typography>
          </li>
          <li>
            <Typography>
              Споры, которые могут возникнуть при исполнении условий настоящего договора,
              стороны будут стремиться разрешать в порядке досудебного разбирательства и
              в рамках действующего законодательства РФ.
            </Typography>
          </li>
          <li>
            <Typography>
              По всем вопросам, не нашедшим своего решения в тексте и условиях настоящего договора,
              но прямо или косвенно вытекающим из отношений сторон по нему,
              затрагивающих имущественные интересы и деловую репутацию сторон договора,
              имея в виду необходимость защиты их охраняемых законом прав и интересов,
              стороны настоящего договора будут руководствоваться нормами и
              положениями действующего законодательства РФ.
            </Typography>
          </li>
          <li>
            <Typography>
              Настоящее соглашение вступает в силу со дня подписания его сторонами,
              с которого и становится обязательным для сторон, заключивших его.
              Условия настоящего соглашения применяются к отношениям сторон,
              возникшим только после заключения настоящего соглашения.
              В случае исполнения обязательств по соглашению и получению услуг Заказчиком,
              соглашение становиться неотъемлемой частью договора на оказание услуг Исполнителя (в виде приложения).
              Сумма, оплаченная в рамках данного соглашения,
              зачитывается в счет окончательного платежа по договору оказания услуг.
            </Typography>
          </li>
          <li>
            <Typography>
              Настоящее соглашение действует в течение 3 месяцев с момента подписания сторонами.
              Прекращение (окончание срока) действия договора влечет за собой прекращение обязательств сторон по нему,
              но не освобождает стороны договора от ответственности за его нарушения,
              если таковые имели место при исполнении условий настоящего договора.
            </Typography>
          </li>
          <li>
            <Typography>
              Оригинал Соглашения Заказчик может получить в любой момент в письменном виде по адресу: 125315,
              г. Москва, 2-ой Балтийский пер., д.1/18, корп. а
            </Typography>
          </li>
        </ol>
      </Box>
      <Typography variant="h5" sx={{ mt: 6, textAlign: "center" }}>
        Подписи сторон
      </Typography>
      <Box
        sx={{
          mt: 4,
          display: 'flex',
          justifyContent: { xs: "start", md: "space-between" },
          flexWrap: { xs: "wrap", md: "nowrap" },
        }}
      >
        <Box sx={{ pr: 2 }}>
          <Typography variant="h6">
            Исполнитель
          </Typography>
          <Typography>
            ООО «ПОДОЛОГ»
          </Typography>
          <Typography>
            Юридический адрес:125315, г. Москва, 2-ой Балтийский пер., д.1/18, корп. А
          </Typography>
          <Typography>
            Фактический адрес:125315, г. Москва, 2-ой Балтийский пер., д.1/18, корп. А
          </Typography>
          <Typography>
            ИНН 7743861644
          </Typography>
          <Typography>
            КПП 774301001
          </Typography>
          <Typography>
            «СДМ-БАНК» (ПАО)
          </Typography>
          <Typography>
            БИК 044525685
          </Typography>
          <Typography>
            Р/с 40702810200000007369
          </Typography>
          <Typography>
            К/с 30101810845250000685
          </Typography>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              height: 120,
              "& img": {
                maxWidth: 150,
                minWidth: 100,
                maxHeight: 150,
                position: "absolute",
              }
            }}
          >
            Ген. директор _____________________ Красюк Татьяна Николаевна
            <img src={stamp} alt="печать" style={{
              top: -50,
              left: 110
            }} />
            <img src={sign} alt="подпись" style={{
              top: -30,
              left: 120
            }} />

          </Box>
        </Box>
        <Box>
          <Typography variant="h6">
            Заказчик
          </Typography>
          <Typography sx={{
            "& span": {
              textDecoration: "underline",
              fontWeight: "bold"
            },
          }}>
            Ф.И.О.:{"\u00A0"}
            <span>
              {store.patientName ? store.patientName : ""}
            </span>
          </Typography>
          <Typography>
            Адрес:
          </Typography>
          <Typography sx={{
            "& span": {
              textDecoration: "underline",
              fontWeight: "bold"
            },
          }}>
            e-mail:{"\u00A0"}
            <span>
              {store.email ? store.email : ""}
            </span>
          </Typography>
          <Typography sx={{
            "& span": {
              textDecoration: "underline",
              fontWeight: "bold"
            },
          }}>
            Телефон:{"\u00A0"}
            <span>
              {store.phone ? store.phone : ""}
            </span>
          </Typography>
          <Typography sx={{ mt: 4 }}>
            _________________________________
          </Typography>
          <Typography>
            (подпись)
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          position: { xs: "fixed", sm: "inherit" },
          bottom: 0,
          left: 0,
          p: { xs: 2, md: 0 },
          bgcolor: { xs: "white", sm: "inherit" },
          width: "100%",
          gap: 2
        }}
      >
        <FormGroup>
          <FormControlLabel sx={{ mr: 0 }} control={
            <Checkbox
              checked={conditionsRead}
              onChange={switchConditionsRead}
            />}
            label="я согласен/на с условиями договора" />
        </FormGroup>
        <Button onClick={goPay} variant="contained" fullWidth={mobileBreakpoint ? true : false} disabled={conditionsRead ? false : true}>
          <Typography>
            Перейти к оплате
          </Typography>
        </Button>
      </Box>

    </Box>
  );
}));

export default AdvancePaymentAgreement;
