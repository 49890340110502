import React, {Suspense} from 'react';
import './_common/assets/styles/App.css';
import "react-image-gallery/styles/css/image-gallery.css";
import {provider} from 'react-ioc';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {FullPageFallbackProgress} from './_components/UI/preloaders/FullPageFallbackProgress';
import {observer} from "mobx-react-lite";
import UserStore from "./_common/stores/user.store";
import {
  HOME_ROUTE,
  indexElement,
  privateRoutes,
  publicRoutes
} from "./_common/router/routes";
import {AuthLayout} from "./_components/Layouts/auth-layout/auth.layout";
import authStore from "./_common/stores/auth.store";


export const App = provider(UserStore)(observer(() => {


  if (authStore.isLoading) {
    return (<FullPageFallbackProgress/>)
  }

  return (
    <Router>
      <Suspense fallback={<FullPageFallbackProgress/>}>
        <Routes>
          <Route path={HOME_ROUTE} element={<AuthLayout/>}>
            <Route index element={indexElement}/>
            {privateRoutes.map(({path, Component}) =>
              <Route key={path} path={path} element={Component}/>
            )}
          </Route>
          {publicRoutes.map(({path, Component}) =>
            <Route key={path} path={path} element={Component}/>
          )}
        </Routes>
      </Suspense>
    </Router>
  );
}));