import React, { useState } from 'react';
import { observer } from "mobx-react-lite";
import { useInstance } from "react-ioc";
import { IconButton } from "@mui/material";
import ChangeWriteViewStore from '../store/ChangeWriteView.view.store';
import ChangeWriteDataStore from '../store/ChangeWriteData.data.store';
import Popover from '@mui/material/Popover';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import SheduleCalendarStore from '../../calendar/store/sheduleCalendar.store';

const WriteActionsMenu = (observer(() => {
    const changeWriteViewStore = useInstance(ChangeWriteViewStore)
    const dataStore = useInstance(ChangeWriteDataStore)
    const sheduleCalendarStore = useInstance(SheduleCalendarStore)

    const [menuOpen, setMenuOpen] = useState(null);

    const open = Boolean(menuOpen);
    const id = open ? 'write-actions-menu-popover' : undefined;

    const handleClickMenu = (event) => {
        setMenuOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuOpen(null);
    };

    const openLogModal = () => {
        changeWriteViewStore.setOpenLogModal(true)
        handleCloseMenu()
    }

    const handleDeleteWrite = () => {
        dataStore.deleteWrite().then((data)=>{
            if (data){
                sheduleCalendarStore.updateAfterDeleteWrite(data)
            }
        })
        changeWriteViewStore.closeModal()
    }

    const handleRecoverWrite = () => {
        dataStore.recoverWrite().then((data)=>{
            if (data){
                sheduleCalendarStore.updateAfterRecoverWrite(data)
            }
        })
        changeWriteViewStore.closeModal()
    }


    return (
        <>
            <IconButton aria-describedby={id} onClick={handleClickMenu}>
                <MoreVertIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={menuOpen}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={openLogModal}>
                            <ListItemText primary="Посмотреть лог записи" />
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    {dataStore.writeData?.isActive ?
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleDeleteWrite}>
                                <ListItemText primary="Удалить запись" />
                            </ListItemButton>
                        </ListItem>
                        :
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleRecoverWrite}>
                                <ListItemText primary="Восстановить запись" />
                            </ListItemButton>
                        </ListItem>
                    }
                </List>
            </Popover>
        </>

    );
}));

export default WriteActionsMenu;

