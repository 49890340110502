import React from 'react';
import { useInstance } from "react-ioc";
import { observer } from "mobx-react-lite";
import {
    Box,
    Button,
    TextField,
    Typography,
    Switch,
} from "@mui/material";
import ArticleIcon from '@mui/icons-material/Article';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SheduleSmsTemplatesStore from '../../../sheduleSmsTemplates/store/sheduleSmsTemplates.store';
import SmsSendDataChangeWriteStore from '../../store/SmsSendModalChangeWriteData.data.store';
import { isPossibleSend } from '../../../../../../_common/helpers/countSmsDateForSheduleSend';
import ChangeWriteDataStore from '../../store/ChangeWriteData.data.store';
import moment from "moment"

const SmsTextsBlock = observer(() => {
    const store = useInstance(SmsSendDataChangeWriteStore)
    const sheduleSmsTemplatesStore = useInstance(SheduleSmsTemplatesStore)
    const changeWriteDataStore = useInstance(ChangeWriteDataStore)

    const smsStatusSwitch = (status) => {
        switch (status) {
            case "NEW":
                return "Новое"
            case "SENT":
                return "Отправлено"
            case "DELIVERED":
                return "Доставлено"
            case "ERROR":
                return "Ошибка"
            default:
                return "Нет статуса"
        }
    }

    const handleSwitch = (index) => {
        store.switchSmsPermission(index)
    }

    const handleTemplateChoose = (index, textIndex) => {
        console.log("store.writeData", store.writeData, store.writeData.time.substring(0,5))
        const writeData = {
            date: store.writeData.date,
            patient: store.writeData.patient,
            specialist: store.writeData.specialist,
            time: store.writeData.time?.length>0 ? store.writeData.time.substring(0,5) : ""
        }
        sheduleSmsTemplatesStore.setOpenModalFromSmsInWrite("old", index, textIndex, writeData)
    }

    const handleSmsDelete = (index, txtIndex) => {
        store.deleteSms(index, txtIndex)
    }

    const handleSmsAdd = (index) => {
        store.sddSms(index)
    }

    const changeSms = (index, txtIndex, value) => {
        store.changeSmsText(index, txtIndex, value)
    }

    return (
        <Box>
            {store?.smsTexts && store?.smsTexts?.map((smsBlock, index) => {
                const isPossibleToSend = isPossibleSend(changeWriteDataStore.date, changeWriteDataStore.timeStart, smsBlock.name)
                if (isPossibleToSend) {
                    return (
                        <Box key={`sms-block-${index}`} sx={{ pb: 1, borderBottom: "1px solid", borderColor: "primary.light4" }}>
                            <Box>
                                <label htmlFor={`contained-switch-send-sms-accordion-${index}`}>
                                    <Switch checked={smsBlock.permission} id={`contained-switch-send-sms-accordion-${index}`} onChange={() => handleSwitch(index)} />
                                    <Box sx={{ display: "inline" }}>
                                        <Typography variant={"subtitle2"} sx={{ display: "inline", fontSize: 14 }}>
                                            {smsBlock.name}
                                        </Typography>
                                    </Box>
                                </label>
                            </Box>

                            {smsBlock.text.length > 0 && smsBlock.text.map((sms, textIndex) => {
                                const isMoreOneSms = smsBlock.text.reduce((accumulator, sms) => {
                                    if (sms.new || !sms.forDelete) {
                                        return accumulator + 1;
                                    }
                                    return accumulator;
                                }, 0);
                                if (!sms?.forDelete) {
                                    if (sms.new) {
                                        return (
                                            <Box key={`sms-block-${index}-${textIndex}`} sx={{ mt: 2 }}>
                                                <Button variant="outlined" size="small" onClick={() => handleTemplateChoose(index, textIndex)}>
                                                    <Typography variant="button">
                                                        Выбрать шаблон
                                                    </Typography>
                                                    <ArticleIcon />
                                                </Button>
                                                <TextField
                                                    sx={{ mt: 2 }}
                                                    label={"Текст сообщения"}
                                                    value={sms.text && sms.text}
                                                    size="small"
                                                    focused
                                                    fullWidth
                                                    inputProps={{ maxLength: 600 }}
                                                    multiline
                                                    minRows={2}
                                                    maxRows={4}
                                                    onChange={(e) => changeSms(index, textIndex, e.target.value)}
                                                />
                                                <Button fullWidth variant="outlined" size="small" sx={{ mt: 2 }} onClick={() => handleSmsDelete(index, textIndex)}>
                                                    <Typography variant="button">
                                                        Удалить
                                                    </Typography>
                                                    <DeleteIcon />
                                                </Button>
                                            </Box>
                                        )
                                    } else {
                                        return (
                                            <Box key={`sms-block-${index}-${textIndex}`} sx={{ mt: 2 }}>
                                                {sms?.status === "NEW" ?
                                                    <Typography variant="subtitle1">
                                                        Это сообщение еще не отправлена. Отправка - {moment(sms?.dispatch).format("DD.MM.YYYY HH:mm")}
                                                    </Typography>
                                                    :
                                                    <Typography variant="subtitle1">
                                                        Статус смс - {smsStatusSwitch(sms?.status)}
                                                    </Typography>
                                                }
                                                <TextField
                                                    label={"Текст сообщения"}
                                                    value={sms.text && sms.text}
                                                    disabled={!sms.new}
                                                    size="small"
                                                    focused
                                                    fullWidth
                                                    multiline
                                                    minRows={2}
                                                    maxRows={4}
                                                />
                                                {sms?.status === "NEW" &&
                                                    <Button sx={{ mt: 1 }} fullWidth variant="outlined" size="small" onClick={() => handleSmsDelete(index, textIndex)}>
                                                        <Typography variant="button">
                                                            Удалить
                                                        </Typography>
                                                        <DeleteIcon />
                                                    </Button>
                                                }
                                            </Box>
                                        )
                                    }

                                } else return ""

                            })}
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{ mt: 2 }}
                                onClick={() => handleSmsAdd(index)}
                                disabled={smsBlock.text.length >= 5 ? true : false}
                            >
                                <Typography variant="button">
                                    Добавить еще смс
                                </Typography>
                                <AddIcon />
                            </Button>
                        </Box>

                    )
                } else {
                    return (
                        <Box key={`sms-block-${index}`} sx={{ pb: 1, borderBottom: "1px solid", borderColor: "primary.light4" }}>
                            <Box>
                                <Typography variant={"subtitle1"} sx={{ display: "inline" }}>
                                    {smsBlock.name}. {" "}
                                </Typography>
                                <Typography variant={"body1"} sx={{ display: "inline" }}>
                                    Добавление смс невозможно - время прошло.
                                </Typography>
                            </Box>
                            {smsBlock.text.length > 0 ?
                                <>
                                    <Typography>
                                        Список существующих смс:
                                    </Typography>
                                    {smsBlock.text.map((sms, textIndex) => {
                                        const isMoreOneSms = smsBlock.text.reduce((accumulator, sms) => {
                                            if (sms.new || !sms.forDelete) {
                                                return accumulator + 1;
                                            }
                                            return accumulator;
                                        }, 0);
                                        if (!sms?.forDelete) {
                                            return (
                                                <Box key={`sms-block-${index}-${textIndex}`} sx={{ mt: 2 }}>
                                                    {sms?.status === "NEW" ?
                                                        <Typography variant="subtitle1">
                                                            Это сообщение еще не отправлена. Отправка - {moment(sms?.dispatch).format("DD.MM.YYYY HH:mm")}
                                                        </Typography>
                                                        :
                                                        <Typography variant="subtitle1">
                                                            Статус смс - {smsStatusSwitch(sms?.status)}
                                                        </Typography>
                                                    }
                                                    <TextField
                                                        label={"Текст сообщения"}
                                                        value={sms.text && sms.text}
                                                        disabled={!sms.new}
                                                        size="small"
                                                        focused
                                                        fullWidth
                                                        multiline
                                                        minRows={2}
                                                        maxRows={4}
                                                    />
                                                    {sms?.status === "NEW" &&
                                                        <Button sx={{ mt: 1 }} fullWidth variant="outlined" size="small" onClick={() => handleSmsDelete(index, textIndex)}>
                                                            <Typography variant="button">
                                                                Удалить
                                                            </Typography>
                                                            <DeleteIcon />
                                                        </Button>
                                                    }
                                                </Box>
                                            )
                                        } else return ""

                                    })
                                    }
                                </>

                                :
                                <Typography>
                                    Смс отсутствуют
                                </Typography>
                            }
                        </Box>

                    )
                }

            })}

        </Box>

    );
});

export default SmsTextsBlock;