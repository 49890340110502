import { makeAutoObservable } from "mobx";

export default class ChangeWriteViewStore {

    state = {
        openModal: false,
        openLogModal: false,
        isInfo: false,
        infoFromShedule: null,
        openDeleteDelayModal: false,
        openCreateLatness: false
    }

    get openLogModal() {
        return this.state.openLogModal
    }

    setOpenLogModal(value) {
        return this.state.openLogModal = value
    }

    get openDeleteDelayModal () { 
        return this.state.openDeleteDelayModal
    }

    setOpenDeleteDelayModal (open) {
        this.state.openDeleteDelayModal = open
    }

    resetStore () {
        this.state.openModal = false
        this.state.isInfo = false
        this.state.openLogModal = false
        this.state.infoFromShedule = null
        this.state.openDeleteDelayModal = false
    }

    get infoFromShedule() {
        return this.state.infoFromShedule
    }

    setInfoFromShedule(info) {
        this.state.infoFromShedule = info
    }

    setOpenModal() {
        this.state.openModal = true
    }

    closeModal() {
        this.state.openModal = false
        this.resetStore()
    }

    get openModal() {
        return this.state.openModal
    }

    setOpenModalGetInfo(info) {
        this.setInfoFromShedule(info)
        this.setOpenModal()
    }

    setIsInfo(boolean) {
        this.state.isInfo = !!boolean
    }

    get isInfo() {
        return this.state.isInfo
    }

    get openCreateLatness() {
        return this.state.openCreateLatness
    }

    setOpenCreateLatness(value) {
        this.state.openCreateLatness = value
    }

    constructor() {
        makeAutoObservable(this)
    }

}