import React from 'react';
import { useInstance } from "react-ioc";
import {
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Box,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import DialogModal from '../../../../../_components/UI/modals/DialogModal';
import { observer } from "mobx-react-lite";
import { fullNameString } from '../../../../../_common/helpers/nameGenerationString';
import SmsTextsBlock from './components/SmsTextsBlock';
import SmsSendDataChangeWriteStore from '../store/SmsSendModalChangeWriteData.data.store';
import SmsSendModalChangeWriteViewStore from '../store/SmsSendModalChangeWriteView.view.store';


const SmsModalForChangingWrite = observer(() => {
    const viewStore = useInstance(SmsSendModalChangeWriteViewStore)
    const store = useInstance(SmsSendDataChangeWriteStore)

    const mainTheme = useTheme();
    const mobileBreakpoint = useMediaQuery(mainTheme.breakpoints.down("md"));

    const handleClose = () => {
        viewStore.setOpenModal(false)
    }

    const handleChangePhone = (phone) => {
        store.setPhone(phone.value)
    }

    function phoneSelectItem (phone) {
        return (
          <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <Box >
              {phone.type.type}{" "}{phone.hasOrder && phone.order}
            </Box>
            <Box >
              {phone.comment}
            </Box>
          </Box>
        )
      }

    return (
        <DialogModal title={'Индивидуальная отправка смс'}
            open={viewStore.openModal}
            onClose={() => handleClose()}
            hideClose={mobileBreakpoint? false : true}
            closeTitle="Продолжить редактирование записи"
            hideSave
            maxWidth={'md'}
            sx={{ position: "relative" }}
        >
            <Box sx={{p: 2, display: "flex", gap: 2, alignItems: "center"}}>
                <Typography variant="h6">
                    {fullNameString(store.patient.surname, store.patient.name, store.patient.patronymic)}
                </Typography>
                <FormControl focused sx={{ mt: 1, minWidth: 200 }}>
                    <InputLabel id="status-write-select-label">Телефон</InputLabel>
                    <Select
                        value={store.phone ? store.phone : ""}
                        onChange={(e, v) => handleChangePhone(v.props)}
                        labelId="status-write-select-label"
                        id="status-write-select"
                        label="Телефон"
                        size="small"
                    >
                        {store.patient.phones.length > 0 && store.patient.phones.map((phone, index) => {
                            return (
                                <MenuItem key={index} value={phone.id} >
                                    {phoneSelectItem(phone)}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{ p: 2 }}>
                <SmsTextsBlock/>
            </Box>
        </DialogModal>
    );
});

export default SmsModalForChangingWrite;