import React from 'react';
import { DataGrid } from "@mui/x-data-grid"
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material"
import { observer } from "mobx-react-lite"
import { useInstance } from "react-ioc"
import PrePaymentDataStore from "../../stores/prePayment.data.store"
import { toJS } from "mobx"
import Grid from "@mui/material/Grid"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';

const PrePaymentTable = observer(() => {

  const store = useInstance(PrePaymentDataStore)

  // function generateRandom() {
  //   var length = 8,
  //     charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
  //     retVal = "";
  //   for (var i = 0, n = charset.length; i < length; ++i) {
  //     retVal += charset.charAt(Math.floor(Math.random() * n));
  //   }
  //   return retVal;
  // }

  const mainTheme = useTheme();
  const mobileBreakpoint = useMediaQuery(mainTheme.breakpoints.down(761));


  const NamingCell = (rowData) => {

    return (
      <Box sx={{width: "100%", display: "flex", flexDirection: "column", gap: 0, whiteSpace: "break-spaces",}}>
        <Typography sx={{whiteSpace: "break-spaces", fontSize: mobileBreakpoint ? "12px" : "16px"}} variant={rowData.row.parent.length < 50 ? "subtitle1" : "body2"}>{rowData.row.parent}</Typography>
        <Typography sx={{fontWeight: "normal", whiteSpace: "break-spaces", fontSize: mobileBreakpoint ? "12px" : "16px"}}
        >{rowData.row.serviceName}</Typography>
      </Box>
    )
  }

  const ActionCell = (rowData) => {
    return (

      <Button
        sx={{
          "&.MuiButtonBase-root:hover": {
            bgcolor: "transparent"
          },
          "& .MuiButtonBase-root": {
          },
          display: "flex",
          fontSize: "8px",
          minWidth: "10px",
          padding: mobileBreakpoint ? "0" : "6px 16px"
        }}
        variant={mobileBreakpoint ? "text" : "outlined"}
        size={"small"}
        onClick={() => {
          store.setDeleteUnit(rowData.row.idToFindBy)
        }}
      >
        {!mobileBreakpoint ? <Typography >УДАЛИТЬ ВСЕ</Typography> : <DeleteIcon color="primary" sx={{
          height: mobileBreakpoint ? "18px": "24px",
        }}/>}


      </Button>

    )
  }


  const columns = [
    {
      field: 'service',
      headerName: 'Название',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      sortable: false,
      renderCell: (params) => NamingCell(params)
    },
    {
      field: 'value',
      headerName: 'Цена',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      sortable: false,
      width: mobileBreakpoint ? 60 : 100,
      renderCell: (params) => {

        return (
          <Box sx={{textAlign: "center", fontSize: mobileBreakpoint ? "12px" : "16px"}}>
            {params.row.value}
          </Box>
        )
      }
    },
    {
      field: 'amount',
      headerName: 'Кол-во',
      align: "center",
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      sortable: false,
      width: mobileBreakpoint ? 60 : 100,
      renderCell: (params) => {

        return (
          <Box sx={{textAlign: "center", display: "flex", alignItems: "center"}}>
            <Button
              sx={{
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent"
                },
                fontSize: "8px",
                minWidth: "10px",
                padding: mobileBreakpoint ? "0" : "6px 16px"
              }}
              onClick={()=> {
                store.setAmountDecrement(params.row.idToFindBy)
              }}>
              <RemoveIcon color="primary" sx={{
                height: mobileBreakpoint ? "18px": "24px"
              }}/>
            </Button>
            <Typography sx={{fontSize: mobileBreakpoint ? "12px" : "16px"}}>
              {params.row.amount}
            </Typography>

            <Button
              sx={{
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent"
                },
                fontSize: "8px",
                minWidth: "10px",
                padding: mobileBreakpoint ? "0" : "6px 16px"
              }}
              onClick={()=> {
                store.setAmountIncrement(params.row.idToFindBy)
              }}>
              <AddIcon color="primary" sx={{
                height: mobileBreakpoint ? "18px": "24px"
              }}/>
            </Button>
          </Box>
        )
      }
    },

    {
      field: 'action',
      headerName: '',
      sortable: false,
      width: mobileBreakpoint ? 20 : 200,
      renderCell: (params) => ActionCell(params)
    },
  ];


  return (

    <>
      {store.allData.length > 0 && <Grid container>
        <Grid item xs={12} sm={2}>
          <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>
            <Button
              variant={"outlined"}
              size={"small"}
              onClick={() => {
                store.setClearDataByButtonClear()
              }}
            >
              <Typography variant={"button"}>
                Очистить
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>}


      <Grid container>
        <Grid item xs={12}>
          <Box sx={{
            width: '100%',
            // '& .MuiDataGrid-columnHeaderTitle': {
            // },
            '& .super-app-theme--header': {
              textTransform: "uppercase",
              fontSize: mobileBreakpoint ? "10px" : "16px",
              fontWeight: "bold",
              whiteSpace: "break-spaces",
              height: "fit-content",
              // boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)"
            },
            '& .MuiDataGrid-iconSeparator': {
              display: "none"
            },
            '& .MuiDataGrid-cell': {
              boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
              padding: mobileBreakpoint ? "2px" : "5px",
              minWidth: "30px",
            },
          }}>

            {
              store.allData.length > 0 && <DataGrid
                rows={toJS(store.allData)}
                columns={columns}
                rowHeight={100}
                // checkboxSelection
                disableSelectionOnClick
                hideFooter
                hideFooterPagination
                hideFooterSelectedRowCount
                disableRowSelectionOnClick
                rowSelection={false}
                animateRows={true}
                disableColumnFilter
                disableColumnMenu
                autoHeight
                // columnHeaderHeight={200}
                getRowHeight={() => 'auto'}

                getRowId={(row: any) => {

                  return row.idToFindBy
                }
                }
              />
            }

          </Box>
        </Grid>
      </Grid>
    </>
  );
});

export default PrePaymentTable;