import React, { useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { useInstance } from "react-ioc";
import {
  Autocomplete,
  FormControl,
  Grid,
  TextField,
  Typography,
  Switch
} from "@mui/material";
import CreateWriteStore from '../store/CreateWrite.data.store';
import CommonInfo from './CommonInfo';
import NewPatientPhoneSet from './NewPatientPhoneSet';
import { namesMaxLength } from '../../../../../_common/helpValues/inputParametrs';
import SmsSendDataCreateWrite from '../store/smsSendData.store';


const CreatePatientBlock = (observer(() => {
  const store = useInstance(CreateWriteStore)
  const smsSendDataCreateWrite = useInstance(SmsSendDataCreateWrite)

  useEffect(() => {
    store.getPhoneTypesList()
  }, [store])

  const [openSourceAutocomplete, setOpenSourceAutocomplete] = useState(false);
  const loadingSourceAutocomplete = openSourceAutocomplete && store.newPatientSources.length === 0;

  const handleChangeSurname = (surname) => {
    store.setSurnameError(false)
    store.setNewPatientSurname(surname)
    smsSendDataCreateWrite.setActivateFindTemplates(true)
  }

  const handleChangeName = (name) => {
    store.setNameError(false)
    store.setNewPatientName(name)
    smsSendDataCreateWrite.setActivateFindTemplates(true)
  }

  const handleChangePatronymic = (patronymic) => {
    store.setNewPatientPatronymic(patronymic)
    smsSendDataCreateWrite.setActivateFindTemplates(true)
  }

  const handleOpenSourceAutomplete = () => {
    store.getNewPatientSourcesList()
    setOpenSourceAutocomplete(true)
  }

  const handleCloseSourceAutomplete = () => {
    setOpenSourceAutocomplete(false)
  }

  const handleChangeSource = (newSourceId) => {
    store.setSourceId(newSourceId)
  }

  const handleChangeIsPatient = () => {
    store.switchNewPatientIsPatient()
  }

  const handleChangeSmsPermission = () => {
    store.switchNewPatientSmsSendPermission()
  }


  return (
    <>
      <Typography sx={{ mt: 2, ml: 1 }} variant="h6">Новый пациент</Typography>
      <Grid container spacing={2} sx={{ p: 1 }}>
        <Grid item xs={12} >
          <Typography>ФИО</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth focused>
            <TextField
              error={store.surnameError}
              size="small"
              focused
              id="Surname"
              label="Фамилия*"
              type="text"
              inputProps={{ maxLength: namesMaxLength }}
              value={store.surnameNewPatient && store.surnameNewPatient}
              onChange={(newValue) => {
                handleChangeSurname(newValue.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth focused>
            <TextField
              error={store.nameError}
              size="small"
              focused
              id="Name"
              label="Имя*"
              type="text"
              inputProps={{ maxLength: namesMaxLength }}
              value={store.nameNewPatient && store.nameNewPatient}
              onChange={(newValue) => {
                handleChangeName(newValue.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth focused>
            <TextField
              size="small"
              focused
              id="Patronymic"
              label="Отчество"
              type="text"
              inputProps={{ maxLength: namesMaxLength }}
              value={store.patronymicNewPatient && store.patronymicNewPatient}
              onChange={(newValue) => {
                handleChangePatronymic(newValue.target.value);
              }}
            >
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={12} >
          <Typography>Источник и тип карты</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth focused sx={{ mt: 1 }}>
            <Autocomplete
              size="small"
              id={"combo-box-demo"}
              open={openSourceAutocomplete}
              onOpen={() => {
                handleOpenSourceAutomplete();
              }}
              onClose={() => {
                handleCloseSourceAutomplete();
              }}
              loading={loadingSourceAutocomplete}
              options={store.newPatientSources && store.newPatientSources}
              onChange={(event, value) => handleChangeSource(value ? value.id : "")}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Источник" focused />}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2} sx={{ display: "flex", flexWrap: "wrap" }}>
          <FormControl focused sx={{ display: "flex", flexDirection: "row", alignItems: "end", height: "100%" }}>
            <label htmlFor="isPatient-write-switch">
              <Switch checked={store.isPatientNewPatient && store.isPatientNewPatient} id="isPatient-write-switch" onChange={handleChangeIsPatient} />
              <Typography sx={{ display: "inline" }} variant={"subtitle2"}>Пациент?</Typography>
            </label>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ display: "flex", flexWrap: "wrap" }}>
          <FormControl focused sx={{ display: "flex", flexDirection: "row", alignItems: "end", height: "100%" }}>
            <label htmlFor="send-sms-permission-patient-write-switch">
              <Switch checked={store.smsSendPermission && store.smsSendPermission} id="send-sms-permission-patient-write-switch" onChange={handleChangeSmsPermission} />
              <Typography sx={{ display: "inline" }} variant={"subtitle2"}>Разрешение на отправку смс</Typography>
            </label>
          </FormControl>
        </Grid>

      </Grid>



      <NewPatientPhoneSet />

      <Typography sx={{ mt: 2, ml: 1 }} variant="h6">Запись</Typography>
      <CommonInfo />
    </>
  );
}));

export default CreatePatientBlock;

