import {gql} from "@apollo/client";

export const AUTH_LOGIN = `
  mutation tokenAuth($username: String! $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token,
      payload,
      refreshExpiresIn,
      user{
        id,
        firstName,
        lastName,
        birthday,
        email,
        sip,
        isSpecialist,
        isManagementGroup,
        isOwnerAdmin
      },
      centers{
        id,
        name,
        internalNumber 
      }
    }
  }
`

export const DELETE_TOKEN_COOKIE = `
  mutation deleteTokenCookie{
    deleteTokenCookie {
      deleted
    }
  }
`

export const CENTERS = `
  query medicalCenterNumber{
    medicalCenters{
      id
      name
      internalNumber
    }
  }
`