import React from 'react';
import { observer } from "mobx-react-lite";
import {
    Box,
    Typography,
} from "@mui/material";

const DeletedWriteBlock = (observer(() => {

    return (
        <Box sx={{ bgcolor: "primary.lightred2", p: 1, borderRadius: 1, mb: 1 }}>
            <Typography sx={{ textAlign: "center", color: "primary.main" }} variant="subtitle1">
                Запись удалена
            </Typography>
        </Box>
    );
}));

export default DeletedWriteBlock;