import React from 'react';
import ModalPrepayment from "./ModalPrepayment"
import { observer } from "mobx-react-lite"
import { useInstance } from "react-ioc"
import PrePaymentDataStore from "../../stores/prePayment.data.store"
import { Grid } from "@mui/material"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"

const PrepaymentSendingConfirmation = (observer(() => {

  const store = useInstance(PrePaymentDataStore)

  return (
    <ModalPrepayment
      open={store.openConfirmationModal}
      close={() => store.setOpenConfirmationModal(false)}
    >
      <>
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={{mb: 4}} variant="h6">
              Добавить предоплату на
              сумму {store.prepSumNew} руб.?
            </Typography>
          </Grid>
        </Grid>

        {store.allData.length>0 && <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{mb: 1}}>
              Услуги
            </Typography>

            {store.allData.map((el, index) => {

              return (
                <Box key={index}>
                  {el.parent}/{el.serviceName}
                </Box>
              )
            })}
          </Grid>
        </Grid>}



        <Grid container justifyContent="flex-end" sx={{
          display: "flex",
          gap: 2,
        }}>
          <Grid item>
            <Button
              sx={{
                textTransform: "upperCase"
              }}
              variant="text"
              size="small"
              onClick={() => store.setOpenConfirmationModal(false)}
            >
              Отмена
            </Button>

          </Grid>
          <Grid item>
            <Button
              sx={{
                textTransform: "upperCase"
              }}
              variant="text"
              size="small"
              onClick={() => {
                store.sendPrepayment()
                store.setOpenConfirmationModal(false)
                store.setOpenModal(false)
                store.setPrepaymentDataClear()
              }}
            >
              Добавить
            </Button>
          </Grid>

        </Grid>
      </>

    </ModalPrepayment>
  );
}));

export default PrepaymentSendingConfirmation;