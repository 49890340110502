import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { useInstance } from "react-ioc";
import {
    Box,
    Typography,
    Badge,
} from "@mui/material";
import { provider } from "react-ioc";
import ButtonMain from '../../../../_components/UI/Buttons/ButtonMain';
import ChangeWriteViewStore from './store/ChangeWriteView.view.store';
import ChangeWriteDataStore from './store/ChangeWriteData.data.store';
import WriteActionsMenu from './components/WriteActionsMenu';
import PatientBlock from './components/PatientBlock';
import PreviousWrites from './components/PreviousWrites';
import CommonInfo from './components/CommonInfoBlock';
import InfoCommentTemplates from './components/InfoWriteCommentTemplates';
import DeletedWriteBlock from './components/DeletedWriteBlock';
import DelayBlock from './components/DelayBlock';
import SmsSendModalChangeWriteViewStore from './store/SmsSendModalChangeWriteView.view.store';
import SmsSendDataChangeWriteStore from './store/SmsSendModalChangeWriteData.data.store';
import SmsTimerSendStore from '../../../../_components/SmsTimerSend/store/sms-timer-send.store';
import { FullPageFallbackProgress } from '../../../../_components/UI/preloaders/FullPageFallbackProgress';
import BaseModal from '../../../../_components/UI/modals/BaseModal';
import SmsModalForChangingWrite from './smsForChangingWrite/smsModalForChangingWrite'
import SheduleSmsTemplates from '../sheduleSmsTemplates/sheduleSmsTemplates'
import SheduleSmsTemplatesStore from '../sheduleSmsTemplates/store/sheduleSmsTemplates.store';
import { smsTimerValue } from '../../../../_common/helpValues/smsConstants';
import { generateId } from '../../../../_common/helpers/generateId';
import SheduleCalendarStore from '../calendar/store/sheduleCalendar.store';
import Latness from './components/Latness';
import CreateDelayModal from './components/CreateLatness/CreateLatnessModal';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { theme } from "../../../../_common/theme/theme"

const StyledBadge = styled(Badge)({
    '& .MuiBadge-badge': {
        transform: "scale(1) translate(10%, -50%)"
    },
})

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }}/>
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.primary.white,
      color: theme.palette.primary.black,
      maxWidth: 320,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid",
      borderColor: theme.palette.primary.light2,
    },
  }));


const ChangeWrite = provider(ChangeWriteDataStore, SmsSendModalChangeWriteViewStore, SmsSendDataChangeWriteStore, SheduleSmsTemplatesStore)(observer(() => {
    const [closeModal, setCloseModal] = useState(false)
    const [firstLoad, setFirstLoad] = useState(true)
    const changeWriteViewStore = useInstance(ChangeWriteViewStore)
    const dataStore = useInstance(ChangeWriteDataStore)
    const smsSendModalChangeWriteViewStore = useInstance(SmsSendModalChangeWriteViewStore)
    const smsSendDataChangeWriteStore = useInstance(SmsSendDataChangeWriteStore)
    const smsTimerSendStore = useInstance(SmsTimerSendStore)
    const sheduleSmsTemplates = useInstance(SheduleSmsTemplatesStore)
    const sheduleCalendarStore = useInstance(SheduleCalendarStore)

    useEffect(() => {
        if (!closeModal) {
            dataStore.getDataForChoose().then(() => {
                smsSendDataChangeWriteStore.getSmsTypes()
                const scheduleId = changeWriteViewStore.infoFromShedule
                dataStore.getWriteDataById(changeWriteViewStore.infoFromShedule).then(() => {
                    smsSendDataChangeWriteStore.getCreatedSms(scheduleId)
                    smsSendDataChangeWriteStore.getSmsTemplates(dataStore.setDataForSmsCreate())
                })
                setFirstLoad(false)
            })
        }
    }, [closeModal, changeWriteViewStore.infoFromShedule])

    useEffect(() => {
        if (dataStore.writeId) {
            const writeId = dataStore.writeId
            setFirstLoad(true)
            dataStore.getWriteDataById(writeId).then(() => {
                smsSendDataChangeWriteStore.getCreatedSms(writeId)
                smsSendDataChangeWriteStore.getSmsTemplates(dataStore.setDataForSmsCreate())
            })
            setFirstLoad(false)
        }

    }, [dataStore.writeId])

    useEffect(() => {
        if (smsSendDataChangeWriteStore.activateFindTemplates === true) {
            smsSendDataChangeWriteStore.getSmsTemplates(dataStore.setDataForSmsCreate())
            smsSendDataChangeWriteStore.setActivateFindTemplates(false)
        }
    }, [dataStore, changeWriteViewStore, smsSendDataChangeWriteStore, smsSendDataChangeWriteStore.activateFindTemplates])

    const handleOpenSmsSend = () => {
        smsSendDataChangeWriteStore.setPatient(dataStore.setPatientForSms())
        smsSendDataChangeWriteStore.setWriteData(dataStore.setDataForSmsCreate())
        smsSendModalChangeWriteViewStore.setOpenModal(true)
    }

    const outputSmsSendButton = () => {
        const isTherePatient = (dataStore.writeData?.patient?.id)
        const wrongData = !(dataStore.specialistId && isTherePatient)
        const hintData = smsSendDataChangeWriteStore.smsQtyForSend || null
        const badgeValue = hintData?.shortName || null
        const tooltipValue = hintData?.longName || ""
        return (
            <Box sx={{ mt: 2, width: "100%" }}>
                <StyledTooltip title={tooltipValue} placement="top-start">
                    <StyledBadge
                        badgeContent={badgeValue}
                        invisible={!badgeValue}
                        color="primary"
                        variant="string"
                        sx={{
                            width: "100%",
                        }}
                    >
                        <ButtonMain
                            onClick={() => handleOpenSmsSend()}
                            disabled={wrongData}
                            fullWidth
                            title="Отправка смс"
                        />
                    </StyledBadge>
                </StyledTooltip>
            </Box>

        )
    }


    const outputWriteBlocks = () => {
        if (dataStore.writeData?.isInfo) {
            return (
                <>
                    {!dataStore.writeData?.isActive && <DeletedWriteBlock />}
                    <CommonInfo />
                    <InfoCommentTemplates />
                </>
            )
        } else {
            return (
                <>
                    {!dataStore.writeData?.isActive && <DeletedWriteBlock />}
                    {!!(dataStore.writeData?.delay && dataStore.writeData?.delay !== 0) && <DelayBlock />}
                    { }
                    <PatientBlock />
                    <PreviousWrites />
                    <CommonInfo />
                    <Latness />
                    {outputSmsSendButton()}
                </>
            )
        }

    }

    const handleSave = () => {
        dataStore.saveWrite(smsSendDataChangeWriteStore.smsForSend).then((data) => {
            if (data) {
                if (data.smsSendNow && data.smsSendNow?.length > 0) {
                    data.smsSendNow.forEach((sms, index) => {
                        smsTimerSendStore.pushSmsForSend({
                            write: sms.write,
                            patient: sms.patient,
                            phoneId: sms.phoneId,
                            text: sms.text,
                            outputText: sms.outputText,
                            time: smsTimerValue,
                            show: true,
                            startedTimer: false,
                            id: generateId(index)
                        })
                    })
                }
                sheduleCalendarStore.updateAfterUpdateWrite(data.writeData)
            }
        }).then(() => {
            dataStore.reset()
            changeWriteViewStore.closeModal()
        })
    }

    const handleCloseModal = () => {
        setCloseModal(true)
        changeWriteViewStore.closeModal()
        dataStore.reset()
    }

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            handleSave()
        } else {
            handleCloseModal()
        }
    }

    if (!dataStore.writeData || firstLoad) {
        return (
            <BaseModal
                onClose={handleCloseModal}
                saveClick={handleCloseModal}
                open={changeWriteViewStore.openModal}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <FullPageFallbackProgress />
            </BaseModal>
        )
    }

    return (
        <>
            <BaseModal
                onClose={(event, reason) => handleClose(event, reason)}
                saveClick={handleSave}
                open={changeWriteViewStore.openModal}
                fullWidth={true}
                maxWidth={'xs'}
                castomTitle={
                    <Box sx={{
                        width: "100%",
                        borderBottom: "1px solid",
                        borderColor: "primary.light",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}>
                        <Typography variant="h6">Карточка записи</Typography>
                        <WriteActionsMenu />
                    </Box>
                }
            >
                <Box sx={{ mr: 2, mt: 3 }}>
                    {outputWriteBlocks()}
                </Box>
            </BaseModal>
            {smsSendModalChangeWriteViewStore.openModal && <SmsModalForChangingWrite />}
            {sheduleSmsTemplates.openModal && <SheduleSmsTemplates />}
            {changeWriteViewStore?.openCreateLatness && <CreateDelayModal />}
        </>

    );
}));

export default ChangeWrite;

