import * as React from 'react';
import { Box, Typography, Button, TextField, Paper } from "@mui/material"
import { provider, useInstance } from "react-ioc";
import { observer } from "mobx-react-lite";
import SmsTimerSendStore from './store/sms-timer-send.store';
import BaseModal from '../UI/modals/BaseModal';
import moment from "moment"
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import { SurnameAndInitialsString } from '../../_common/helpers/nameGenerationString';
import { fullNameString } from '../../_common/helpers/nameGenerationString';




const SmsTimerSend = provider()(observer(() => {
    const timerStore = useInstance(SmsTimerSendStore)

    const handleClose = () => {
        timerStore.ignoreChangesInSms()
        timerStore.setOpenModal(false)
    }

    const handleSave = () => {
        timerStore.saveChangesInSms()
        timerStore.setOpenModal(false)
    }

    const fullName = (patient) => {
        return fullNameString(patient?.lastName, patient?.firstName, patient?.patronymic)
    }

    const handleChangeSmsText = (id, text) => {
        timerStore.setSmsNewText(id, text)
    }

    const handleRemoveSms = (id) => {
        timerStore.setDeleteSms(id)
    }

    return (
        <>
            <BaseModal
                open={timerStore.openModal}
                onClose={() => handleClose()}
                saveClick={() => handleSave()}
                title={"Сообщения"}
                maxWidth='md'
            >
                <Box sx={{ p: { xs: 0, sm: 2 }, pt: 0, width: "100%" }}>
                    {timerStore.smsForSend.length === 0 &&
                        <Typography sx={{ textAlign: "center", mt: 4 }} variant="h6">
                            Очередь сообщений пуста
                        </Typography>
                    }
                    {timerStore.smsForSend.length > 0 && timerStore.smsForSend.map((sms) => {
                        if (sms.show && sms.time>0) {
                            const currentTime = sms.time
                            let minutes = Math.floor(currentTime/60)
                            let seconds = currentTime - Math.floor(currentTime/60)*60
                            if (minutes < 10) minutes = "0" + minutes
                            if (seconds < 10) seconds = "0" + seconds
                            return (
                                <Box key={sms.id}>
                                    <Paper sx={{ mt: 3, display: { xs: "none", sm: "block" } }} elevation={2}>
                                        <Box sx={{ borderRadius: 1, display: "flex", justifyContent: "space-between", bgcolor: "primary.light2", alignItems: "center", p: 1 }}>
                                            <Box sx={{ display: "flex" }}>
                                                <Typography sx={{ color: "primary.main", fontWeight: 600 }}>
                                                    {fullName(sms.patient)}
                                                </Typography>
                                                <Typography sx={{ ml: 1 }}>
                                                    до отправки:{" "}{minutes}:{seconds}
                                                </Typography>
                                            </Box>
                                            <Button onClick={() => handleRemoveSms(sms.id)}>
                                                <Typography variant="button">
                                                    Отменить отправку
                                                </Typography>
                                                <CommentsDisabledIcon />
                                            </Button>
                                        </Box>
                                        <Box sx={{ p: 1 }}>
                                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                    <Typography variant="subtitle1">
                                                        Пациент:
                                                    </Typography>
                                                    <Typography>
                                                        {fullName(sms.patient)}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                    <Typography variant="subtitle1">
                                                        Возраст:
                                                    </Typography>
                                                    {sms.patient.birthday ?
                                                        <Typography>
                                                            {moment(sms.patient.birthday).format("DD.MM.YYYY")}
                                                            {sms.patient?.age ? `(${sms.patient?.age})` : ""}
                                                        </Typography>
                                                        : <Typography>Возраст отсутствует</Typography>
                                                    }
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                <Typography variant="subtitle1">
                                                    Запись:
                                                </Typography>
                                                <Typography>
                                                    {moment(sms.write?.date).format("DD.MM.YYYY")} {" на "}
                                                    {sms.write?.timeStart?.slice(0, 5)}
                                                    {" к "}
                                                    {SurnameAndInitialsString(sms.write.specialist.lastName, sms.write.specialist.name, sms.write.specialist.patronymic)}
                                                </Typography>
                                            </Box>
                                            <TextField
                                                sx={{ mt: 1 }}
                                                focused
                                                value={sms.outputText}
                                                multiline
                                                rows={3}
                                                size="small"
                                                fullWidth
                                                onChange={(e) => handleChangeSmsText(sms.id, e.target.value)}
                                            />
                                        </Box>

                                    </Paper>


                                    <Accordion sx={{ mt: 4, display: { xs: "block", sm: "none" } }}>
                                        <AccordionSummary
                                            sx={{ bgcolor: "primary.light2", borderRadius: 1 }}
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", bgcolor: "primary.light2", alignItems: "center", flexWrap: "wrap" }}>
                                                <Box sx={{ display: "flex" }}>
                                                    <Typography sx={{ color: "primary.main", fontWeight: 600 }}>
                                                        {SurnameAndInitialsString(sms.patient.lastName, sms.patient.name, sms.patient.patronymic)}
                                                    </Typography>
                                                    <Typography sx={{ ml: 1, alignItems: "center", display: "flex" }}>
                                                        <AccessAlarmsIcon />{" "}{minutes}:{seconds}
                                                    </Typography>
                                                </Box>
                                                <Button onClick={() => handleRemoveSms(sms.id)}>
                                                    <CommentsDisabledIcon />
                                                </Button>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ pl: 0, pr: 0, display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: { xs: "wrap", sm: "no-wrap" } }}>
                                            <Box sx={{ p: 1 }}>
                                                <Box sx={{ display: "flex", alignItems: "center", gap: 1, flexWrap: "wrap" }}>
                                                    <Typography variant="subtitle1">
                                                        Пациент:
                                                    </Typography>
                                                    <Typography>
                                                        {fullName(sms.patient)}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", alignItems: "center", gap: 1, flexWrap: "wrap" }}>
                                                    <Typography variant="subtitle1">
                                                        Возраст:
                                                    </Typography>
                                                    {sms.patient.birthday ?
                                                        <Typography>
                                                            {moment(sms.patient.birthday).format("DD.MM.YYYY")}
                                                            {sms.patient?.age ? `(${sms.patient?.age})` : ""}
                                                        </Typography>
                                                        : <Typography>Возраст отсутствует</Typography>
                                                    }
                                                </Box>
                                                <Box sx={{ display: "flex", alignItems: "center", gap: 1, flexWrap: "wrap" }}>
                                                    <Typography variant="subtitle1">
                                                        Запись:
                                                    </Typography>
                                                    <Typography>
                                                        {moment(sms.write?.date).format("DD.MM.YYYY")} {" на "}
                                                        {sms.write?.timeStart?.slice(0, 5)}
                                                        {" к "}
                                                        {SurnameAndInitialsString(sms.write.specialist.lastName, sms.write.specialist.name, sms.write.specialist.patronymic)}
                                                    </Typography>
                                                </Box>
                                                <TextField
                                                    sx={{ mt: 1 }}
                                                    focused
                                                    value={sms.outputText}
                                                    multiline
                                                    rows={3}
                                                    size="small"
                                                    fullWidth
                                                    onChange={(e) => handleChangeSmsText(sms.id, e.target.value)}
                                                />
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                            )
                        }else return ""
                    })}
                </Box>
            </BaseModal>
        </>
    );
})
);

export default SmsTimerSend;