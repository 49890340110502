import React, {useEffect} from "react";
import { Box, Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { theme } from "../../_common/theme/theme";

const ErrorPaymentPage = () => {

    useEffect(() => {
        document.title = 'Ошибка оплаты'
      }, [])

  return (
    <Box sx={{
      width: "100%",
      height: "60vh",
      minHeight: 400,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 1,
      p: 2,
    }}>
      <Typography>
        <CancelIcon sx={{ fontSize: 150, color: theme.palette.error.main }} />
      </Typography>
      <Typography variant="h4" sx={{ textAlign: "center" }}>
        Ошибка оплаты
      </Typography>
      <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
        Во время платежа произошла ошибка оплаты
      </Typography>
    </Box>
  );
};

export default ErrorPaymentPage;
