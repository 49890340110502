import {makeAutoObservable} from "mobx";
import {queryFetch} from "../http/http";
import {AUTH_LOGIN, DELETE_TOKEN_COOKIE} from "../mutations/auth";


class AuthStore {
   user = JSON.parse(localStorage.getItem('user')) || {
     id: null,
     email: null,
     firstName: null,
     lastName: null,
     birthday: null,
     sip: null,
     isSpecialist: null,
     isManagementGroup: null,
     isOwnerAdmin: null,
   }
  isAuth = false
  isLoading = false
  lastRequest = null
  menuAnchorEl = null
  medicalCenterId = localStorage.getItem('medical_center_id') || '1'
  currentMedicalCenterId = localStorage.getItem('medical_center_id') || '1'
  currentMedicalCenterPhone = localStorage.getItem('medical_center_phone') || 103
  medicalCentersData = JSON.parse(localStorage.getItem('centers')) || []

  isNavigateHome = localStorage.getItem('is_navigate_home') || false

  constructor() {
    this.checkAuth()
    makeAutoObservable(this)
  }

  setMenuAnchorEl(element) {
    this.menuAnchorEl = element
  }

  setIsLoading(is_loading) {
    this.isLoading = is_loading
  }

  setLastRequest(request) {
    this.lastRequest = request
  }

  setMedicalCentersData(array) {
    this.medicalCentersData = []
    this.medicalCentersData.push(...array)
    localStorage.setItem('centers', JSON.stringify(array))
  }

  setMedicalCenterId(id) {
    this.medicalCenterId = id
    localStorage.setItem('medical_center_id', id)
    this.setCurrentMedicalCenterPhone(id)
  }

  setCurrentMedicalCenterId(id) {
    this.currentMedicalCenterId = id
    this.setCurrentMedicalCenterPhone(id)
  }

  setCurrentMedicalCenterPhone(centerId) {
    const center = this.medicalCentersData.filter((center)=>center.id===centerId)
    this.currentMedicalCenterPhone = center[0].internalNumber
    localStorage.setItem('medical_center_phone', center[0].internalNumber)
  }

  setIsAuth(is_auth) {
    this.isAuth = is_auth
  }

  setUser(user) {
    localStorage.setItem('user', JSON.stringify(user))
    this.user = user
  }

  async login(username, password) {
    const res = await queryFetch(AUTH_LOGIN, {username, password})
    const data = res.data?.tokenAuth
    if (data) {
      this.setUser(data.user)
      this.setMedicalCentersData(data.centers)
      this.setIsNavigateHome(false)
      this.setIsAuth(true)

      localStorage.setItem('chat_sound_play', JSON.stringify(false))
      localStorage.setItem('chat_sound_started', JSON.stringify(false))
      return true
    }
    return false
  }

  async logout() {
    await queryFetch(DELETE_TOKEN_COOKIE)
    localStorage.removeItem('user')
    localStorage.removeItem('sub')
    localStorage.removeItem('email')
    localStorage.removeItem('centers')
    localStorage.removeItem('medical_center_id')
    localStorage.removeItem('medical_center_phone')
    localStorage.removeItem('schedule_calendar_date')

    localStorage.setItem('chat_sound_play', JSON.stringify(false))
    localStorage.setItem('chat_sound_started', JSON.stringify(false))
    this.setIsAuth(false)
  }

  setIsNavigateHome(is_navigate){
     this.isNavigateHome = is_navigate
     localStorage.setItem('is_navigate_home', is_navigate)
  }

  checkAuth(){
     if (this.user.id){
       this.setIsAuth(true)
     }
  }

}

const authStore = new AuthStore()
export default authStore