import React, { useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { useInstance } from "react-ioc";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormControl
} from "@mui/material";
import CreateWriteViewStore from '../store/CreateWriteView.view.store';
import CreateWriteStore from '../store/CreateWrite.data.store';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import InfoIcon from '@mui/icons-material/Info';
import { fullNameString } from '../../../../../_common/helpers/nameGenerationString';
import moment from "moment";
import CommonInfo from './CommonInfo';
import SmsSendDataCreateWrite from '../store/smsSendData.store';


const WriteBlock = (observer(() => {
  const [patienterror, setPatientError] = useState(false)
  const viewStore = useInstance(CreateWriteViewStore)
  const store = useInstance(CreateWriteStore)
  const smsSendDataCreateWrite = useInstance(SmsSendDataCreateWrite)

  useEffect(()=>{
    if (store.patientError){
      setPatientError(true)
    }
  }, [store, store.patientError])

  const handleOpenInfoWrite = () => {
    viewStore.setIsInfo(true)
  }

  const handleOpenCreatePatient = () => {
    viewStore.setIsNewPatient(true)
  }

  const handleSetPatient = (patient) => {
    store.setPatientError(false)
    setPatientError(false)
    store.setPatient(patient)
    smsSendDataCreateWrite.setActivateFindTemplates(true)
  }

  let timeout = null;

  const handleSearchPatient = (strFind) => {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      store.searchPatient(strFind.replace(/ /g, ","))
    }, 200)

  }
  
  return (
    <>
      <Box sx={{ p: 1 }}>
        <Button onClick={handleOpenInfoWrite} size="small" variant="outlined" fullWidth>
          <Typography variant="button" sx={{ mr: 1 }}>
            Создать информационную запись
          </Typography>
          <InfoIcon />
        </Button>
        <Button onClick={handleOpenCreatePatient} size="small" variant="outlined" fullWidth sx={{ mt: 1 }}>
          <Typography variant="button" sx={{ mr: 1 }}>
            Создать запись для нового пациента
          </Typography>
          <PersonAddIcon />
        </Button>
      </Box>
      <Grid container spacing={2} sx={{ p: 1 }}>
        <Grid item xs={12} sx={{mt: 1}}>
          <Typography>Данные записи:</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth focused>
            <Autocomplete
              value={store.patient ? store.patient : null}
              size="small"
              disablePortal
              options={store.patients}
              loading={store.loadingPatients}
              id="patients-create-write"
              getOptionLabel={(option) => fullNameString(option.lastName, option.firstName, option.patronymic)}
              onChange={(event, value) => handleSetPatient(value)}
              renderOption={(props, option) => (
                <Box {...props} key={option.id} sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                 <Box>{fullNameString(option?.lastName, option?.firstName, option?.patronymic)}</Box>

                  {option?.birthday &&
                    <Box sx={{ width: 130, textAlign: "end" }}>
                      {moment(option?.birthday).format("DD.MM.YYYY")}
                    </Box>
                  }
                </Box>
              )}
              renderInput={(params) => {
                return (
                  <TextField
                    error={patienterror}
                    color={patienterror && "error"}
                    onChange={(event) => handleSearchPatient(event.target.value)}
                    {...params}
                    label={"Пациент"}
                    variant="outlined"
                    focused={true}
                    size={"small"}
                  />
                );
              }}
            />
          </FormControl>
        </Grid>
        {/* {store.patientError ? "qqq" : "wwww"} */}
      </Grid>
      <CommonInfo />
    </>
  );
}));

export default WriteBlock;

