import React from 'react';
import {
    Box, Dialog,
    DialogContent,
    DialogTitle,
    Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { useInstance } from "react-ioc";
import { useQuery } from "@apollo/client";
import { FullPageFallbackProgress } from "../../../../../_components/UI/preloaders/FullPageFallbackProgress";
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { LOG_INFO } from '../../../queries/modal.queries';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ChangeWriteViewStore from '../store/ChangeWriteView.view.store';
 
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.primary.light3,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const WriteLogModal = observer(() => {
    const store = useInstance(ChangeWriteViewStore)

    const handleClose = () => {
        store.setOpenLogModal(false)
    }

    const { data, loading, error } = useQuery(LOG_INFO, {
        variables: { scheduleId: store?.infoFromShedule }
    })
    if (loading) {
        return (
            <Dialog
                onClose={handleClose}
                open={store.openLogModal}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <FullPageFallbackProgress />
            </Dialog>
        )
    }
    if (error) {
        return (
            <Dialog
                onClose={handleClose}
                open={store.openLogModal}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <Box sx={{ p: 4 }}>
                    <Typography> Возникла ошибка</Typography>
                </Box>
            </Dialog>
        )
    }

    const statusSwitch = (status) =>{
        switch (status){
            case "Подтвержденная запись":
                return "Подтвержд."
            case "Новая запись":
                return "Новая"
            default:
                return status
        }
    }

    return (
        <Dialog
            onClose={handleClose}
            open={store.openLogModal}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle sx={{
                display: 'flex',
                alignitems: 'center',
                justifyContent: 'space-between',
                backgroundColor: 'primary.white',
                p: 1, pl: 2
            }}>
                <Box>Лог записи</Box>
            </DialogTitle>
            <DialogContent dividers sx={{ p: 2 }}>
                {data?.scheduleLogInfo &&
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ minWidth: 100, maxWidth: 100 }}>Дата и время</TableCell>
                                    <TableCell sx={{ minWidth: 120, maxWidth: 120 }}>Специалист</TableCell>
                                    <TableCell sx={{ minWidth: 150, maxWidth: 150 }}>Пациент</TableCell>
                                    <TableCell sx={{ maxWidth: {xs: 100, sm: 200, md:320}, minWidth: {xs: 120, sm:200},  width: "100%" }}>Описание</TableCell>
                                    <TableCell sx={{ minWidth: 95, maxWidth: 95 }}>Статус</TableCell>
                                    <TableCell sx={{ minWidth: 95, maxWidth: 95, textAlign: "center" }}>
                                        Опоздание
                                    </TableCell>
                                    <TableCell sx={{ minWidth: 80, maxWidth: 80 }}>Активность</TableCell>
                                    <TableCell sx={{ minWidth: 80, maxWidth: 80 }}>Изменение</TableCell>
                                    <TableCell sx={{ minWidth: 120, maxWidth: 120 }}>Автор</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.scheduleLogInfo.map((row) => (
                                    <StyledTableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>
                                            <Typography variant="body2">
                                                {moment(row.date).format("DD.MM.YYYY")}
                                            </Typography>
                                            <Typography variant="body2">
                                                {row.time}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{pl:0.5, pr:0.5}}>
                                            {row.specialist}
                                        </TableCell>
                                        <TableCell sx={{pl:0.5, pr:0.5}}>
                                            {row.patient}
                                        </TableCell>
                                        <TableCell sx={{pl:0.5, pr:0.5}}>
                                            {row.description}
                                        </TableCell>
                                        <TableCell>
                                            {!row.isInfo && statusSwitch(row.status)}
                                            <br />
                                            {row.isStudent && " Ученическая"}
                                            <br />
                                            {row.isInfo && "Информационная"}
                                        </TableCell>
                                        <TableCell sx={{textAlign: "center"}}>
                                            {row?.delay}
                                        </TableCell>
                                        <TableCell alignitems='center'>
                                            <Typography sx={{ textAlign: "center" }}>
                                                {row.isActive && <CheckCircleOutlineIcon sx={{ color: "primary.deepGreen" }} />}
                                                {!row.isActive && <HighlightOffIcon sx={{ color: "primary.lightred" }} />}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>{moment(row.timeCreation).format("DD.MM.YYYY HH:mm")}</TableCell>
                                        <TableCell>
                                            {row.author}
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }

            </DialogContent>
        </Dialog>
    );
});

export default WriteLogModal;