import React, {memo, useMemo} from 'react';
import Grid from "@mui/material/Grid"
import MessageItem from "./MessageItem"
import { observer } from "mobx-react-lite"
import { useInstance } from "react-ioc"
import StoreChat from "../stores/storeChat"


const InboxMessages = observer(() => {

  const storeChat = useInstance(StoreChat)

  const getMessages = useMemo(
    () => storeChat.subscriptionData?.map((el) => {

      return (
        <Grid item xs={12} key={el.id}
              sx={{
                marginBottom: "10px"
              }}
        >
          <MessageItem
            text={el.text}
            id={el.id}
            firstName={el.fromUser.firstName}
            lastName={el.fromUser.lastName}
            patronymic={el.fromUser.patronymic}
            userId={el.fromUser.id}
          />
        </Grid>
      )
    }),
    [storeChat.subscriptionData]
  );

  return (

    <Grid container>

      {getMessages}
    </Grid>
  );
});

export default memo(InboxMessages);