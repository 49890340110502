import { makeAutoObservable, toJS } from "mobx";
import { client } from "../../../_common/http/appoloClient"
import {
  READ_ALL_CHAT_MESSAGE_AND_SET_INACTIVE,
  READ_ONE_CHAT_MESSAGE_AND_SET_INACTIVE,
  SEND_CHAT_MESSAGE
} from "../_mutations/chatMessages.mutation"
import { GET_ALL_TEMPLATES, SPEC_DAY } from "../_queries/chat.queries"

import moment from "moment"
import { CHAT_MESSAGES_SUBSCRIPTION, ENDED_CHAT_SUBSCRIPTION } from "../_subscriptions/chatSubscriptions.subscriptions"
// import sound from "../../../_common/assets/sounds/beep.wav"
import beep from "../../../_common/assets/sounds/beep.wav"
// import authStore from "../../../_common/stores/auth.store"


export default class StoreChatModal {

  state = {
    openModal: false,
    allUsers: null,
    specialistInfo: "",
    messageText: "",
    messagesData: [],
    chatClosed: true,

    messageShortButtons: [],
    buttonsText: "",
    userFromId: "",
    userId: "",

    userIdRequest: "",

    preloader: false,

    subscriptionData: [],

    // audio: new Audio(beep),

    audioBlock: new Audio(beep),

    hideBanner: false,

  }


  constructor() {
    makeAutoObservable(this)
  }

  get openModal() {
    return this.state.openModal
  }

  get subscriptionData() {
    return toJS(this.state.subscriptionData)
  }

  setSubscriptionData(data) {
    this.state.subscriptionData = data
  }


  play() {

    // this.state.audio.src = sound
    // // this.state.audio.loop = true;
    // this.state.audio.muted = false
    // this.state.audio.play();

    // console.log("PLAY", toJS(this.state.audio))

    this.state.audioBlock.muted = false
    this.state.audioBlock.play()
  }

  intervalPlay() {
    const flagSoundStarted = localStorage.getItem('chat_sound_started')
    const flagInterval = JSON.parse(flagSoundStarted)

    const timerInterval = setInterval(() => {

      const flagValue = localStorage.getItem('chat_sound_play')
      const flag = JSON.parse(flagValue)


      if (flag ) {

        if (!flagInterval && localStorage.getItem('user') !== null) {

          // console.log("play....")
          localStorage.setItem('chat_sound_started', JSON.stringify(true))
          this.play()
        }
      } else {

        // console.log("else....")
        localStorage.setItem('chat_sound_started', JSON.stringify(false))
        clearInterval(timerInterval)
      }
    }, 1000)
  }


  addSubscriptionData(data, id) {

    
    if (localStorage.getItem('user') !== null) {

      this.state.subscriptionData.push(data)
      localStorage.setItem('chat_sound_play', JSON.stringify(true))
    }

    this.intervalPlay()
  }


  async setChatMessages(id) {


    if (!!id) {

      const result = await client.subscribe({
        query: CHAT_MESSAGES_SUBSCRIPTION,
        variables: {
          userId: id
        }
      })


      result.subscribe({
        next: (data) => {

          const hasMessageWithTheId = this.state.subscriptionData.some(obj => obj.id === data.data.chatMessages.id)

          if (!hasMessageWithTheId && data?.data.chatMessages.newMessagesCount !== 0) {

            return this.addSubscriptionData(data.data.chatMessages, id)
          } else return
        },
        error: (error) => {
          console.error('Subscription error:', error)
        },
      });

    }
  }


  async setChatMessagesEnd(id) {

    if (!!id) {

      const result = await client.subscribe({
        query: ENDED_CHAT_SUBSCRIPTION,
        variables: {
          userId: id
        }
      })


      result.subscribe({
        next: (data) => {


          if (!!data?.data.messageRead) {

            if (data?.data.messageRead.newMessagesCount <= 0) {

              this.clearMessages()
            }
          }
        },

        error: (error) => {
          console.error('Subscription error:', error)
        },
      });

    }
  }


  async getSpecialists(center) {


    await client.query({
      query: SPEC_DAY,
      variables: {
        "centerId": center,
        "dateStart": moment(new Date()).format("YYYY-MM-DD"),
        "dateEnd": moment(new Date()).format("YYYY-MM-DD"),
        "isActive": true
      }

    }).then((data) => {

      this.setAllUsers(data?.data.table)
    })

  }

  async getTemplates() {


    await client.query({

      query: GET_ALL_TEMPLATES,
    }).then((data) => {

      this.setMessageShortButtons(data?.data.allTemplates)
    })
  }

  setOpenOneMessage(open) {

    this.state.chatClosed = open

  }

  setOpenChatModal(open) {

    this.state.openModal = open

  }

  get chatClosed() {

    return this.state.chatClosed
  }


  setMessageShortButtons(data) {

    this.state.messageShortButtons = data
  }

  get messageShortButtons() {

    return this.state.messageShortButtons
  }

  clearMessages() {

    this.state.subscriptionData = []

    this.muteAudio()
  }



  muteAudio() {

    localStorage.setItem('chat_sound_play', JSON.stringify(false))
    this.state.audioBlock.muted = true
    this.state.audioBlock.currentTime = 0
    this.state.audioBlock.pause()
  }

  async setMessagesRead() {


    await client.mutate({

      mutation: READ_ALL_CHAT_MESSAGE_AND_SET_INACTIVE,

    }).then((data) => {

    }).catch((err) => console.log(`Ошибка прочтения всех сообщений. Текст ошибки: ${err}`))
  }

  async setOneMessagesRead(id) {


    await client.mutate({

      mutation: READ_ONE_CHAT_MESSAGE_AND_SET_INACTIVE,
      variables: {
        messageId: +id
      }
    }).then((data) => {

      this.state.subscriptionData = this.state.subscriptionData.filter((el) => {
        return el.id !== id
      })


    }).catch((err) => console.log("Ошибка прочтения одного сообщения. Текст ошибки: ", err))
  }


  setClearUsers() {

    this.state.allUsers = null
  }

  get messagesData() {

    return this.state.messagesData
  }

  setAllUsers(data) {

    this.state.allUsers = data
  }


  get allUsers() {

    return this.state.allUsers
  }

  setUserId(data) {

    this.state.userId = +data
  }

  get userId() {

    return this.state.userId
  }

  setSpecialistInfo(data) {

    this.state.specialistInfo = data
  }

  get specialistInfo() {

    return this.state.specialistInfo
  }


  setMessageText(data) {

    this.state.messageText = data
  }

  setButtonText(data) {

    this.state.buttonsText = data
    this.state.messageText = this.state.messageText + " " + this.state.buttonsText + " "
  }


  get messageText() {

    return this.state.messageText
  }

  setMessageTextClear() {

    this.state.messageText = ""
  }


  async sendChatMessage(additionalText) {


    await client.mutate({

      mutation: SEND_CHAT_MESSAGE,
      variables: {
        input: {
          text: additionalText ? additionalText : this.state.messageText,
          toUser: +this.state.specialistInfo,
        },
      },
    }).then(() => {

      // console.log("SUCCESS sendChatMessage")
    }).catch((err) => console.log("ОШИБКА ОТПРАВКИ МУТАЦИИ ОТПРАВКИ СООБЩЕНИЯ. Текст ошибки: ", err))
  }


  async sendMessageByDone(additionalText, id, userId) {

    await client.mutate({

      mutation: SEND_CHAT_MESSAGE,
      variables: {
        input: {
          text: additionalText ? additionalText : this.state.messageText,
          toUser: userId,
        },
      },
    }).then((data) => {

      this.state.subscriptionData = this.state.subscriptionData.filter((el) => {
        return el.id !== id
      })


    }).catch((err) => console.log("ОШИБКА МУТАЦИИ ОТВЕТА НА ОДНО СООБЩЕНИЕ ПО ГАЛОЧКЕ/КРЕСТИКУ. Текст ошибки: ", err))
  }
}