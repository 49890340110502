import React from 'react';
import { observer } from "mobx-react-lite"
import { Box, Paper } from "@mui/material"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import DoneIcon from "@mui/icons-material/Done"
import CloseIcon from "@mui/icons-material/Close"
import { useInstance } from "react-ioc"
import StoreChat from "../stores/storeChat"
import { styled } from "@mui/material/styles"

const StyledOneMessagePaper = styled(Paper)(({theme}) => ({
  width: "300px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "10px",
  gap: "8px"
}));


const OneMessage = observer((props) => {

  const storeChat = useInstance(StoreChat)


  const wrapOneMessageText = (text) => {

    if (!!text) {
      if (text.length > 31) {
        return text.slice(0, 30).concat("...")
      } else return text
    } else return ""
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: "10%",
        right: (theme) => theme.spacing(2),
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
        left: (theme) => theme.spacing(2),
        gap: 2,
        zIndex: 10,
      }}
    >
      <StyledOneMessagePaper elevation={4}>
        <Typography>
          {storeChat.subscriptionData[0].fromUser.lastName} {storeChat.subscriptionData[0].fromUser.firstName ? storeChat.subscriptionData[0].fromUser.firstName.charAt(0) + "." : ""} {storeChat.subscriptionData[0].fromUser.patronymic ? storeChat.subscriptionData[0].fromUser.patronymic.charAt(0) + "." : ""}
        </Typography>

        <Typography>
          {wrapOneMessageText(storeChat.subscriptionData[0].text)}
        </Typography>

        {storeChat.subscriptionData[0].text !== "Принято" && <IconButton onClick={() => {

          storeChat.sendMessageByDone("Принято", storeChat.subscriptionData[0].id, storeChat.subscriptionData[0].fromUser.id)

          storeChat.setOpenOneMessage(false)
          storeChat.setOneMessagesRead(storeChat.subscriptionData[0].id)

          localStorage.setItem('chat_sound_play', JSON.stringify(false))
        }
        }>
          <DoneIcon sx={{
            color: "#3F51B5"
          }} />
        </IconButton>}


        {storeChat.subscriptionData[0].text === "Принято" && <IconButton onClick={() => {

          storeChat.setOpenOneMessage(false)
          storeChat.setOneMessagesRead(storeChat.subscriptionData[0].id)

          localStorage.setItem('chat_sound_play', JSON.stringify(false))

        }
        }>
          <CloseIcon sx={{
            color: "#3F51B5"
          }} />
        </IconButton>}

      </StyledOneMessagePaper>
    </Box>

  );
});

export default OneMessage;