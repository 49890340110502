import { makeAutoObservable, toJS } from "mobx";
import Moment from 'moment';
import moment from "moment"
import { inject } from "react-ioc"
import StoreChatModal from "../../../_components/Chat/stores/storeChat"
import authStore from "../../../_common/stores/auth.store"


export default class TableViewStore {

  storeChatModal = inject(this, StoreChatModal);


  state = {
    calendarRef:null,
    calendarDate: null,
    calendarFullDate: null,
    openDatePicker: false,
    anchorEl: null,
    openModalRecord: false,
    event: {},
    popoverEvent: {},
    tableCalendarDayToGo: null,
    scheduleDayToGo: null,
    refetching: false,

    spec: null,
    specData: null,

    specFlag: false,
    users: [{id: 0, lastName: ""}],

    user: "0",

    tableSpecialists: null,
    tableSpecialistBySpecId: null,
    allSpecialists: null
  }
  
  firstCalendarDate = null
  lastCalendarDate = null

  constructor() {
    makeAutoObservable(this)
  }

  get refetching() {
    return this.state.refetching
  }

  get firstCalendarDateGet() {
    return this.firstCalendarDate
  }

  setSpec(data){

    this.state.spec = data
  }

  setTableSpecialists(data){

    if (data) {

      this.clearSpecialistsList()
      this.state.tableSpecialists = data.map((el) => {

        return {
          title: `${el?.specialist.lastName} ${el?.specialist?.firstName ? el?.specialist.firstName.charAt(0) + "." : ""} ${el?.specialist?.patronymic ? el?.specialist.patronymic.charAt(0) + "." : ""}`,
          date: el?.date,
          time: `${el?.timeStart.slice(0, -3)}-${el?.timeEnd.slice(0, -3)}`,
          center: el?.medicalCenter?.name,
          specialistId: el?.specialist?.id,
          personStatus: el?.role,
        }
      })
    }
  }

  get tableSpecialists(){

    return this.state.tableSpecialists
  }

  seTableSpecialistBySpecId(data){


    if (data) {
      this.clearSpecialistByIdData()

      this.state.tableSpecialistBySpecId = data.map((el) => {

        return {
          title: `${el?.specialist.lastName} ${el?.specialist?.firstName ? el?.specialist.firstName.charAt(0) + "." : ""} ${el?.specialist?.patronymic ? el?.specialist.patronymic.charAt(0) + "." : ""}`,
          date: el?.date,
          time: `${el?.timeStart.slice(0, -3)}-${el?.timeEnd.slice(0, -3)}`,
          center: el?.medicalCenter?.name,
          specialistId: el?.specialist?.id,
          personStatus: el?.role,
        }
      })
    }
  }

  get tableSpecialistBySpecId(){

    return this.state.tableSpecialistBySpecId
  }

  setAllSpecialists(){


    if(!!this.state.tableSpecialists && !!this.state.tableSpecialistBySpecId) {


      this.state.allSpecialists = this.state.tableSpecialists.concat(this.state.tableSpecialistBySpecId)

    } else return
  }

  get allSpecialists(){

    return this.state.allSpecialists
  }

  clearSpecialistsList(){

    this.state.tableSpecialists = []
  }

  clearSpecialistByIdData(){

    this.state.tableSpecialistBySpecId = []
  }

  clearAllSpecialists(){

    this.state.allSpecialists = []
  }

  get spec(){

    return this.state.spec
  }

  setSpecData(data){

    this.state.specData = data
  }

  get specData(){

    return this.state.specData
  }


  setUsers(data){

    if (data){
      this.state.users = [...this.state.users, ...data]
    }
  }

  get users(){

    return this.state.users
  }

  setUser(id){
    this.state.user = id
  }

  get user(){

    return this.state.user
  }

  clearUser(){

    this.state.user = null
  }



  get lastCalendarDateGet() {
    return this.lastCalendarDate
  }

  get openDatePicker() {
    return this.state.openDatePicker
  }

  get calendarDate() {
    return this.state.calendarDate
  }

  get calendarFullDate() {
    return this.state.calendarFullDate
  }

  get openModalRecord() {
    return this.state.openModalRecord
  }

  get anchorEl() {
    return this.state.anchorEl
  }

  get isPopoverOpen() {
    return Boolean(this.state.anchorEl)
  }

  get popoverEvent() {
    return this.state.popoverEvent
  }

  setRefetching(data) {

    this.state.refetching = data
    this.storeChatModal.getSpecialists(authStore.medicalCenterId)
  }

  setOpenDatePicker(open) {
    this.state.openDatePicker = open
  }

  setCalendarNext() {
    this.state.calendarRef.current.getApi().next()
    this.setCalendarDate()
  }

  setCalendarTimelineDay() {
    this.state.calendarRef.current.getApi().changeView('resourceTimelineDay');
  }

  setCalendarTimelineWeek() {
    this.state.calendarRef.current.getApi().changeView('resourceTimelineWeek');
  }

  setCalendarTimelineMonth() {
    this.state.calendarRef.current.getApi().changeView('resourceTimelineMonth');
  }

  setCalendarPrev() {
    this.state.calendarRef.current.getApi().prev()
    this.setCalendarDate()
  }

  setCalendarToday() {
    this.state.calendarRef.current.getApi().today()
    this.setCalendarDate()
  }

  setCalendarRef(ref) {
    this.state.calendarRef = ref
  }

  setGoCalendarDate(date) {

    this.state.calendarRef.current.getApi().gotoDate(date)
    this.setCalendarDate()
  }

  setCalendarDate() {
    const date = this.state.calendarRef.current.getApi().getDate()

    // this.state.calendarDate = Moment(date).format("MMMM YYYY");

    this.state.calendarDate = moment(date).format("YYYY-MM-DD")
    this.state.calendarFullDate = date;

    this.clearUser()
  }

  setAnchorEl(element) {
    this.state.anchorEl = element
  }

  setOpenModalRecord(isOpen) {
    this.state.openModalRecord = isOpen
  }

  setEvent(event) {
    this.state.event = event
  }

  setPopoverEvent(event) {
    this.state.popoverEvent = event
  }

  setFirstCalendarDate(date) {
    this.firstCalendarDate = date
  }

  setLastCalendarDate(date) {
    this.lastCalendarDate = date
  }

  setScheduleDayToGo(date) {

    this.state.scheduleDayToGo =  moment(date).format("YYYY-MM-DD")
  }

  get scheduleDayToGo () {

    return this.state.scheduleDayToGo
  }


}