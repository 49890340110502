export function fullNameString(surname, name, patronymic) {
    const isSurname = surname ? true : false,
        isName = name ? true : false,
        isPatronymic = patronymic ? true : false;

    const fullName = (isSurname ? ` ${surname}` : "")  + (isName ? ` ${name}` : "") + (isPatronymic ? ` ${patronymic}` : "")

    return fullName
}

export function SurnameAndInitialsString(surname, name, patronymic) {
    const isName = name && name !== null ? true : false,
        isPatronymic = patronymic && patronymic !== null ? true : false;

    const surnameAndInitials = surname + (isName ? ` ${name.substring(0, 1)}.` : "") + (isPatronymic ? ` ${patronymic.substring(0, 1)}.` : "")

    return surnameAndInitials
}

export function NameAndPatronymicString(name, patronymic) {
    const isName = name && name != null ? true : false,
        isPatronymic = patronymic && patronymic != null ? true : false;

    const nameAndPatronymic = (isName ? name : "") + (isPatronymic ? ` ${patronymic}` : "")

    return nameAndPatronymic
}