import React, { useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { useInstance } from "react-ioc";
import { Button } from "@mui/material";
import ChangeWriteDataStore from '../store/ChangeWriteData.data.store';


const InfoCommentTemplates = (observer(() => {
  const store = useInstance(ChangeWriteDataStore)

  useEffect(()=>{
    store.getInfoComments()
  }, [store])

  const handleAddTemplate = (templateText) => {
    store.setAddTextToComment(templateText)
  }

  return (
    <>
      {store?.infoComments?.length>0 && store?.infoComments?.map((template)=>{
        return(
            <Button key={template.id} size="small" onClick={()=>handleAddTemplate(template.comment)}>
                {template.comment}
            </Button>
        )
      })}
    </>
  );
}));

export default InfoCommentTemplates;

