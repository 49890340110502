import { gql } from "@apollo/client";

export const SMS_TEMPLATES = gql`
query smsTemplates{
  allSmsTemplates{
    id
    text
    identifier
  }
}
`