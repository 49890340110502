import {
    makeAutoObservable,
} from "mobx";
import {
    client
} from "../../../../_common/http/appoloClient";
import {
    SMS_BALANCE
} from "../queries/smsBalanceQuery";

export default class SmsBalanceStore {

    state = {
        balance: null,
        refetchBalance: true
    }

    get refetchBalance() {
        return this.state.refetchBalance
    }

    switchRefetchBalance() {
        this.state.refetchBalance = !this.state.refetchBalance
    }

    get balance() {
        return this.state.balance
    }

    setBalance(balance) {
        if (balance) {
            this.state.balance = balance
        }
    }

    async getSmsBalance() {
        await client.query({
            query: SMS_BALANCE
        }).then((data) => {
            this.setBalance(data.data.smsBalance)
        })
    }

    constructor() {
        makeAutoObservable(this)
    }
}