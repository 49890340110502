import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { useInstance } from "react-ioc";
import { provider } from "react-ioc";
import CreateWriteViewStore from './store/CreateWriteView.view.store';
import CreateWriteStore from './store/CreateWrite.data.store';
import WriteBlock from './components/WriteBlock';
import InfoWriteBlock from './components/InfoWriteBlock';
import CreatePatientBlock from './components/CreatePatientBlock';
import moment from "moment";
import SmsSendDataCreateWrite from './store/smsSendData.store';
import SmsSendModalCreateWriteViewStore from './store/SmsSendModalCreateWrite.view.store';
import ButtonMain from '../../../../_components/UI/Buttons/ButtonMain';
import authStore from '../../../../_common/stores/auth.store';
import SmsTimerSendStore from '../../../../_components/SmsTimerSend/store/sms-timer-send.store';
import { FullPageFallbackProgress } from '../../../../_components/UI/preloaders/FullPageFallbackProgress';
import BaseModal from '../../../../_components/UI/modals/BaseModal';
import SmsModalFromCreateWrite from './smsSendForCreatingWrite/smsModalFromCreateWrite';
import SheduleSmsTemplates from '../sheduleSmsTemplates/sheduleSmsTemplates'
import SheduleSmsTemplatesStore from '../sheduleSmsTemplates/store/sheduleSmsTemplates.store';
import { smsTimerValue } from '../../../../_common/helpValues/smsConstants';
import { generateId } from '../../../../_common/helpers/generateId';
import SheduleCalendarStore from '../calendar/store/sheduleCalendar.store';
import { Badge } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { theme } from "../../../../_common/theme/theme"

const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    transform: "scale(1) translate(10%, -50%)"
  },
})

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.black,
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid",
    borderColor: theme.palette.primary.light2,
  },
}));

const CreateWrite = provider(CreateWriteStore, CreateWriteStore, SmsSendModalCreateWriteViewStore, SmsSendDataCreateWrite, SheduleSmsTemplatesStore)(observer(() => {
  const [closeModal, setCloseModal] = useState(false)
  const viewStore = useInstance(CreateWriteViewStore)
  const store = useInstance(CreateWriteStore)
  const smsSendModalCreateWriteViewStore = useInstance(SmsSendModalCreateWriteViewStore)
  const smsSendDataCreateWrite = useInstance(SmsSendDataCreateWrite)
  const smsTimerSendStore = useInstance(SmsTimerSendStore)
  const sheduleSmsTemplates = useInstance(SheduleSmsTemplatesStore)
  const sheduleCalendarStore = useInstance(SheduleCalendarStore)
  const [isSaved, setIsSaved] = useState(false)

  useEffect(() => {
    if (!closeModal && store && smsSendDataCreateWrite && viewStore && viewStore.infoFromShedule?.info?.start && viewStore.infoFromShedule?.info?.end && viewStore.infoFromShedule?.info?.resource?.id && viewStore.infoFromShedule?.center) {
      store.setAuthStoreMedicalCenterId(authStore.medicalCenterId)
      store.getDataForChooseCommonInfo()
      store.setDateAfterOpen(viewStore.infoFromShedule?.info?.start)
      store.setTimeStart(moment(viewStore.infoFromShedule?.info?.start).format("HH:mm:ss").slice(0, 5))
      store.setTimeEnd(moment(viewStore.infoFromShedule?.info?.end).format("HH:mm:ss").slice(0, 5))
      store.getSpecialistsFromOpen(viewStore.infoFromShedule?.info?.resource?.id, viewStore.infoFromShedule?.center, viewStore.infoFromShedule?.info?.start)
      store.searchPatient("")
      smsSendDataCreateWrite.getSmsTypes()
    }
  }, [closeModal, store, smsSendDataCreateWrite, viewStore, viewStore.infoFromShedule?.info?.start, viewStore.infoFromShedule?.info?.end, viewStore.infoFromShedule?.info?.resource?.id, viewStore.infoFromShedule?.center])

  useEffect(() => {
    if (smsSendDataCreateWrite.activateFindTemplates === true) {
      smsSendDataCreateWrite.getSmsTemplates(store.setDataForGettingSmsTemplates(viewStore.isNewPatient))
      smsSendDataCreateWrite.setActivateFindTemplates(false)
    }
  }, [store, viewStore, viewStore.isNewPatient, smsSendDataCreateWrite, smsSendDataCreateWrite.activateFindTemplates])

  const info = viewStore.isInfo === true && viewStore.isNewPatient !== true,
    newPatient = viewStore.isInfo !== true && viewStore.isNewPatient === true,
    write = viewStore.isInfo !== true && viewStore.isNewPatient !== true;

  const handleOpenSmsSend = () => {
    smsSendModalCreateWriteViewStore.setOpenModal(true)
    smsSendDataCreateWrite.setPatient(store.setPatientAndPhonesForSms(viewStore.isNewPatient))
  }

  const outputSmsSendButton = () => {
    const isTherePatient = (write && store.patient?.id) || (newPatient && store.newPatientEssentialData.surname.length > 0 && store.newPatientEssentialData.name.length > 0 && store.newPatientEssentialData.phones[0].number.length > 0)
    const wrongData = !(store.specialistId && isTherePatient)
    const hintData = smsSendDataCreateWrite.smsQtyForSend || null
    const badgeValue = hintData?.shortName || null
    const tooltipValue = hintData?.longName || ""
    return (
      <StyledTooltip title={tooltipValue} placement="top-start">
        <StyledBadge
          badgeContent={badgeValue}
          invisible={!badgeValue}
          color="primary"
          variant="string"
          sx={{
            width: "100%",
          }}
        >
          <ButtonMain
            onClick={() => handleOpenSmsSend()}
            disabled={wrongData}
            fullWidth
            title="Отправка смс"
          />
        </StyledBadge>
      </StyledTooltip>

    )
  }

  const outputWrite = () => {
    if (info) {
      return (
        <InfoWriteBlock />
      )
    } else if (newPatient) {
      return (
        <>
          <CreatePatientBlock />
          {outputSmsSendButton()}
        </>

      )
    } else if (write) {
      return (
        <>
          <WriteBlock />
          {outputSmsSendButton()}
        </>

      )
    } else {
      alert("произошла ошибка в создании записи")
      viewStore.closeModal()
    }
  }

  const handleCloseModal = () => {
    setCloseModal(true)
    viewStore.closeModal()
    smsSendModalCreateWriteViewStore.setOpenModal(false)
    smsSendDataCreateWrite.reset()
  }

  const handleSave = () => {
    setIsSaved(true)
    if (!isSaved) {
      if (store.savePermission(viewStore.isInfo, viewStore.isNewPatient)) {
        store.createWrite(
          viewStore.isInfo,
          viewStore.isNewPatient,
          smsSendDataCreateWrite.smsActivateAndText
        ).catch(() => {
          setIsSaved(false)
        }).then((data) => {
          if (data) {
            if (data?.smsForSendNow?.length > 0) {
              data.smsForSendNow.forEach((sms, index) => {
                smsTimerSendStore.pushSmsForSend({
                  write: sms.write,
                  patient: sms.patient,
                  phoneId: sms.phoneId,
                  text: sms.text,
                  outputText: sms.outputText,
                  time: smsTimerValue,
                  show: true,
                  startedTimer: false,
                  id: generateId(index)
                })
              })
            }
            sheduleCalendarStore.updateAfterCreateWrite(data.writeData)
            handleCloseModal()
          }
        })
      } else {
        setIsSaved(false)
        alert("Заполните недостающие данные")
      }
    } else {
      sheduleCalendarStore.updateAfterCreateWrite()
      handleCloseModal()
    }
  }

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      handleSave()
    } else {
      handleCloseModal()
    }
  }

  if (store.LoadingOnStartModal) {
    <BaseModal
      onClose={handleCloseModal}
      saveClick={handleCloseModal}
      open={viewStore.openModal}
      fullWidth={true}
      maxWidth={!newPatient ? 'xs' : 'md'}
    >
      <FullPageFallbackProgress />
    </BaseModal>
  }

  return (
    <>
      <BaseModal
        onClose={(event, reason) => handleClose(event, reason)}
        saveClick={handleSave}
        open={viewStore.openModal}
        fullWidth={true}
        maxWidth={!newPatient ? 'xs' : 'md'}
        title={"Карточка записи"}
      >
        {outputWrite()}
      </BaseModal>
      {smsSendModalCreateWriteViewStore.openModal && <SmsModalFromCreateWrite />}
      {sheduleSmsTemplates.openModal && <SheduleSmsTemplates />}
    </>
  );
}));

export default CreateWrite;

