import {gql} from "@apollo/client";

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile($input: UpdateUserProfileInput!) {
  updateUserProfile(input: $input) {
    user {
      email
      firstName
      lastName
      patronymic
      birthday
      sip
      position {
        id
      }
      socialNetworks {
        id
        nickname
        networkType {
        id
        type
        }
        
      }
    }
    ok
    errors
  }
}
`