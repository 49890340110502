
import { makeAutoObservable } from "mobx";

export default class SmsSendModalChangeWriteViewStore {

    state = {
        openModal: false
    }

    get openModal () {
        return this.state.openModal
    }

    setOpenModal(value) {
        this.state.openModal = value
    }

    constructor() {
        makeAutoObservable(this)
    }

}