import React from 'react';
import { observer } from "mobx-react-lite";
import { useInstance } from "react-ioc";
import { Button } from "@mui/material";
import CreateWriteStore from '../store/CreateWrite.data.store';


const InfoWriteCommentTemplates = (observer(() => {
  const store = useInstance(CreateWriteStore)

  const handleAddTemplate = (templateText) => {
    store.setAddTextToComment(templateText)
  }

  return (
    <>
      {store.infoCommentTemplates.length>0 && store.infoCommentTemplates.map((template)=>{
        return(
            <Button key={template.id} size="small" onClick={()=>handleAddTemplate(template.comment)}>
                {template.comment}
            </Button>
        )
      })}
    </>
  );
}));

export default InfoWriteCommentTemplates;

