import { makeAutoObservable, toJS } from "mobx";
import {
    SMS_TEMPLETES,
    SMS_TYPES
} from "../../../queries/modal.queries";
import {
    client
} from "../../../../../_common/http/appoloClient";
import {
    nowSmsSheduleIdentifyer,
    inDaySmsSheduleIdentifyer,
    twoHoursSmsSheduleIdentifyer,
    threeDaysBeforSheduleIdentifyer
} from "../../../../../_common/helpValues/smsConstants";
import {
    smsTemplateConverter
} from "../../../../../_common/helpers/smsTemplatesConverter";
import { isPossibleSend } from "../../../../../_common/helpers/countSmsDateForSheduleSend";
import CreateWriteStore from "./CreateWrite.data.store";
import { inject } from "react-ioc";



export default class SmsSendDataCreateWrite {
    dataStore = inject(this, CreateWriteStore)

    patientData = {
        isNew: false,
        name: "",
        surname: "",
        patronymic: "",
        phones: [],
    }

    sendData = {
        smsTexts: [],
        writeData: null,
        savedCastomSend: false,
        phone: "",
    }

    sendActivate = {
        activateFindTemplates: false
    }

    reset() {
        this.patientData = {
            isNew: false,
            name: "",
            surname: "",
            patronymic: "",
            phones: [],
        }
    
        this.sendData = {
            smsTexts: [],
            writeData: null,
            savedCastomSend: false,
            phone: "",
        }
    
        this.sendActivate = {
            activateFindTemplates: false
        }
    }

    get smsTexts () {
        return this.sendData.smsTexts
    }

    setSmsTexts (smsTexts) {
        this.sendData.smsTexts = smsTexts
    }

    get patient () {
        return this.patientData
    }

    setPhone (phoneId) {
        const phones = this.patientData.phones.filter((phone)=>phone.id===phoneId)
        this.sendData.phone = phones[0]
    }

    setPhones (phones) {
        this.patientData.phones = phones
    }

    setPatient ({isNew, surname, name, patronymic, phones}) {
        let isPhoeInPhones = false
        for (let i = 0; i<phones.length; i++){
            if (phones[i].number===this.sendData.phone.number){
                isPhoeInPhones = true
                break
            }
        }
        const ruPhones = []
        phones?.forEach((phone)=>{
            if (phone?.isRus){
                ruPhones.push(phone)
            }
            ruPhones.push(phone)
        })
        this.setPhones(ruPhones)
        this.patientData.isNew = isNew
        this.patientData.surname = surname
        this.patientData.name = name
        this.patientData.patronymic = patronymic
        if (ruPhones.length>0 && (isPhoeInPhones===false || this.sendData.phone==="")) {
            this.sendData.phone = ruPhones[0]
        }
    }

    get phone () {
        return this.sendData.phone
    }

    get savedCastomSend() {
        return this.sendData.savedCastomSend
    }

    get smsActivateAndText() {
        let sms = this.sendData.smsTexts.filter((sms)=> sms.permission && isPossibleSend(this.dataStore.date, this.dataStore.timeStart, sms.name))
        return {
            phone: this.sendData.phone,
            sms: sms
        }
    }

    activateSavedCastomSend() {
        this.sendData.savedCastomSend = true
    }

    get writeData() {
        return this.sendData.writeData
    }

    setWriteData(data) {
        this.sendData.writeData = data
    }

    get activateFindTemplates() {
        return this.sendActivate.activateFindTemplates
    }

    setActivateFindTemplates(value) {
        this.sendActivate.activateFindTemplates = value
    }

    //get/set nowSms

    get nowSms() {
        return this.sendData.nowSms
    }

    addNowSms() {
        this.sendData.nowSms.push("")
    }

    deleteNowSms(index) {
        if (this.sendData.nowSms.length > 1) {
            this.sendData.nowSms.splice(index, 1)
        }
    }

    changeNowSms(index, value) {
        this.sendData.nowSms[index] = value
    }

    //get/set inDaySms

    get inDaySms() {
        return this.sendData.inDaySms
    }

    addInDaySms() {
        this.sendData.inDaySms.push("")
    }

    deleteInDaySms(index) {
        if (this.sendData.inDaySms.length > 1) {
            this.sendData.inDaySms.splice(index, 1)
        }
    }

    changeInDaySms(index, value) {
        this.sendData.inDaySms[index] = value
    }

    //get/set twoHoursSms

    get twoHoursSms() {
        return this.sendData.twoHoursSms
    }

    addTwoHoursSms() {
        this.sendData.twoHoursSms.push("")
    }

    deleteTwoHoursSms(index) {
        if (this.sendData.twoHoursSms.length > 1) {
            this.sendData.twoHoursSms.splice(index, 1)
        }
    }

    changeTwoHoursSms(index, value) {
        this.sendData.twoHoursSms[index] = value
    }


    //get smsTemplates

    async getSmsTemplates(writeData) {
        this.setWriteData(writeData)
        await client.query({
            query: SMS_TEMPLETES,
        }).then((data) => {
            let now = "",
                threeDays = "",
                inDay = "",
                twoHours = "",
                isAllFound = false;
            for (let i = 0; i < data.data.allSmsTemplates.length; i++) {
                if (data.data.allSmsTemplates[i].identifier === nowSmsSheduleIdentifyer) {
                    now = smsTemplateConverter(data.data.allSmsTemplates[i].text, writeData)
                }
                if (data.data.allSmsTemplates[i].identifier === threeDaysBeforSheduleIdentifyer) {
                    threeDays = smsTemplateConverter(data.data.allSmsTemplates[i].text, writeData)
                }
                if (data.data.allSmsTemplates[i].identifier === inDaySmsSheduleIdentifyer) {
                    inDay = smsTemplateConverter(data.data.allSmsTemplates[i].text, writeData)
                }
                if (data.data.allSmsTemplates[i].identifier === twoHoursSmsSheduleIdentifyer) {
                    twoHours = smsTemplateConverter(data.data.allSmsTemplates[i].text, writeData)
                }
                if (now.length > 0 && inDay.length > 0 && twoHours.length > 0 && threeDays.length > 0) {
                    isAllFound = true
                }
                if (isAllFound) {
                    break
                }
            }
            this.sendData.smsTexts.forEach((sms)=>{
                if (sms.text[0]){
                    if (sms?.name === "сейчас"){
                        sms.text[0] = now
                    }else if (sms?.name === "за 2 часа до записи") {
                        sms.text[0] = twoHours
                    }else if (sms?.name === "за день до записи") {
                        sms.text[0] = inDay
                    }else if (sms?.name === "за 3 дня до записи") {
                        sms.text[0] = threeDays
                    }
                }
            })
        })
    }

    async getSmsTypes () {
        await client.query({
            query: SMS_TYPES,
        }).then((data) => {
            const smsTypes = data?.data?.smsTypes?.map((type)=>{return({...type, permission: false, text: [""]})})
            this.setSmsTexts(smsTypes)
        })
    }

    switchSmsPermission(index) {
        this.sendData.smsTexts[index].permission = !this.sendData.smsTexts[index].permission
    }

    changeSmsText(indexGroup, indexText, text) {
        this.sendData.smsTexts[indexGroup].text[indexText] = text
    }

    sddSms(index) {
        this.sendData.smsTexts[index].text.push("")
    }

    deleteSms(gropIndex, textIndex) {
        this.sendData.smsTexts[gropIndex].text.splice(textIndex, 1)
    }

    get smsQtyForSend() {
        const switchType = (type) => {
            switch (type) {
                case "за 2 часа до записи":
                    return "2ч"
                case "за день до записи":
                    return "д"
                case "за 3 дня до записи":
                    return "3д"
                case "сейчас":
                    return "с"
                default:
                    return "Нет типа"
            }
        }
        const smsQtyArr = this.sendData.smsTexts?.map((type)=>{
            const smsQty = type?.text?.length>0 && type?.text?.reduce((acc, text) => {
                if (text?.length>0 && type?.permission){
                    return  acc+1
                }else return  acc
            }, 0)
            return {
                type: type?.name,
                qty: smsQty || null
            }
        })
        const smsQtyStr = smsQtyArr?.length>0 && smsQtyArr?.reduce((acc, sms) => acc+(sms.qty ? ` ${switchType(sms.type)}-${sms.qty}` : ""),"")
        const smsQtyLongNameStr = smsQtyArr?.length>0 && smsQtyArr?.reduce((acc, sms) => acc+(sms.qty ? ` ${sms.type} - ${sms.qty},` : ""),"")
        return {shortName: smsQtyStr, longName: smsQtyLongNameStr}
    }

    constructor() {
        makeAutoObservable(this)
    }

}