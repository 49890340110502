import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { theme } from "../../_common/theme/theme";
import ButtonMain from "../../_components/UI/Buttons/ButtonMain";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTE } from "../../_common/router/routes"
import fluffCat from "../../_common/assets/img/fluffCat/cute-cat-handdrawn-07.png"
import HomeIcon from '@mui/icons-material/Home';

const FourHundredFour = () => {

    useEffect(() => {
        document.title = '404'
    }, [])

    const navigate = useNavigate()

    const handleClickHome = () => {
        navigate(HOME_ROUTE)
    }

    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", width: "100%", p: {xs: 2, sm: 4}}}>
            <Box sx={{ display: "flex", flexDirection: "column", maxWidth: {xs: "95%", md: "1200px"}, maxHeight: "100%" }}>
                <Typography variant="h1" sx={{textAlign: {xs: "center", sm: "left"}}}>
                    404
                </Typography>
                <Typography variant="h3" sx={{ fontSize: { xs: 20, sm: 34 }, textAlign: {xs: "center", sm: "left"} }}>
                    Такая Страница не найдена. Зато тут спит Пушок
                </Typography>
                <Box>
                    <ButtonMain
                        sx={{ mt: 2, width: {xs: "100%", sm: "auto"} }}
                        icon={<HomeIcon />}
                        title="На главную"
                        onClick={handleClickHome}
                    />
                </Box>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    flex: 1,
                    "& img": {
                        maxWidth: {xs: 400, sm: 400, md: 1000},
                        minWidth: 100,
                        maxHeight: 600,
                        objectFit: "cover"
                    }
                }}>
                    <img src={fluffCat} alt="Пушок отошел" />
                </Box>
            </Box>
        </Box>

    );
};

export default FourHundredFour;
