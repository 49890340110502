import React, { memo, useMemo } from 'react';
import ModalChat from "./ModalChat"
import InboxMessages from "./InboxMessages"
import Grid from "@mui/material/Grid"
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { useInstance } from "react-ioc"
import StoreChat from "../stores/storeChat"
import { observer } from "mobx-react-lite"
import { SurnameAndInitialsString } from "../../../_common/helpers/nameGenerationString"

const Layout = styled(Box)(({theme}) => ({
  padding: "10px",
}));

const InboxMessagesLayout = styled(Box)(({theme}) => ({
  boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
  marginBottom: "20px",
  maxHeight: "300px",
  overflow: "auto"
}));

const ButtonSendLayout = styled(Box)(({theme}) => ({
  boxShadow: " 0px -1px 0px rgba(0, 0, 0, 0.12)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));


const ButtonSend = styled(Button)(({theme}) => ({
  width: "100%"
}));


const SeveralMessages = observer((props) => {

  const storeChat = useInstance(StoreChat)

  const getDaySpecialists = useMemo(
    () => storeChat.allUsers?.slice().sort((a, b) => {
      if (a.specialist.lastName > b.specialist.lastName) return 1
      else if (a.specialist.lastName < b.specialist.lastName) return -1
      else return 0
    }).map((specialist, index) => {

        return (
          <MenuItem
            key={index}
            value={specialist.specialist.id}
          >
            {SurnameAndInitialsString(specialist.specialist.lastName, specialist.specialist.firstName, specialist.specialist.patronymic)}
          </MenuItem>
        )
      }
    ),
    [storeChat.allUsers]
  );


  const getShortMessagesButtons = useMemo(
    () => storeChat.messageShortButtons?.slice().sort((a, b) => {
      if (a.text > b.text) return 1
      else if (a.text < b.text) return -1
      else return 0
    }).map((el) => {

      return (

        <Button key={el.id} onClick={() => {
          storeChat.setButtonText(el.text)
        }}>
          {el.text}
        </Button>
      )
    }),
    [storeChat.messageShortButtons, storeChat]
  );


  return (
    <ModalChat
      open={storeChat.openModal}
      onClose={() => {
        storeChat.setOpenChatModal(false)
        storeChat.setMessageTextClear()

        storeChat.setMessagesRead()

        localStorage.setItem('chat_sound_play', JSON.stringify(false))

      }}
      saveClick={() => {
        storeChat.setOpenChatModal(false)
        storeChat.setMessageTextClear()

        // console.log("!!!!!!!")
        storeChat.setMessagesRead()
        localStorage.setItem('chat_sound_play', JSON.stringify(false))
      }}
      title={"Чат"}
    >
      <Layout
      >

        <Typography>
          Входящие сообщения
        </Typography>

        <InboxMessagesLayout>
          <InboxMessages />
        </InboxMessagesLayout>

        {
          !!storeChat.allUsers &&
          <Grid item xs={12}>
            <FormControl fullWidth sx={{minWidth: 220, mb: 2}} size="small" focused>
              <InputLabel id="userSelect">Пользователь</InputLabel>
              <Select
                labelId="userSelect"
                id="userSelect"
                defaultValue={storeChat.specialistInfo}
                label="Пользователь"
                onChange={(event) => {
                  storeChat.setSpecialistInfo(event.target.value.toString())
                }}
              >

                {getDaySpecialists}

              </Select>
            </FormControl>
          </Grid>
        }

        <Grid item xs={12}>
          <FormControl fullWidth sx={{minWidth: 244}} size="small" focused>
            <TextField
              label={"Текст сообщения"}
              focused
              size="small"
              fullWidth
              type="text"
              multiline
              rows={4}
              value={storeChat.messageText}
              onChange={(event) => storeChat.setMessageText(event.target.value)}
            />
          </FormControl>

          {getShortMessagesButtons}

        </Grid>
      </Layout>

      <ButtonSendLayout>
        <ButtonSend
          onClick={() => {
            storeChat.sendChatMessage()
            storeChat.setMessageTextClear()
          }}>
          Отправить
        </ButtonSend>
      </ButtonSendLayout>

    </ModalChat>
  );
});

export default memo(SeveralMessages);