import {
    makeAutoObservable,
} from "mobx";
import {
    client
} from "../../../_common/http/appoloClient";
import {
    SEND_SMS
} from "../_mutations/smsSendMutations";

export default class SmsTimerSendStore {

    //smsForSend is an arr where are fields:
    //   write -------- data of write in shedule
    //   patient ------ data of patient
    //   phoneId ------ id of phone number where to send
    //   text --------- text will be sended to a REAL person
    //   outputText --- text what see in modal - it will change text after saving
    //   time --------- time of the timer
    //   show --------- needed for deleting sms after save. Mark in modal if need to remove
    //   startedTimer - boolean (it puts false and when timer starts it becomes true)
    //   id ----------- is generated on frontend!

    state = {
        openModal: false,
        smsForSend: []
    }

    constructor() {
        makeAutoObservable(this)
    }

    get openModal() {
        return this.state.openModal
    }

    setOpenModal(data) {
        this.state.openModal = data
    }

    get smsForSend() {
        return this.state.smsForSend
    }

    //count sms what timers haven't been run out (>0)

    get smsInQueueAmmount() {
        let smsAmmount = 0
        this.state.smsForSend.forEach((sms) => {
            if (sms.time > 0) {
                smsAmmount++
            }
        })
        return smsAmmount
    }
    
    //set all sms arr

    setSmsForSend(data) {
        this.state.smsForSend = data
    }

    //ads one sms

    pushSmsForSend(sms) {
        this.state.smsForSend.push(sms)
    }

    //delete an sms

    deleteSms(id) {
        const index = this.state.smsForSend.findIndex((obj) => obj.id === id);
        if (index !== -1) {
            this.state.smsForSend.splice(index, 1);
        }      
    }

    //change sms timer

    changeSmsTimer (id) {
        const index = this.state.smsForSend.findIndex((obj) => obj.id === id);
        if (index !== -1) {
            this.state.smsForSend[index].time--
        }  
    }

    //mutation send sms to patient

    sendSms(sms) {
        client.mutate({
            mutation: SEND_SMS,
            variables: {
                input: {
                    patient: sms.patient.id,
                    phone: sms.phoneId,
                    text: sms.text,
                },
            },
        }).catch((error) => {
            console.log("error in send sms after timer", error)
            alert("Возникла ошибка при отправке смс")
        })
    }

    //start timer for an sms

    startTimer(sms) {
        const timerInterval = setInterval(() => {
            if (sms.time > 0) {
                this.changeSmsTimer(sms.id)
            } else {
                clearInterval(timerInterval)
                this.sendSms(sms)
                this.deleteSms(sms.id)
            }
        }, 1000);
    }

    //finds sms without started timer and starts it for them

    timerChange() {
        for (const sms of this.state.smsForSend) {
            if (sms.startedTimer === false) {
                const index = this.state.smsForSend.findIndex((obj) => obj.id === sms.id)
                if (index !== -1) {
                    this.state.smsForSend[index].startedTimer = true
                } 
                this.startTimer(sms)
            }
        }
    }

    //set new text. It will be shoun now but used after save

    setSmsNewText(id, text) {
        const index = this.state.smsForSend.findIndex((obj) => obj.id === id);
        if (index !== -1) {
            this.state.smsForSend[index].outputText = text
        }
    }

    //mark sms for delete. It will be deleted after save

    setDeleteSms(id) {
        const index = this.state.smsForSend.findIndex((obj) => obj.id === id);
        if (index !== -1) {
            this.state.smsForSend[index].show = false
        }
    }

    //save changes in modal. Put values of fields what are seen in modal to fields what are used in mutation. Also remove deleted sms

    saveChangesInSms() {
        const filteredSms = this.state.smsForSend.filter(sms => sms.show !== false)
        filteredSms.forEach((sms) => {
            sms.text = sms.outputText
        })
        this.setSmsForSend(filteredSms)
    }

    //use if you dont want to save changes in sms. It puts values what are seen in modal to what they were befor modal changes

    ignoreChangesInSms() {
        this.state.smsForSend.forEach((sms) => {
            sms.show = true
            sms.outputText = sms.text
        })
    }
}