import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, useMediaQuery, useTheme } from "@mui/material"
import { ENDED_CALLS_SUBSCRIPTION, STARTED_CALLS_SUBSCRIPTION } from './_subscriptions/callsSubscription.subscriptions';
import { useInstance } from "react-ioc";
import { observer } from "mobx-react-lite";
import CallsBannerStore from '../Layouts/auth-layout/stores/calls.store';
import { useSubscription } from "@apollo/client";
import CallsBannerItemOutgoing from "./components/CallsBannerItemOutgoing"
import CallsItemIncoming from "./components/CallsItemIncoming"
import MobileCallsBannerItemOutgoing from "./components/MobileBannersItemOutogiong"


const CallsBanner = observer(() => {
    const store = useInstance(CallsBannerStore)

    const {data, loading, error} = useSubscription(STARTED_CALLS_SUBSCRIPTION);

    const {
      data: dataEndedCall,
      loading: loadingEndedCall,
      error: errorEndedCall
    } = useSubscription(ENDED_CALLS_SUBSCRIPTION);


    const [showCallsOutgoing, setShowCallsOutgoing] = useState(false);
    const [showCalls, setShowCalls] = useState(false);

    useEffect(() => {
      if (data?.callStarted && !loading) {
        store.setAllCalls(data.callStarted)
      }
    }, [loading, data, store])

    useEffect(() => {
      if (dataEndedCall?.callEnded && !loadingEndedCall) {
        store.setMutateCallsInfo(dataEndedCall.callEnded)
      }
    }, [loadingEndedCall, dataEndedCall, store])


    if (error) {
    }

    if (errorEndedCall) {
    }

    const setCallDirectionCorrectName = (name) => {
      switch (name) {
        case 'incoming':
          return "Входящий звонок";
        case 'outgoing':
          return "Исходящий звонок";
        default:
          return "";
      }
    }

  const mainTheme = useTheme();
  const mobileBreakpointSm = useMediaQuery(mainTheme.breakpoints.down("800"));

    return (
      <>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
        }}>
          {!!mobileBreakpointSm ? <MobileCallsBannerItemOutgoing
            banners={store.callsArrayOutgoing}
            outgoing
            setShowCalls={setShowCalls}
            showCallsOutgoing={showCallsOutgoing}
            setShowCallsOutgoing={setShowCallsOutgoing}
            setCallDirectionCorrectName={setCallDirectionCorrectName}
          /> : <CallsBannerItemOutgoing
                banners={store.callsArrayOutgoing}
                outgoing
                setShowCalls={setShowCalls}
                showCallsOutgoing={showCallsOutgoing}
                setShowCallsOutgoing={setShowCallsOutgoing}
                setCallDirectionCorrectName={setCallDirectionCorrectName}
              />}
        </Box>

        <Box sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end"
        }}>
          <CallsItemIncoming
            banners={store.callsArrayIncoming}
            showCalls={showCalls}
            setShowCalls={setShowCalls}
            showCallsOutgoing={showCallsOutgoing}
            setShowCallsOutgoing={setShowCallsOutgoing}
            setCallDirectionCorrectName={setCallDirectionCorrectName}
          />
        </Box>
      </>
    );
  });

export default CallsBanner;