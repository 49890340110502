import { action, makeAutoObservable, toJS } from "mobx";
import moment from "moment"
import { client } from "../../../../_common/http/appoloClient"
import { GET_APPOINTMENT_IMAGES_BY_ID } from "../../../../pages/patients/_queries/patient.renewAppointment.queries"
import { inject } from "react-ioc"
import AllPatientCard from "../../../../pages/patients/store/patientCard.store"


export default class DataStoreListDays {

  allPatientCardModal = inject(this, AllPatientCard);


  state = {
    openModal: false,
    data: [],
    loading: false,
    favoriteButtons: [],
    favoriteAppointments: [],
    favoriteCount: 0,
    allAppointmentData: null,

    appointmentsResults: [],
    dateStart: null,
    dateEnd: null,
    rangeFilteredData: [],

    allRangedData: null,
    rangeAppointmentsResults: [],

    previousDayLastEvent: null,

    findEventsByOneDay: false,

    checkedApp: [],


    currentCount: 0,
    showAllEventsAfterAdding: false,

    currentCountRangedResults: 0,
    showAllEventsAfterAddingRangedResults: false,

    checkedRenewed: false,

  }

  constructor() {
    makeAutoObservable(this, {setAddFavoriteButton: action.bound}, { autoBind: true })
  }

  get previousDayLastEvent () {
    return this.state.previousDayLastEvent
  }

  setPreviousDayLastEvent (data) {
    this.state.previousDayLastEvent = data
  }

  countViewedAppointments(){

    if(this.state.appointmentsResults?.length>0){

      this.state.currentCount = this.state.appointmentsResults.length
    }
  }

  isNeedToShowMoreEventsAfterAddingNewEvent(){

    if(this.state.currentCount === 5){

      this.state.showAllEventsAfterAdding = false
    }

    if(this.state.currentCount > 5){
      this.state.showAllEventsAfterAdding = true
    }
  }

  get showAllEventsAfterAdding(){

    return this.state.showAllEventsAfterAdding
  }

  get currentCount(){

    return this.state.currentCount
  }




  countViewedRangedAppointments(){
    if(this.state.rangeAppointmentsResults?.length>0){

      this.state.currentCountRangedResults = this.state.rangeAppointmentsResults.length
    }
  }

  isNeedToShowMoreEventsAfterAddingNewEventRangedResults(){

    if(this.state.currentCountRangedResults === 5){

      this.state.showAllEventsAfterAddingRangedResults = false
    }

    if(this.state.currentCountRangedResults > 5){
      this.state.showAllEventsAfterAddingRangedResults = true
    }
  }

  get showAllEventsAfterAddingRangedResults(){

    return this.state.showAllEventsAfterAddingRangedResults
  }

  get currentCountRangedResults(){

    return this.state.currentCountRangedResults
  }


  setChecked(data){

    this.state.checkedApp.push(data)
    localStorage.setItem('appointmentsList', JSON.stringify([...new Map([...this.state.checkedApp, data].map((m) => [m.id, m])).values()]));
  }

  setCheckedFromLocalStorage(data){

    if (data.length>0){
      const newVals = data
      this.state.checkedApp = [...new Map(newVals.map((m) => [m.id, m])).values()];
    }
  }

  clearCheckedUp(){

    this.state.checkedApp = []
  }

  deleteChecked(appointmentId){


    if (this.state.checkedApp.length > 0) {

      const filteredItems = this.state.checkedApp.filter((el) => {
        return el.appointment.appointmentId !== appointmentId
      })

      this.state.checkedApp = filteredItems
      localStorage.setItem('appointmentsList', JSON.stringify(filteredItems));
    }
  }

  get checkedApp(){

    return this.state.checkedApp
  }


  setAppData(data) {
    this.state.allAppointmentData = data
  }

  get getAppData() {
    return this.state.allAppointmentData
  }

  setRangeData(data) {
    this.state.allRangedData = data

  }

  get getRangedAppData() {
    return this.state.allRangedData
  }

  // get allAppointments() {
  //   return this.state.appointmentsResults
  // }

  setAppItems(data) {
    if (!data.length) {
      this.state.appointmentsResults = []
    }

    this.state.appointmentsResults = [...new Map(this.state.appointmentsResults?.concat(data).map((m) => [m.id, m])).values()];
    this.countViewedAppointments()
    this.isNeedToShowMoreEventsAfterAddingNewEvent()
  }

  setRangeAppItems(data) {

    if (!data.length) {
      this.state.rangeAppointmentsResults = []
    }

    if (data.length) {
      this.state.rangeAppointmentsResults = [...new Map(this.state.rangeAppointmentsResults?.concat(data).map((m) => [m.id, m])).values()];
      this.countViewedRangedAppointments()
      this.isNeedToShowMoreEventsAfterAddingNewEventRangedResults()
    }

  }

  get appointmentsWithNewItems () {
    return this.state.appointmentsResults
  }

  get appointmentsRangeWithNewItems () {
    return this.state.rangeAppointmentsResults
  }

  setDateStart(value) {
    this.state.dateStart = value
  }

  get dateStart() {
    return this.state.dateStart
  }

  get dateEnd() {
    return this.state.dateEnd
  }

  setDataProfile(data) {
    return this.state.data = data
  }

  get openModal() {
    return this.state.openModal
  }
  get days() {
    return this.state.data
  }
  get loading() {
    return this.state.loading;
  }
  get favoriteButtons() {
    return this.state.favoriteButtons;
  }

  get favoriteAppointments() {
    return this.state.favoriteAppointments;
  }

  get favoriteCount() {
    return this.state.favoriteCount;
  }


  async renewAppointmentByAppointmentId(appointmentId, editApp){

    await client.query({
      query: GET_APPOINTMENT_IMAGES_BY_ID,
      variables: {
        id: appointmentId
      },
    }).then((data)=>{


      if (localStorage.getItem('appointmentsList') === undefined || localStorage.getItem('appointmentsList') === null){
        localStorage.setItem('appointmentsList', JSON.stringify([]))
      }



      const updateLocalStorage = JSON.parse(localStorage.getItem('appointmentsList')).map(el => {

          return el.appointment.appointmentId !== data.data.appointment.appointmentId ? el : {...el, appointment:  data.data.appointment}
        });


      localStorage.setItem('appointmentsList', JSON.stringify(toJS(updateLocalStorage)))

      this.state.checkedApp = JSON.parse(localStorage.getItem('appointmentsList'))

      this.allPatientCardModal.setRefetching(true)
      this.state.checkedRenewed = true

    })

  }


  setAddFavoriteButton (appointmentId) {
    this.state.favoriteButtons.push(
      {id: appointmentId, status: false}
    )
  }

  setChangeFavoriteStatus (appointmentId, status) {
    const favoriteButton = this.favoriteButtons.find((el) => el.id === parseInt(appointmentId))
    favoriteButton.status = status
    return status
  }

  setFavoriteAppointment (dayId, appointmentId) {
    this.state.favoriteAppointments.push(
      this.findAppointment(dayId, appointmentId)
    )
    this.state.favoriteCount += 1
  }

  setDeleteFavoriteAppointment(appointmentId) {
    const filterAppointment = this.state.favoriteAppointments.filter((appointment) => appointment.id !== appointmentId)
    this.state.favoriteAppointments.replace(filterAppointment);
    this.state.favoriteCount -= 1
  }

  setClearAllFavorites() {
    this.state.favoriteAppointments.replace([])
    //this.state.favoriteButtons.replace([])
    this.state.favoriteButtons.forEach(element => element.status = false)
    this.state.favoriteCount = 0
  }

  getFavoriteStatus(appointmentId) {

    let button = this.favoriteButtons.find((el) => el.id === appointmentId)

    if (!button) {
      this.setAddFavoriteButton(appointmentId,)
        button = this.favoriteButtons.find((el) => el.id === appointmentId)
    }
    return button.status
  }

  findAppointment(dayId, appointmentId) {
    return this.findDayById(dayId).appointments?.find((appointment) => appointment.id === parseInt(appointmentId)) || null;
  }

  findDayById(dayId) {
    return this.days?.find((day) => day['id'] === parseInt(dayId)) || null;
  }

  compareDate(start, end){

    const startCompare =  moment(start).format('YYYY-MM-DD')
    const endCompare = moment(end).format('YYYY-MM-DD')

    if (startCompare === endCompare) {

      this.state.findEventsByOneDay = true
    }
  }

  setFindEventsByOneDayToInitialState(){

    this.state.findEventsByOneDay = false
  }

  get findEventsByOneDay(){

    return this.state.findEventsByOneDay
  }
}