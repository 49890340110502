import { gql } from "@apollo/client";

export const SEND_CHAT_MESSAGE = gql`
mutation sendMessage($input: ChatMessageInput){
  sendMessage(input: $input)
    { 
      ok 
      errors 
      message {
        text 
        id
        newMessagesCount
        isRead
        isDeleted

        
        fromUser{
          id
          firstName
          lastName
          patronymic
        }
      }
    }
  }
`

export const READ_ALL_CHAT_MESSAGE_AND_SET_INACTIVE = gql`
mutation readAllMessages{
  readAllMessages
  {
    success
    msg
  }
}  
`

export const READ_ONE_CHAT_MESSAGE_AND_SET_INACTIVE = gql`
mutation readMessage($messageId: ID!){
   readMessage(messageId: $messageId){ 
      success
      msg
      message {
      newMessagesCount
       text 
       id
      }
     }
   } 
`

