import { makeAutoObservable } from "mobx";
import { client } from "../../../_common/http/appoloClient"
import { USER_PROFILE } from "../query/userProfile"
import { UPDATE_USER_PROFILE } from "../mutations/mutationsUserProfile"
import { dateToFormat } from "../../../_common/helpers/dateHelpers"
import moment from "moment"
import authStore from "../../../_common/stores/auth.store"

export default class UserProfileStore {

  state = {
    user:[],
    preloader: false,
    loading: true,

    firstName: "",
    lastName: "",
    patronymic: "",
    email: "",
    position: "",
    sip: "",
    birthday: "",
    socialNetworks: "",

    dateOfBirthToView: "",
    social: [],

    socialTypes: [],
    freeSocial: true,
  }

  constructor() {
    this.getUserData()
    makeAutoObservable(this)
  }


  async getUserData(){
    await client.query({
      query: USER_PROFILE,
      variables: {
        email: authStore.user.email
      }
    }).then((data) => {

      if (data) {
        this.state.user = data.data.userProfile
        this.state.loading = data.data.loading

        this.state.socialNetworks = data.data.userProfile.socialNetworks

        this.state.lastName = data.data.userProfile.lastName
        this.state.firstName = data.data.userProfile.firstName
        this.state.patronymic = data.data.userProfile.patronymic

        this.state.birthday = data.data.userProfile.birthday
        this.state.position = !!data.data.userProfile.position ? data.data.userProfile.position?.name : ""
        this.state.sip = data.data.userProfile.sip
        this.state.email = data.data.userProfile.email

        this.setViewBirthDay(data.data.userProfile.birthday)

        if(this.state.socialNetworks > 0 && this.state.socialTypes.length > 0){

          this.state.freeSocial = this.state.socialNetworks === this.state.socialTypes.length ? false : true

        }
      }
    })
  }

  setSocialTypes(data){

    this.state.socialTypes = data
  }

  get socialTypes(){

    return this.state.socialTypes
  }

  get freeSocial(){

    return this.state.freeSocial
  }

  setSocial(data){

    this.state.social = data
  }

  get social(){

    return this.state.social
  }

  setClearSocial(){

    this.state.social = []
  }

  setClearFreeSocial(){

    this.state.freeSocial = []
  }




  async setUpdateUser(social) {


    const birthdayDate = dateToFormat(this.state.birthday)

    await client.mutate({

      mutation: UPDATE_USER_PROFILE,
      variables: {
        "input": {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          patronymic: this.state.patronymic,
          birthday: birthdayDate ? birthdayDate : undefined,
          position: undefined,
          sip: this.state.sip,
          socialNetworks: social,
        }
      },
    }).then((data) => {

      if (data) {

        this.state.socialNetworks = data.data.updateUserProfile.user.socialNetworks
        this.state.lastName = data.data.updateUserProfile.user.lastName
        this.state.firstName = data.data.updateUserProfile.user.firstName
        this.state.patronymic = data.data.updateUserProfile.user.patronymic
        this.state.birthday = data.data.updateUserProfile.user.birthday
        this.state.sip = data.data.updateUserProfile.user.sip

        this.setViewBirthDay(data.data.updateUserProfile.user.birthday)

        if(this.state.socialNetworks > 0 && this.state.socialTypes.length > 0){

          this.state.freeSocial = this.state.socialNetworks === this.state.socialTypes.length ? false : true

        }
      }
    })
  }



  get socialNetworks(){

    return this.state.socialNetworks
  }

  get lastName(){

    return this.state.lastName
  }

  setLastName(data){

    this.state.lastName = data
  }

  get firstName(){

    return this.state.firstName
  }

  setFirstName(data){

    this.state.firstName = data
  }


  get patronymic(){

    return this.state.patronymic
  }

  setPatronymic(data){

    this.state.patronymic = data
  }

  get user(){

    return this.state.user
  }

  setBirthday(data){

    //07/01/2023
    this.state.birthday = data
  }

  get birthday(){

    return this.state.birthday
  }


  get loading(){

    return this.state.loading
  }

  get position(){

    return this.state.position
  }

  setSip(data){

    this.state.sip = data
  }

  get sip() {

    return this.state.sip
  }

  get email(){
    return this.state.email
  }

  setViewBirthDay(date){
    if (date){
      this.state.dateOfBirthToView = moment(date).format("DD.MM.YYYY")
    }
  }

  get dateOfBirthToView(){

    return this.state.dateOfBirthToView
  }
}