import { makeAutoObservable } from "mobx";


export default class AdvancePaymentStore {
  state = {
    isLoaded: false,
    error: false,
    email: null,
    patientName: null,
    paymentAmount: null,
    phone: null,
    paymentCode: null
  }

  get isLoaded() {
    return this.state.isLoaded
  }

  setIsLoaded(value) {
    this.state.isLoaded = value
  }

  get error() {
    return this.state.error
  }

  setError(value) {
    this.state.error = value
  }

  get email() {
    return this.state.email
  }
  setEmail(email) {
    this.state.email = email
  }

  get patientName() {
    return this.state.patientName
  }

  setPatientName(patientName) {
    this.state.patientName = patientName
  }

  get paymentAmount() {
    return this.state.paymentAmount
  }

  setPaymentAmount(paymentAmount) {
    return this.state.paymentAmount = paymentAmount
  }

  get phone() {
    return this.state.phone
  }

  setPhone(phone) {
    this.state.phone = phone
  }

  setPaymentCode(code) {
    this.state.paymentCode = code
  }


  async getPaymentData(dataKey) {
    this.setPaymentCode(dataKey)
    let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/patient_data/${dataKey}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json'
      },
    })
    if (response.ok) {
      let paymentData = await response.json();
      this.setEmail(paymentData.email)
      this.setPatientName(paymentData.patientName)
      this.setPaymentAmount(paymentData.paymentAmount)
      this.setPhone(paymentData.phone)
      this.setIsLoaded(true)
    } else {
      this.setError(true)
    }
  }

  async sendToPay() {
    let sendData = {
      "agree": true
    }
    let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/patient_data/${this.state.paymentCode}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(sendData)
    })
    if (response.ok) {
      let paymentData = await response.json();
      window.location.href = paymentData.link;
    } else {
      this.setError(true)
    }
  }

  constructor() {
    makeAutoObservable(this)
  }
}