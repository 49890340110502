import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import ChangeWriteViewStore from '../store/ChangeWriteView.view.store';
import { useInstance } from "react-ioc";
import { RECORD } from '../../../queries/modal.queries';
import { UPDATE_SHEDULE } from '../../../mutations/modal.mutations';
import { useMutation, useQuery } from "@apollo/client";

export default function DeleteDelayDialog() {
    const viewStore = useInstance(ChangeWriteViewStore)

    const { data } = useQuery(RECORD, {
        variables: {
            scheduleId: viewStore.infoFromShedule,
        }
    })

    const [addData, { data: deleteData, errors: deleteError }] = useMutation(UPDATE_SHEDULE);

    const handleClose = () => {
        viewStore.setOpenDeleteDelayModal(false)
    };

    const handleDeleteDelay = () => {
        addData({
            variables: {
                "input": {
                    dayTableId: data.scheduleInfo.dayTable.id,
                    scheduleEntryId: data.scheduleInfo.id,
                    statusId: data.scheduleInfo.status.id,
                    specialistId: data.scheduleInfo.specialist.id,
                    date: data.scheduleInfo.date,
                    timeStart: data.scheduleInfo.timeStart,
                    timeEnd: data.scheduleInfo.timeEnd,
                    delay: 0
                }
            }
        }).catch((error) => {
            if (error) alert(error)
        }).then(() => {
            handleClose()
        })

    }

    return (
        <Dialog
            open={viewStore.openDeleteDelayModal}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {`Удалить опоздание на ${data?.scheduleInfo.delay} мин?`}
            </DialogTitle>
            <DialogActions>
                <Button onClick={handleClose}>Отмена</Button>
                <Button onClick={handleDeleteDelay} autoFocus>
                    Удалить опоздание
                </Button>
            </DialogActions>
        </Dialog>
    );
}