import { observer } from "mobx-react-lite"
import { useInstance } from "react-ioc"
import { useMediaQuery, useTheme } from "@mui/material"
import { useEffect, useMemo, useRef, useState } from "react"

import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import { useQuery } from "@apollo/client";

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"

import { theme } from "../../../../_common/theme/theme"
import { MEDICAL_PRICE_LIST } from "../../../price/_queries/price_ex.queries"
import { FullPageFallbackProgress } from "../../../../_components/UI/preloaders/FullPageFallbackProgress"
import classes from "../../../../_common/assets/styles/pricePageAlpine.module.css"
import PrePaymentDataStore from "../../stores/prePayment.data.store"


const StyledBox = styled(Box)(({theme}) => ({

  "&.ag-theme-alpine": {
    borderTop: "none",
    backgroundColor: "black",
    "--ag-borders": "none",
  },

  "&.ag-theme-alpine .ag-row.ag-row-odd ": {
    backgroundColor: `${theme.palette.primary.light3}`,
  },

  "& .ag-header-row:not(:first-child) .ag-header-cell": {
    borderTop: "none",
    // minHeight: "110px !important"
  },

  "& .ag-header-group-cell-label": {
    minHeight: "50px !important",
    fontWeight: "500 !important",
  },

  "& .ag-header-cell-label": {
    fontWeight: "500 !important",
  },

  "&.ag-theme-alpine .ag-header": {
    backgroundColor: `${theme.palette.primary.light3}`,
    color: "#686868",
    textTransform: "uppercase",
  },

  "& .ag-header .ag-pivot-off": {
    minHeight: "110px !important"
  },
  "& .ag-row-odd .ag-row-no-focus .ag-row ag-row-level-1 .ag-row-position-absolute": {
    height: "300px"
  },
  "& .ag-cell ": {
    padding: "0",
    minHeight: "110px !important"
  },

  "& .ag-cell-wrapper": {
    minHeight: "60px !important"
  },


  "&.ag-theme-alpine .ag-header-group-cell": {
    fontSize: "14px",
    transform: "translate(30%)",
    borderLeft: "none"
  },
  "&.ag-theme-alpine .ag-header-cell": {
    fontSize: "11px",
    border: "none",
    borderRight: "none",
    padding: "0"
  }
}));

const SearchInPriceLayout = styled(Box)(({theme}) => ({
  "& input": {
    padding: "8px",
    borderRadius: "8px",
    border: "2px solid #5F66BF",
    fontSize: "16px",
    width: "230px",
  },
}));


const MedicalEstimate = observer((props) => {

  const estimateStore = useInstance(PrePaymentDataStore);

  const mainTheme = useTheme();
  const smallBreakpoint = useMediaQuery(mainTheme.breakpoints.down(1501));

  const {data: priceData, loading } = useQuery(MEDICAL_PRICE_LIST, {
    variables: {
      typeServices: "MEDICAL",
      priceId: 5,
      centerId: estimateStore.center,
      doctorId: estimateStore.specialist
    }
  });

  const [priceForTable, setPriceForTable] = useState([]);

  useEffect(() => {
    setPriceForTable([])

    if (priceData?.priceListNew && estimateStore.specialist !== "") {
      priceData?.priceListNew.forEach((newPrice) => {
        if (newPrice.isActive) {
          setPriceForTable(priceForTable => [...priceForTable, newPrice])
        }
      })
    }
  }, [priceData, loading, estimateStore.specialist, estimateStore.center,])

  const gridRef = useRef();
  const containerStyle = useMemo(() => ({width: '100%', height: '100%', background: "white", overflow: "scroll"}), []);

  const columnDefs = [

    // {
    //   headerName: '',
    //   width: 35,
    //   height: 20,
    //   left: -15,
    //   field: '0',
    //   rowGroup: true,
    //   rowGroupIndex: 0,
    //   initialPinned: 'left',
    // },
    {
      headerName: '',
      width: 35,
      height: 20,
      left: -15,
      field: '0',
      rowGroup: true,
      rowGroupIndex: 0,
      autoHeight: true,
      initialPinned: 'left',
      cellRenderer: (params) => {
        return (
          <>{!params?.data?.service?.isGroup && params?.data?.service?.color !== null &&
            <Box
              sx={{
                border: "1px solid",
                borderColor: "primary.light",
                background: params?.data?.service?.color !== null ? `#${params?.data?.service?.color}` : undefined,
                width: "15px",
                height: "15px",
                // marginTop: "9px",
                borderRadius: "50%"
              }}
            />
          }
          </>
        )
      }
    },
    {
      headerName: 'Дополнительные манипуляции во время проведения',
      children: [
        {
          headerName: 'Отдельные манипуляции',
          field: 'price1',
          minWidth: 150,
          height: 100,

          cellRenderer: (params) => {

            return (
              <>
                {!params.data?.service?.isGroup &&
                  <Box
                    // sx={{
                    //   color: estimateStore.center?.id === "1" ?
                    //     `${theme.palette.primary.blue}` : estimateStore.center?.id === "2" ?
                    //       `${theme.palette.primary.light}` : estimateStore.center === null ? undefined : undefined
                    // }}
                  >
                    {params.value != null && <Button
                      sx={{textTransform: 'uppercase', mt: 1, mb: 1}}
                      size="small"
                      onClick={() => {

                        estimateStore.setAllDataData({
                          parent: params?.data?.service.parent.name,
                          serviceName: params?.data?.service.name,
                          serviceId: params.data.service.id,
                          value: params.value,
                          idToFindBy: params.data.service.id + params.value + "price1",
                          price: "price1"
                          }, params.data.service.id, params.value, "price1")
                        }
                      }
                    >
                      <Typography
                        sx={{
                          color: estimateStore.center === "1" ?
                            `${theme.palette.primary.blue}` : estimateStore.center === "2" ?
                              `${theme.palette.primary.light}` : estimateStore.center === null ? undefined : undefined
                        }}
                      >{params.value}</Typography>
                    </Button>}
                  </Box>
                }
              </>
            )
          }
        },
        {
          headerName: 'Обработка одного ногтя',
          field: 'price2',
          minWidth: 150,
          left: 50,
          cellRenderer: (params) => {

            return (
              <>
                {!params.data?.service?.isGroup &&
                  <Box
                    // sx={{
                    //   color: estimateStore.center === "1" ?
                    //     `${theme.palette.primary.blue}` : estimateStore.center === "2" ?
                    //       `${theme.palette.primary.light}` : estimateStore.center === null ? undefined : undefined
                    // }}
                  >
                    {params.value != null && <Button
                      sx={{textTransform: 'uppercase', mt: 1, mb: 1}}
                      size="small"
                      onClick={() => {

                        estimateStore.setAllDataData({
                          parent: params?.data?.service.parent.name,
                          serviceName: params?.data?.service.name,
                          serviceId: params.data.service.id,
                          value: params.value,
                          idToFindBy: params.data.service.id + params.value + "price2",
                          price: "price2",
                          }, params.data.service.id, params.value, "price2")
                        }
                      }
                    >
                      <Typography
                        sx={{
                          color: estimateStore.center === "1" ?
                            `${theme.palette.primary.blue}` : estimateStore.center === "2" ?
                              `${theme.palette.primary.light}` : estimateStore.center === null ? undefined : undefined
                        }}
                      >{params.value}</Typography>
                    </Button>}
                  </Box>
                }
              </>
            )
          }
        },
        {
          headerName: 'Гиг. аппаратного педикюра',
          field: 'price3',
          minWidth: 150,
          left: 50,
          cellRenderer: (params) => {

            return (
              <>
                {!params.data?.service?.isGroup &&
                  <Box
                    // sx={{
                    //   color: estimateStore.center === "1" ?
                    //     `${theme.palette.primary.blue}` : estimateStore.center === "2" ?
                    //       `${theme.palette.primary.light}` : estimateStore.center === null ? undefined : undefined
                    // }}
                  >
                    {params.value != null && <Button
                      sx={{textTransform: 'uppercase', mt: 1, mb: 1}}
                      size="small"
                      onClick={() => {

                        estimateStore.setAllDataData({
                          parent: params?.data?.service.parent.name,
                          serviceName: params?.data?.service.name,
                          serviceId: params.data.service.id,
                          value: params.value,
                          idToFindBy: params.data.service.id + params.value + "price3",
                          price: "price3"
                          }, params.data.service.id, params.value, "price3")
                        }
                      }
                    >
                      <Typography
                        sx={{
                          color: estimateStore.center === "1" ?
                            `${theme.palette.primary.blue}` : estimateStore.center === "2" ?
                              `${theme.palette.primary.light}` : estimateStore.center === null ? undefined : undefined
                        }}
                      >{params.value}</Typography>
                    </Button>}
                  </Box>
                }
              </>
            )
          }
        },
        {
          headerName: 'Гиг. обработки ногтей',
          field: 'price4',
          minWidth: 150,
          left: 50,
          cellRenderer: (params) => {

            return (
              <>
                {!params.data?.service?.isGroup &&
                  <Box
                    // sx={{
                    //   color: estimateStore.center === "1" ?
                    //     `${theme.palette.primary.blue}` : estimateStore.center === "2" ?
                    //       `${theme.palette.primary.light}` : estimateStore.center === null ? undefined : undefined
                    // }}
                  >
                    {params.value != null && <Button
                      sx={{textTransform: 'uppercase', mt: 1, mb: 1}}
                      size="small"
                      onClick={() => {

                        estimateStore.setAllDataData({
                          parent: params?.data?.service.parent.name,
                          serviceName: params?.data?.service.name,
                          serviceId: params.data.service.id,
                          value: params.value,
                          idToFindBy: params.data.service.id + params.value + "price4",
                          price: "price4",
                          }, params.data.service.id, params.value, "price4")
                        }
                      }
                    >
                      <Typography
                        sx={{
                          color: estimateStore.center === "1" ?
                            `${theme.palette.primary.blue}` : estimateStore.center === "2" ?
                              `${theme.palette.primary.light}` : estimateStore.center === null ? undefined : undefined
                        }}
                      >{params.value}</Typography>
                    </Button>}
                  </Box>
                }
              </>
            )
          }
        },
        {
          headerName: 'Гиг. Обработки подошвенной поверхности стоп',
          field: 'price5',
          minWidth: 150,
          left: 50,
          cellRendererParams: {
            suppressMenu: false
          },
          cellRenderer: (params) => {

            return (
              <>
                {!params.data?.service?.isGroup &&
                  <Box
                    // sx={{
                    //   color: estimateStore.center === "1" ?
                    //     `${theme.palette.primary.blue}` : estimateStore.center === "2" ?
                    //       `${theme.palette.primary.light}` : estimateStore.center === null ? undefined : undefined
                    // }}
                  >
                    {params.value != null && <Button
                      sx={{textTransform: 'uppercase', mt: 1, mb: 1}}
                      size="small"
                      onClick={() => {

                        estimateStore.setAllDataData({
                          parent: params?.data?.service.parent.name,
                          serviceName: params?.data?.service.name,
                          serviceId: params.data.service.id,
                          value: params.value,
                          idToFindBy: params.data.service.id + params.value + "price5",
                          price: "price5",
                         }, params.data.service.id, params.value, "price5")
                        }
                      }
                    >
                      <Typography
                        sx={{
                          color: estimateStore.center === "1" ?
                            `${theme.palette.primary.blue}` : estimateStore.center === "2" ?
                              `${theme.palette.primary.light}` : estimateStore.center === null ? undefined : undefined
                        }}
                      >{params.value}</Typography>
                    </Button>}
                  </Box>
                }
              </>
            )
          }
        },
      ]
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      // resizable: true,
      // minWidth: 250,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: 'Наименование услуги',
      left: 100,
      minWidth: smallBreakpoint ? 200 : 400,
      cellStyle: {'whiteSpace': 'normal'},
      autoHeight: true, // <- Yay!
      sort: 'desc',
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {


        if (nodeA.data.service.isGroup === nodeB.data.service.isGroup) {
          if (nodeA.data.service.order === nodeB.data.service.order ) {
            return 0;
          }
          if (nodeA.data.service.order < nodeB.data.service.order){
            return 1
          }
          if (nodeA.data.service.order > nodeB.data.service.order){
            return -1
          }
        }

        if (nodeA.data.service.isGroup === true || nodeB.data.service.isGroup === true ) {


          if(nodeA.data.service.isGroup > nodeB.data.service.isGroup) {
            return -1
          }

          if(nodeA.data.service.isGroup < nodeB.data.service.isGroup) {
            return 1
          }

          else {
            return 0
          }
        }

        if (nodeA.data.service.isGroup === false && nodeB.data.service.isGroup === false) {

          if(nodeA.data.service.order < nodeB.data.service.order) {
            return 1
          }

          if(nodeA.data.service.order > nodeB.data.service.order) {
            return -1
          }

          else {
            return 0
          }
        }
      },
      cellRendererParams: {
        suppressCount: true,
        suppressMenu: false
      },
    };
  }, [smallBreakpoint]);

  const getRowStyle = params => {

    // if (params.data) {
    //   return {paddingLeft: "20px"}
    // }

    if (params.data?.service?.isGroup) {
      return {background: `${theme.palette.primary.light2}`};
    }
  };


  const getDataPath = (data) => {
    let path = data.path.split('/');
    return path
  };

  const onFilterTextBoxChanged = () => {

    gridRef.current.api.setQuickFilter(
      document.getElementById('filter-text-box').value
    );
  }


  if (loading) {
    return (<FullPageFallbackProgress />)
  }

  return (
    <div style={containerStyle}>
      <div className={classes.agThemeAlpine}>
        <SearchInPriceLayout style={{marginBottom: '5px'}}>
          <input
            type="text"
            id="filter-text-box"
            placeholder="Поиск..."
            onInput={onFilterTextBoxChanged}
          />
        </SearchInPriceLayout>

        {
          priceForTable.length > 0 && priceForTable && !loading &&
          <StyledBox className="ag-theme-alpine" sx={{height: 600, width: '1500px', background: "white"}}>
            <AgGridReact
              ref={gridRef}
              rowData={
                priceForTable.length > 0 && priceForTable
                // storePriceData.medicalPrice && storePriceData.medicalPrice.filter((el) => el.service.isActive !== false)
              }
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              autoGroupColumnDef={autoGroupColumnDef}
              treeData={true}
              animateRows={true}
              groupDefaultExpanded={-1}
              getDataPath={getDataPath}
              rowSelection={'single'}
              getRowStyle={getRowStyle}
              overlayNoRowsTemplate={"Нет данных для отображения"}
            />
          </StyledBox>
        }

      </div>
    </div>
  );
});
export default MedicalEstimate;