import {makeAutoObservable} from "mobx";

export default class RedirectSheduleTableStore {

  state = {
    startDate: undefined,
    endDate: undefined,
    currentDate: undefined,
    redirectedFromTable: false,
    redirectedFromShedule: false,
    redirectedFromScheduleWrite: false,
  }
  get startDate() {
    return this.state.startDate
  }
  get endDate() {
    return this.state.endDate
  }
  get currentDate() {
    return this.state.currentDate
  }
  get redirectedFromTable() {
    return this.state.redirectedFromTable
  }
  get redirectedFromShedule() {
    return this.state.redirectedFromShedule
  }
  get redirectedFromScheduleWrite() {
    return this.state.redirectedFromScheduleWrite
  }
  setStartDate(data) {
    this.state.startDate = data
  }
  setEndDate(data) {
    this.state.endDate = data
  }
  setCurrentDate(data) {
    this.state.currentDate = data
  }
  setRedirectedFromTable(data) {
    this.state.redirectedFromTable = data
  }
  setRedirectedFromShedule(data) {
    this.state.redirectedFromShedule = data
  }
  setRedirectedFromScheduleWrite(data) {
    this.state.redirectedFromScheduleWrite = data
  }

  setResetRedirect() {
    this.state.startDate = undefined
    this.state.endDate = undefined
    this.state.currentDate = undefined
    this.state.redirectedFromTable = false
    this.state.redirectedFromShedule = false
    this.state.redirectedFromScheduleWrite = false
  }

  constructor() {
    makeAutoObservable(this)
  }
}