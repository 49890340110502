import React from 'react';
import CloseIcon from "@mui/icons-material/Close"
import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"
import IconButton from "@mui/material/IconButton"

const StyledHeaderLayout = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  background: "#ECEDFF",
  alignItems: "center",

  padding: "5px 10px 5px 10px",

  [theme.breakpoints.down("sm")]: {
    // padding: "10px 10px 12px 10px",
  },

}));

const StyledTitle = styled(Box)(({ theme }) => ({

  width: "33px",
  height: "27px",
  fontSize: "18px",
  lineHeight: "27px",
  fontWeight: 500

}));



const ModalHeader = (props) => {

  const {onClose, title} = props

  return (
    <StyledHeaderLayout>
      <StyledTitle>
        {title}
      </StyledTitle>
      <IconButton
        onClick={()=>{

        onClose()
      }}>
        <CloseIcon />
      </IconButton>

    </StyledHeaderLayout>
  );
};

export default ModalHeader;