import { makeAutoObservable } from "mobx";
import {
    client
} from "../../../../../_common/http/appoloClient";
import {
    SEARCH_PATIENT,
    COMMON_INFO_FOR_WRITE,
    INFOCOMMENTS,
    SPECIALIST_BY_DATE_CENTER,
    PATIENT_SOURCES,
    PHONE_TYPES
} from "../../../queries/modal.queries";
import moment from "moment"
import {
    CREATE_SHEDULE,
    CREATE_PATIENT,
    SEND_SMS
} from "../../../mutations/modal.mutations";
import { cleanStringFromSpaceUpperFirstChar } from "../../../../../_common/helpers/stringReplace";

export default class CreateWriteStore {


    //data for write
    authStoreMedicalCenterId = null

    state = {
        LoadingOnStartModal: false,
        specialistError: false,
        centerError: false,
        dateError: false,
        timeStartError: false,
        timeEndError: false,
        patientError: false,
        surnameError: false,
        nameError: false,
    }

    writeData = {
        patient: "",
        specialistId: "",
        centerId: "",
        date: new Date(),
        timeStart: "",
        timeEnd: "",
        comment: "",
        status: "",
        isStudent: false,
    }

    newPatientData = {
        surname: "",
        name: "",
        patronymic: "",
        isPatient: true,
        sourceId: "",
        smsSendPermission: true,
        phones: [{
            id: "",
            number: "",
            foreignCode: "",
            foreignNumber: "",
            type: {
                id: "",
                type: "",
            },
            smsPermission: true,
            comment: "",
            isRu: true,
            numberError: false,
            codeError: false,
            foreignNumberError: false,
        }, ]
    }

    //data for selects, search etc.

    dataForChoose = {
        patients: [],
        loadingPatients: false,
        specialists: [],
        centers: [],
        statuses: [],
        infoCommentTemplates: [],
        newPatientSources: [],
        phoneTypes: []
    }

    //errors get/set
    get specialistError() {
        return this.state.specialistError
    }

    setSpecialistError(value) {
        this.state.specialistError = value
    }

    get centerError() {
        return this.state.centerError
    }

    setCenterError(value) {
        this.state.centerError = value
    }

    get dateError() {
        return this.state.dateError
    }

    setDateError(value) {
        this.state.dateError = value
    }

    get timeStartError() {
        return this.state.timeStartError
    }

    setTimeStartError(value) {
        this.state.timeStartError = value
    }

    get timeEndError() {
        return this.state.timeEndError
    }

    setTimeEndError(value) {
        this.state.timeEndError = value
    }

    get patientError() {
        return this.state.patientError
    }

    setPatientError(value) {
        this.state.patientError = value
    }

    get surnameError() {
        return this.state.surnameError
    }

    setSurnameError(value) {
        this.state.surnameError = value
    }

    get nameError() {
        return this.state.nameError
    }

    setNameError(value) {
        this.state.nameError = value
    }


    //

    get LoadingOnStartModal () {
        return this.state.LoadingOnStartModal
    }

    setAuthStoreMedicalCenterId(id) {
        this.authStoreMedicalCenterId = id
    }

    //new patient phone get/set

    get phones() {
        return this.newPatientData.phones
    }

    deletePhoneFromPhonesByIndex(index) {
        if (this.newPatientData.phones.length > 1) {
            this.newPatientData.phones.splice(index, 1)
        }
    }

    setPhoneNumberByIndex(index, number) {
        this.newPatientData.phones[index].id = number
        this.newPatientData.phones[index].number = number
    }

    setPhoneForeignCodeByIndex(index, code) {
        this.newPatientData.phones[index].foreignCode = code
    }

    setPhoneForeignNumberByIndex(index, number) {
        this.newPatientData.phones[index].foreignNumber = number
    }

    setPhoneTypeByIndex(index, type) {
        this.newPatientData.phones[index].type = type
    }

    setPhoneSwitchIsRuByIndex(index) {
        const previousIsRusValue = this.newPatientData.phones[index].isRu
        if (previousIsRusValue) {
            this.newPatientData.phones[index].foreignNumber = this.newPatientData.phones[index].number.replace("+7", "")
        }else {
            this.newPatientData.phones[index].number = "+7" + this.newPatientData.phones[index].foreignNumber
        }
        this.newPatientData.phones[index].isRu = !previousIsRusValue
    }

    setPhoneSwitchSmsPermissionByIndex(index) {
        this.newPatientData.phones[index].smsPermission = !this.newPatientData.phones[index].smsPermission
    }

    setPhoneCommentByIndex(index, comment) {
        this.newPatientData.phones[index].comment = comment
    }

    addNewPhone() {
        let type = ""
        for (let i = 0; i < this.dataForChoose.phoneTypes.length; i++) {
            if (this.dataForChoose.phoneTypes[i].type === "Личный") {
                type = this.dataForChoose.phoneTypes[i]
            }
        }
        this.newPatientData.phones.push({
                id: "",
                number: "",
                foreignCode: "",
                foreignNumber: "",
                type: type,
                smsPermission: true,
                comment: "",
                isRu: true,
                numberError: false,
                codeError: false,
                foreignNumberError: false,
        }, )
    }

    setPhoneNumberError(index, value) {
        this.newPatientData.phones[index].numberError = value
    }
    setPhoneForeignNumberError(index, value) {
        this.newPatientData.phones[index].foreignNumberError = value
    }
    setPhoneCodeError(index, value) {
        this.newPatientData.phones[index].codeError = value
    }

    //set/get phone types

    get phoneTypes() {
        return this.dataForChoose.phoneTypes
    }

    setPhoneTypes(typesList) {
        this.dataForChoose.phoneTypes = typesList
    }

    async getPhoneTypesList() {
        await client.query({
            query: PHONE_TYPES,
        }).then((data) => {
            this.setPhoneTypes(data.data.phoneTypes)
            for (let i = 0; i < this.dataForChoose.phoneTypes.length; i++) {
                if (this.dataForChoose.phoneTypes[i].type === "Личный") {
                    this.setPhoneTypeByIndex(0, this.dataForChoose.phoneTypes[i])
                }
            }
        })
    }

    //get/set infoCommentTemplates

    get infoCommentTemplates() {
        return this.dataForChoose.infoCommentTemplates
    }

    setInfoCommentTemplates(templatesList) {
        this.dataForChoose.infoCommentTemplates = templatesList
    }

    async getInfoCommentTemplates() {
        await client.query({
            query: INFOCOMMENTS,
        }).then((data) => {
            this.setInfoCommentTemplates(data.data.commentOptions)
        })
    }

    setAddTextToComment(text) {
        const freeSymbolsAmount = 300 - this.writeData.comment.length
        if (freeSymbolsAmount >= text.length) {
            if (this.writeData.comment.length > 0) {
                this.writeData.comment += `, ${text}`
            } else {
                this.writeData.comment = text
            }
        }
    }

    //get/set specialistId

    get specialistId() {
        return this.writeData.specialistId
    }

    setSpecialistId(newSpecialist) {
        this.writeData.specialistId = newSpecialist
    }

    //get/set specialists

    get specialists() {
        return this.dataForChoose.specialists
    }

    setSpecialists(specialistsList) {
        this.dataForChoose.specialists = specialistsList
    }

    async getSpecialists() {
        await client.query({
            query: SPECIALIST_BY_DATE_CENTER,
            variables: {
                centerId: this.writeData.centerId,
                date: moment(this.writeData.date).format("YYYY-MM-DD"),
                isActive: true,
            }
        }).then((data) => {
            this.setSpecialists(data.data.tableBySpecialist)
            const isChousenSpecialistInDay = data.data.tableBySpecialist.some(item => item.id === this.writeData.specialistId)
            if (!isChousenSpecialistInDay){
                this.setSpecialistId("")
            }

        })
    }

    async getSpecialistsFromOpen(specialistFromOpenId, center, date) {
        this.state.LoadingOnStartModal = true
        await client.query({
            query: SPECIALIST_BY_DATE_CENTER,
            variables: {
                centerId: center,
                date: moment(date).format("YYYY-MM-DD"),
                isActive: true,
            }
        }).then((data) => {
            this.setSpecialists(data.data.tableBySpecialist)
            this.setSpecialistId(specialistFromOpenId)
            this.state.LoadingOnStartModal = false
        })
    }

    //get/set date

    get date() {
        return this.writeData.date
    }

    setDate(newDate) {
        if (newDate !== this.writeData.date) {
            this.writeData.date = newDate
            this.getSpecialists()
        } else {
            this.writeData.date = newDate
        }
    }

    setDateAfterOpen(newDate) {
        this.writeData.date = newDate
    }

    //set/get timeStart

    get timeStart() {
        return this.writeData.timeStart
    }

    setTimeStart(newTime) {
        this.writeData.timeStart = newTime
    }

    //set/get timeEnd

    get timeEnd() {
        return this.writeData.timeEnd
    }

    setTimeEnd(newTime) {
        this.writeData.timeEnd = newTime
    }

    //get/set center

    get centerId() {
        return this.writeData.centerId
    }

    setCenterId(newCenterId) {
        if (newCenterId !== this.writeData.centerId) {
            this.writeData.centerId = newCenterId
            this.getSpecialists()
        } else {
            this.writeData.centerId = newCenterId
        }
    }

    setCenterIdInCommon(newCenterId) {
        this.writeData.centerId = newCenterId
    }

    //get/set centers for choose

    get centers() {
        return this.dataForChoose.centers
    }

    setCenters(centersList) {
        this.dataForChoose.centers = centersList
    }

    //patient search

    get patients() {
        return this.dataForChoose.patients
    }

    get loadingPatients() {
        return this.dataForChoose.loadingPatients
    }

    setLoadingPatients(value) {
        this.dataForChoose.loadingPatients = value
    }

    setPatients(patientsList) {
        this.dataForChoose.patients = patientsList
    }

    async searchPatient(searchString) {
        this.setLoadingPatients(true)
        await client.query({
            query: SEARCH_PATIENT,
            variables: {
                q: searchString ? searchString.replace(" ", "") : "",
                isActive: true,
            }
        }).then((data) => {
            this.setPatients(data.data.searchPatient)
            this.setLoadingPatients(false)
        })
    }

    //set/get patient (in usual write)

    get patient() {
        return this.writeData.patient
    }

    setPatient(patient) {
        this.writeData.patient = patient
    }

    //set patient surname, name, patronymic, isPatient, sourceId

    setNewPatientSurname(surname) {
        this.newPatientData.surname = surname
    }

    get surnameNewPatient() {
        return this.newPatientData.surname
    }

    setNewPatientName(name) {
        this.newPatientData.name = name
    }

    get nameNewPatient() {
        return this.newPatientData.name
    }

    setNewPatientPatronymic(patronymic) {
        this.newPatientData.patronymic = patronymic
    }

    get patronymicNewPatient() {
        return this.newPatientData.patronymic
    }

    switchNewPatientIsPatient() {
        this.newPatientData.isPatient = !this.newPatientData.isPatient
    }

    get isPatientNewPatient() {
        return this.newPatientData.isPatient
    }

    switchNewPatientSmsSendPermission() {
        this.newPatientData.smsSendPermission = !this.newPatientData.smsSendPermission
    }

    get smsSendPermission() {
        return this.newPatientData.smsSendPermission
    }

    setSourceId(id) {
        this.newPatientData.sourceId = id
    }

    get sourceIdNewPatient() {
        return this.newPatientData.sourceId
    }

    //set/get sources for new patient

    get newPatientSources() {
        return this.dataForChoose.newPatientSources
    }

    setNewPatientSources(sourcesList) {
        this.dataForChoose.newPatientSources = sourcesList
    }

    async getNewPatientSourcesList() {
        if (this.dataForChoose.newPatientSources.length === 0) {
            await client.query({
                query: PATIENT_SOURCES,
            }).then((data) => {
                this.setNewPatientSources(data.data.sources)
            })
        }
    }

    get newPatientEssentialData() {
        return {
            surname: this.newPatientData.surname,
            name: this.newPatientData.name,
            phones: this.newPatientData.phones
        }
    }

    //set/get status (commonInfo)

    get status() {
        return this.writeData.status
    }

    setStatus(newStatus) {
        this.writeData.status = newStatus
    }

    setDefaultStatus(statusesList) {
        for (let i = 0; i < statusesList.length; i++) {
            if (+statusesList[i].id === 1) {
                this.setStatus(statusesList[i].id)
            }
        }
    }

    //get/set statuses

    get statuses() {
        return this.dataForChoose.statuses
    }

    setStatuses(statusesList) {
        this.dataForChoose.statuses = statusesList
    }

    //set common data from query for dataForChoose

    async getDataForChooseCommonInfo() {
        await client.query({
            query: COMMON_INFO_FOR_WRITE,
        }).then((data) => {
            this.setStatuses(data.data.scheduleStatuses)
            this.setDefaultStatus(data.data.scheduleStatuses)
            this.setCenters(data.data.medicalCenters)
            this.setCenterIdInCommon(this.authStoreMedicalCenterId)
        })
    }

    //set/get student write

    get isStudent() {
        return this.writeData.isStudent
    }

    setIsStudent() {
        this.writeData.isStudent = !this.writeData.isStudent
    }

    //set comment

    setComment(newText) {
        this.writeData.comment = newText
    }

    //set data for sms templates

    setPatientAndPhonesForSms(isNewPatient) {
        if (isNewPatient){
            return(
                {
                    isNew: true,
                    surname: this.newPatientData.surname,
                    name: this.newPatientData.name,
                    patronymic: this.newPatientData.patronymic,
                    phones: this.newPatientData.phones,
                }
            )
        }else{
            return(
                {
                    isNew: false,
                    surname: this.writeData.patient.lastName,
                    name: this.writeData.patient.firstName,
                    patronymic: this.writeData.patient.patronymic,
                    phones: this.writeData.patient.phones,
                }
            )
        }
    }

    setDataForGettingSmsTemplates(isNewPatient) {
        let writeData = {
            specialist: {
                surname: "",
                name: "",
                patronymic: "",
            },
            patient: {
                surname: "",
                name: "",
                patronymic: "",
            },
            date: "",
            time: ""
        }

        let specialist = {}
        for (let i = 0; i < this.dataForChoose.specialists.length; i++) {
            if (this.writeData.specialistId === this.dataForChoose.specialists[i].id) {
                specialist = this.dataForChoose.specialists[i]
            }
        }

        const newPatient = {
            lastName: this.newPatientData.surname,
            firstName: this.newPatientData.name,
            patronymic: this.newPatientData.patronymic
        }
        
        let patient = isNewPatient ? newPatient : this.writeData.patient

        writeData.date = moment(this.writeData.date).format("DD.MM.YYYY")
        writeData.time = this.writeData.timeStart
        writeData.specialist = {
            surname: specialist.lastName,
            name: specialist.firstName,
            patronymic: specialist.patronymic
        }
        writeData.patient = {
            surname: patient?.lastName,
            name: patient?.firstName,
            patronymic: patient?.patronymic
        }
        return writeData
    }

    get comment() {
        return this.writeData.comment
    }


    filterPhonesFromEmpty() {
        let newPhoneSet = this.newPatientData.phones.filter((phone)=>phone.number?.length>0 || phone.foreignNumber?.length>0)
        if (!newPhoneSet?.length){
            let type = ""
            for (let i = 0; i < this.dataForChoose.phoneTypes.length; i++) {
                if (this.dataForChoose.phoneTypes[i].type === "Личный") {
                    type = this.dataForChoose.phoneTypes[i]
                }
            }
            newPhoneSet = [{
                    id: "",
                    number: "",
                    foreignCode: "",
                    foreignNumber: "",
                    type: type,
                    smsPermission: true,
                    comment: "",
                    isRu: true,
                    numberError: false,
                    codeError: false,
                    foreignNumberError: false,
            }, ]
        }
        this.newPatientData.phones = newPhoneSet
    }

    savePermission (isInfo, isNewPatient) {
        let isSpecialist = false, 
            isCenter = false, 
            isDate = false, 
            isTimeStart = false, 
            isTimeEnd = false;

        if (this.writeData.specialistId){
            isSpecialist = true
        }else {
            this.setSpecialistError(true)
        }

        if (this.writeData.centerId){
            isCenter = true
        }else {
            this.setCenterError(true)
        }

        if (this.writeData.date){
            isDate = true
        }else {
            this.setDateError(true)
        }

        if (this.writeData.timeStart){
            isTimeStart = true
        }else {
            this.setTimeStartError(true)
        }

        if (this.writeData.timeEnd){
            isTimeEnd = true
        }else {
            this.setTimeEndError(true)
        }

        const isBaseData = isSpecialist && isCenter && isDate && isTimeStart && isTimeEnd

        if (isInfo){
            return isBaseData ? true : false
        }else if (isNewPatient){
            let isSurname = false,
                isName = false,
                isPhone = false;
            if (this.newPatientData.surname){
                isSurname = true
            }else {
                this.setSurnameError(true)
            }
            if (this.newPatientData.name){
                isName = true
            }else {
                this.setNameError(true)
            }
            this.filterPhonesFromEmpty()
            let phonesSetMistakes = this.newPatientData.phones.map((phone, index)=>{
                let number = phone.number.replace(/[+()_ -]/g, ''),
                    foreignNumber = phone.foreignNumber.replace(/[+()_ -]/g, ''),
                    isSuitableNumber = false
                if (phone.isRu){
                    if (number.length===11){
                        isSuitableNumber = true
                    }else this.setPhoneNumberError(index, true)
                }else{
                    if (foreignNumber.length===10){
                        if (phone.foreignCode?.length>0){
                            isSuitableNumber = true
                        }else this.setPhoneCodeError(index, true)
                    }else{
                        this.setPhoneForeignNumberError(index, true)
                        if (!phone?.foreignCode?.length>0){
                            this.setPhoneCodeError(index, true)
                        }
                    }
                }
                return isSuitableNumber ? true : false
            })
            if (!phonesSetMistakes.includes(false)){
                isPhone = true
            }
            const isPatient = isSurname && isName && isPhone
            return (isBaseData && isPatient) ? true : false
        }else {
            if (this.writeData.patient){
                return isBaseData ? true : false
            }else {
                this.setPatientError(true)
                return false
            }
        }
    }

    findSpecialistTableIdForSend() {
        let table = []

        // let tableId = undefined

        for (let i = 0; i < this.dataForChoose.specialists.length; i++) {
            if (this.dataForChoose.specialists[i].id === this.writeData.specialistId) {
                table = this.dataForChoose.specialists[i].table
                break
            }
        }
        if (table.length === 1) {
            return table[0].id
        } else if (table.length > 1) {
            for (let i = 0; i < table.length; i++) {
                if (table[i].timeStart.slice(0, 5) <= this.writeData.timeStart && this.writeData.timeEnd <= table[i].timeEnd.slice(0, 5)) {
                    return table[i].id
                }
            }
            return table[0].id
        }

    }

    createInfoWrite() {
        return new Promise((resolve, reject)=>{
            client.mutate({
                mutation: CREATE_SHEDULE,
                variables: {
                    input: {
                        dayTableId: this.findSpecialistTableIdForSend(),
                        specialistId: this.writeData.specialistId,
                        centerId: this.writeData.centerId,
                        date: moment(this.writeData.date).format("YYYY-MM-DD"),
                        timeStart: this.writeData.timeStart,
                        timeEnd: this.writeData.timeEnd,
                        description: this.writeData.comment,
                        statusId: this.writeData.status,
                        isInfo: true,
                    },
                },
            }).catch((error) => {
                if (error) alert(error)
                reject(error)
              }).then((data) => {
                if (data){
                    resolve({
                        writeData: data.data.createSchedule.schedule,
                    })
                }
                
            })
        })
        
    }

    createPatient() {
        return new Promise((resolve, reject) => {
            var phonesSet = this.newPatientData.phones.map((phone, index) => {
                if (phone.isRu){
                    return ({
                    typeId: phone.type.id,
                    number: phone.number.replace(/[+() -]/g, ''),
                    comment: phone.comment,
                    isSms: phone.smsPermission,
                    isDefault: index === 0 ? true : false,
                    ruNumber: true
                })
                }else return ({
                    typeId: phone.type.id,
                    number: phone.foreignCode + phone.foreignNumber.replace(/[() -]/g, ''),
                    comment: phone.comment,
                    isSms: phone.smsPermission,
                    isDefault: index === 0 ? true : false,
                    ruNumber: false
                })
                
            })
            client.mutate({
                mutation: CREATE_PATIENT,
                variables: {
                    "input": {
                        firstName: cleanStringFromSpaceUpperFirstChar(this.newPatientData.name),
                        lastName: cleanStringFromSpaceUpperFirstChar(this.newPatientData.surname),
                        patronymic: this.newPatientData.patronymic.length > 0 ? cleanStringFromSpaceUpperFirstChar(this.newPatientData.patronymic) : undefined,
                        source: {id: this.newPatientData.sourceId ? this.newPatientData.sourceId : undefined},
                        isPatient: this.newPatientData.isPatient,
                        sendSms: this.newPatientData.smsSendPermission ? "YES" : "NO",
                        phones: phonesSet,
                    }
                }
            }).catch((error) => {
                console.log(error.message)
                if (error && error.message===`[ErrorDetail(string='Такой номер с типом "Личный" уже существует!', code='invalid')]`){
                    alert(`Такой номер с типом "Личный" уже существует!`)
                } else if (error) alert("Возникла ошибка при создании пациента")
                reject(error)
            }).then((data) => {
                resolve(data)
            })

        });

    }

    createWriteForNewPatient(castomSmsSend) {
        return new Promise((resolve, reject) => {
            let smsForSendNow = []
            this.createPatient().then((data) => {
                client.mutate({
                    mutation: CREATE_SHEDULE,
                    variables: {
                        input: {
                            dayTableId: this.findSpecialistTableIdForSend(),
                            specialistId: this.writeData.specialistId,
                            centerId: this.writeData.centerId,
                            date: moment(this.writeData.date).format("YYYY-MM-DD"),
                            timeStart: this.writeData.timeStart,
                            timeEnd: this.writeData.timeEnd,
                            description: this.writeData.comment,
                            statusId: this.writeData.status,
                            isStudent: this.writeData.isStudent,
                            patientId: data.data.createPatient.patient.id
                        },
                    },
                }).catch((error) => {
                    if (error) alert(error)
                    reject(error)
                  }).then((writeData) => {
                    if (writeData){
                        let phoneId = null
                        for (let i = 0; i < data.data.createPatient.patient.phones.length; i++) {
                            if (data.data.createPatient.patient.phones[i].isDefault) {
                                phoneId = data.data.createPatient.patient.phones[i].id
                            }
                        }

                        castomSmsSend.sms.forEach((sms) => {
                            if (sms.name === "сейчас" && sms.permission) {
                                sms.text.forEach((item) => {
                                    if (item.length > 0) {
                                        smsForSendNow.push({
                                            write: writeData.data.createSchedule.schedule,
                                            patient: data.data.createPatient.patient,
                                            phoneId: phoneId,
                                            text: item,
                                            outputText: item,
                                        })
                                    }
                                })
                            } else if (sms.name === "за 3 дня до записи") {
                                const day = moment(this.writeData.date)
                                sms.text.forEach((item) => {
                                    if (item.length > 0) {
                                        client.mutate({
                                            mutation: SEND_SMS,
                                            variables: {
                                                input: {
                                                    patient: data.data.createPatient.patient.id,
                                                    phone: phoneId,
                                                    text: item,
                                                    dispatch: moment(day.subtract(3, 'days')).format("YYYY-MM-DD") + "T15:00:00",
                                                    scheduleEntry: writeData.data.createSchedule.schedule.id,
                                                    smsType: sms.id,
                                                }
                                            }
                                        })
                                    }
                                })
                            } else if (sms.name === "за день до записи") {
                                const day = moment(this.writeData.date)
                                sms.text.forEach((item) => {
                                    if (item.length > 0) {
                                        client.mutate({
                                            mutation: SEND_SMS,
                                            variables: {
                                                input: {
                                                    patient: data.data.createPatient.patient.id,
                                                    phone: phoneId,
                                                    text: item,
                                                    dispatch: moment(day.subtract(1, 'days')).format("YYYY-MM-DD") + "T15:00:00",
                                                    scheduleEntry: writeData.data.createSchedule.schedule.id,
                                                    smsType: sms.id,
                                                }
                                            }
                                        })
                                    }
                                })
                            } else if (sms.name === "за 2 часа до записи") {
                                const day = moment(this.writeData.date).format("YYYY-MM-DD")
                                let time = +this.writeData.timeStart.slice(0, 2) - 2
                                if (time > 7 && time < 10) {
                                    time = "0" + time + this.writeData.timeStart.slice(2, 5)
                                } else if (time > 10) {
                                    time = "" + time + this.writeData.timeStart.slice(2, 5)
                                } else time = "07:00"
                                sms.text.forEach((item) => {
                                    if (item.length > 0) {
                                        client.mutate({
                                            mutation: SEND_SMS,
                                            variables: {
                                                input: {
                                                    patient: data.data.createPatient.patient.id,
                                                    phone: phoneId,
                                                    text: item,
                                                    dispatch: day + "T" + time,
                                                    scheduleEntry: writeData.data.createSchedule.schedule.id,
                                                    smsType: sms.id,
                                                }
                                            }
                                        })
                                    }
                                })
                            }
                        })
                        resolve({
                            writeData: writeData.data.createSchedule.schedule,
                            smsForSendNow: smsForSendNow
                        })
                    }
                })
            })
        })

    }

    async createNewWrite(castomSmsSend) {
        return new Promise((resolve, reject)=>{
            let smsForSendNow = []
            client.mutate({
                mutation: CREATE_SHEDULE,
                variables: {
                    input: {
                        dayTableId: this.findSpecialistTableIdForSend(),
                        specialistId: this.writeData.specialistId,
                        centerId: this.writeData.centerId,
                        date: moment(this.writeData.date).format("YYYY-MM-DD"),
                        timeStart: this.writeData.timeStart,
                        timeEnd: this.writeData.timeEnd,
                        description: this.writeData.comment,
                        statusId: this.writeData.status,
                        isStudent: this.writeData.isStudent,
                        patientId: this.writeData.patient.id
                    },
                },
            }).catch((error) => {
                if (error) alert(error)
                reject(error)
              }).then((data) => {
                if (data){
                    castomSmsSend.sms.forEach((sms)=>{
                        if (sms.name === "сейчас" && sms.permission){
                            sms.text.forEach((item) => {
                                if (item.length > 0) {
                                    smsForSendNow.push({
                                        write: data.data.createSchedule.schedule,
                                        patient: this.writeData.patient,
                                        phoneId: castomSmsSend.phone?.id,
                                        text: item,
                                        outputText: item,
                                    })
                                }
                            })
                        }else if (sms.name === "за 3 дня до записи") {
                            const day = moment(this.writeData.date)
                            sms.text.forEach((item) => {
                                if (item.length > 0) {
                                    client.mutate({
                                        mutation: SEND_SMS,
                                        variables: {
                                            input:{
                                                patient: this.writeData.patient.id,
                                                phone: castomSmsSend.phone.id,
                                                text: item,
                                                dispatch: moment(day.subtract(3, 'days')).format("YYYY-MM-DD")+"T15:00:00",
                                                scheduleEntry: data.data.createSchedule.schedule.id,
                                                smsType: sms.id,
                                            }
                                        }
                                    })
                                }})
                        }else if (sms.name === "за день до записи") {
                            const day = moment(this.writeData.date)
                            sms.text.forEach((item) => {
                                if (item.length > 0) {
                                    client.mutate({
                                        mutation: SEND_SMS,
                                        variables: {
                                            input:{
                                                patient: this.writeData.patient.id,
                                                phone: castomSmsSend.phone.id,
                                                text: item,
                                                dispatch: moment(day.subtract(1, 'days')).format("YYYY-MM-DD")+"T15:00:00",
                                                scheduleEntry: data.data.createSchedule.schedule.id,
                                                smsType: sms.id,
                                            }
                                        }
                                    })
                                }})
                        }else if (sms.name === "за 2 часа до записи") {
                            const day = moment(this.writeData.date).format("YYYY-MM-DD")
                            let time = +this.writeData.timeStart.slice(0, 2) - 2
                            if (time>7 && time<10){
                                time = "0"+time+this.writeData.timeStart.slice(2, 5)
                            }else if (time > 10){
                                time = ""+time+this.writeData.timeStart.slice(2, 5)
                            }else time = "07:00"
                            sms.text.forEach((item) => {
                                if (item.length > 0) {
                                    client.mutate({
                                        mutation: SEND_SMS,
                                        variables: {
                                            input:{
                                                patient: this.writeData.patient.id,
                                                phone: castomSmsSend.phone.id,
                                                text: item,
                                                dispatch: day+"T"+time,
                                                scheduleEntry: data.data.createSchedule.schedule.id,
                                                smsType: sms.id,
                                            }
                                        }
                                    })
                                }})
                        }
                    })
                    resolve({
                        writeData: data.data.createSchedule.schedule,
                        smsForSendNow: smsForSendNow
                    })
                }
            })
        })
        
    }

    createWrite(isInfo, isNewPatient, castomSmsSend) {
        return new Promise((resolve, reject)=>{
            if (isInfo) {
                this.createInfoWrite().catch(() =>{
                    reject(false)
                }).then((data)=>{
                    resolve(data)
                })
            } else if (isNewPatient) {
                this.createWriteForNewPatient(castomSmsSend).catch(()=>{
                    reject(false)
                }).then((data)=>{
                    resolve(data)
                })
            } else {
                this.createNewWrite(castomSmsSend).catch(()=>{
                    reject(false)
                }).then((data)=>{
                    resolve(data)
                })
            }
        })
        
    }



    constructor() {
        makeAutoObservable(this)
    }

}