import React from 'react';
import {
    Box,
    Typography
} from "@mui/material";
import { FullPageFallbackProgress } from '../UI/preloaders/FullPageFallbackProgress';
import { useQuery } from "@apollo/client";
import { SMS_TEMPLATES } from './query/smsTemplates.query';
import { smsTemplateConverter } from '../../_common/helpers/smsTemplatesConverter';


const SmsTemplates = ({ dataForTextConvert, onChooseTemplate }) => {

    const { data, loading, error } = useQuery(SMS_TEMPLATES)

    if (loading) {
        return (
            <FullPageFallbackProgress />
        )
    }

    function generateSmsText(text) {
        return smsTemplateConverter(text, dataForTextConvert)
    }


    function onTemplateSelect(text) {
        onChooseTemplate && onChooseTemplate(text);
    }
    if (error) {
        return (
            <Box sx={{ width: "100%", textAlign: "center" }}>
                Возникла ошибка
            </Box>
        )

    }

    return (
        <Box sx={{ width: "100%" }}>
            {data && data?.allSmsTemplates?.map((temlate) => {
                return (
                    <Box
                        key={`sms-template-${temlate.id}`}
                        onClick={() => onTemplateSelect(generateSmsText(temlate.text))}
                        sx={{
                            mt: 2, p: 2,
                            border: "1px solid",
                            borderRadius: 2,
                            borderColor: "primary.light2",
                            ":first-of-type": {
                                mt: 0
                            },
                            '&:hover': {
                                bgcolor: 'primary.light2'
                            },
                        }}
                    >
                        <Typography variant="subtitle1">
                            {temlate.identifier}
                        </Typography>
                        <Typography>
                            {generateSmsText(temlate.text)}
                        </Typography>
                    </Box>
                )
            })}
        </Box>
    );
};

export default SmsTemplates;

