import React from 'react';
import { observer } from "mobx-react-lite";
import { useInstance } from "react-ioc";
import {
    FormControl,
    Grid, InputLabel, MenuItem, Select,
    TextField,
    Typography,
    Switch,
    FormHelperText,
} from "@mui/material";
import CreateWriteViewStore from '../store/CreateWriteView.view.store';
import CreateWriteStore from '../store/CreateWrite.data.store';
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import moment from "moment";
import { SurnameAndInitialsString } from '../../../../../_common/helpers/nameGenerationString';
import SmsSendDataCreateWrite from '../store/smsSendData.store';
import { styled } from '@mui/material/styles';
import { sortArrayOfSpecByAlphabetWithStore } from "../../../../../_common/helpers/sortArrayOfSpecByAlphabet"


const CustomTextField = styled(TextField)({
    '& textarea': {
      padding: 16
    },
    '.MuiInputBase-multiline': {
        padding: 0
    }
  });
  

const CommonInfo = (observer(() => {
    const viewStore = useInstance(CreateWriteViewStore)
    const store = useInstance(CreateWriteStore)
    const smsSendDataCreateWrite = useInstance(SmsSendDataCreateWrite)

    const handleChangeSpecialist = (newSpecialist) => {
        store.setSpecialistError(false)
        store.setSpecialistId(newSpecialist)
        smsSendDataCreateWrite.setActivateFindTemplates(true)
    }

    const handleChangeCenter = (newCenter) => {
        store.setCenterError(false)
        store.setCenterId(newCenter)
    }

    const handleChangeDate = (newDate) => {
        store.setDateError(false)
        store.setDate(moment(newDate).format("YYYY-MM-DD"))
        smsSendDataCreateWrite.setActivateFindTemplates(true)
    }

    const handleChangeTimeStart = (newTime) => {
        store.setTimeStartError(false)
        store.setTimeStart(newTime)
        smsSendDataCreateWrite.setActivateFindTemplates(true)
    }

    const handleChangeTimeEnd = (newTime) => {
        store.setTimeEndError(false)
        store.setTimeEnd(newTime)
    }

    const handleChangeComment = (newText) => {
        store.setComment(newText)
    }

    const handleChangeStatus = (newStatus) => {
        store.setStatus(newStatus)
    }

    const handleChangeStudent = () => {
        store.setIsStudent()
    }

    return (
        <Grid container spacing={2} sx={{ p: 1 }}>
            <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                <FormControl fullWidth focused >
                    <InputLabel error={store.specialistError} id="specialist-write-select-label" disabled={store.specialists.length === 0}>Специалист*</InputLabel>
                    <Select
                        error={store.specialistError}
                        color={""}
                        value={store.specialistId || ''}
                        onChange={(e) => handleChangeSpecialist(e.target.value)}
                        labelId="specialist-write-select-label"
                        id="specialist-write-select"
                        label="Специалист"
                        size="small"
                        disabled={store.specialists.length === 0}
                    >
                        {store.specialists.length > 0 && sortArrayOfSpecByAlphabetWithStore(store.specialists).map((specialist) => {
                            return (
                                <MenuItem key={specialist.id} value={specialist.id}>
                                    {SurnameAndInitialsString(specialist.lastName, specialist.firstName, specialist.patronymic)}
                                </MenuItem>
                            )
                        })}
                    </Select>
                    {store.specialists.length === 0 && <FormHelperText>специалисты отсутствуют</FormHelperText>}
                    {(!store.specialistId || store.specialistId === "") && store.specialists.length != 0 && <FormHelperText>выберите специалиста</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ mt: 2 }}>
                <FormControl fullWidth focused >
                    <InputLabel error={store.centerError} id="center-write-select-label">Центр*</InputLabel>
                    <Select
                        error={store.centerError}
                        value={store.centerId && store.centerId}
                        onChange={(e) => handleChangeCenter(e.target.value)}
                        labelId="center-write-select-label"
                        id="center-write-select"
                        label="Центр"
                        size="small"
                    >
                        {store.centers.length > 0 && store.centers.map((center) => {
                            return (
                                <MenuItem key={center.id} value={center.id}>
                                    {center.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6} sm={4} sx={{ mt: 2 }}>
                <FormControl fullWidth focused>
                    <MobileDatePicker
                        label="Дата*"
                        inputFormat="DD.MM.yyyy"
                        value={store.date && store.date}
                        disablePast
                        onChange={(newValue) => handleChangeDate(newValue)}
                        renderInput={(params) => <TextField color={store.dateError && "error"} error={store.dateError} {...params} size="small" focused />}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6} sm={4} sx={{ mt: 2 }}>
                <FormControl fullWidth focused>
                    <TextField
                        error={store.timeStartError}
                        size="small"
                        focused
                        id="timeStart"
                        label="от*"
                        type="time"
                        value={store.timeStart && store.timeStart}
                        onChange={(newValue) => {
                            handleChangeTimeStart(newValue.target.value);
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6} sm={4} sx={{ mt: 2 }}>
                <FormControl fullWidth focused>
                    <TextField
                        error={store.timeEndError}
                        size="small"
                        focused
                        id="timeEnd"
                        label="до*"
                        type="time"
                        value={store.timeEnd && store.timeEnd}
                        onChange={(newValue) => {
                            handleChangeTimeEnd(newValue.target.value);
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <CustomTextField
                    label={viewStore.isInfo === true ? "Комментарий" : "Причина обращения"}
                    value={store.comment && store.comment}
                    size="small"
                    focused
                    fullWidth
                    inputProps={{ maxLength: 300 }}
                    multiline
                    minRows={2}
                    maxRows={4}
                    onChange={(e) => handleChangeComment(e.target.value)}
                />
            </Grid>
            {viewStore.isInfo === false &&
                <>
                    <Grid item xs={6} sx={{ mt: 2 }}>
                        <FormControl fullWidth focused>
                            <InputLabel id="status-write-select-label">Статус записи</InputLabel>
                            <Select
                                value={store.status && store.status}
                                onChange={(e) => handleChangeStatus(e.target.value)}
                                labelId="status-write-select-label"
                                id="status-write-select"
                                label="Статус записи"
                                size="small"
                            >
                                {store.statuses.length > 0 && store.statuses.map((status) => {
                                    return (
                                        <MenuItem key={status.id} value={status.id}>
                                            {status.name}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{ mt: 2 }}>
                        <FormControl fullWidth focused sx={{ pb: 1, display: "flex", flexDirection: "row", alignItems: "end", height: "100%" }}>
                            <label htmlFor="student-write-switch">
                                <Switch size="small" checked={store.isStudent && store.isStudent} id="student-write-switch" onChange={handleChangeStudent} />
                                <Typography sx={{ display: "inline" }} variant={"body2"}>Ученическая запись</Typography>
                            </label>
                        </FormControl>
                    </Grid>
                </>
            }

        </Grid>
    );
}));

export default CommonInfo;

