import moment from "moment";

export const isPossibleSend = (date, time, type) => {
    const currentDateTime = moment();
    let eventDateTime = null
    if (type==="за 3 дня до записи"){
        eventDateTime = moment(date).subtract(3, 'days').format("YYYY-MM-DD")+" 15:00:00"
    }else if (type==="за день до записи"){
        eventDateTime = moment(date).subtract(1, 'days').format("YYYY-MM-DD")+" 15:00:00"
    }else if (type==="за 2 часа до записи"){
        eventDateTime = date+" "+time
    }
    if (eventDateTime && currentDateTime.isAfter(eventDateTime)) {
        return false
      } else {
        return true
      }
      
}