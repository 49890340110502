import {gql} from "@apollo/client";
export const PRICE_LIST = gql`
  query priceList(
    $priceId: ID!
    $centerId: ID!
    $doctorId: ID!
    $typeServices: TypeServices!
  ){
    priceList(
      priceId: $priceId
      centerId: $centerId
      doctorId: $doctorId
      typeServices: $typeServices
    ){
      id
      path
      doctor{
        firstName
        lastName
      }
      price1
      price2
      price3
      price4
      price5
      doctorPercent
    }
  }
`
export const BASE_PRICE_LIST = gql`
  query servicesInPrice(
  $priceId: ID!
  $centerId: ID!
  $doctorId: ID!
  $typeServices: TypeSpecialistServices!
){
  servicesInPrice(
    priceId: $priceId
    centerId: $centerId
    doctorId: $doctorId
    typeServices: $typeServices
  ){
    id
    path
    childrenCount
    prices{
      id
      price1
      price2
      price3
      price4
      price5
      doctorPercent
    }
  }
}
`

//медленный старый запрос прайс-листа

// export const MEDICAL_PRICE_LIST = gql`
// query priceList(
//   $typeServices: String!,
//   $priceId: ID!,
//   $centerId: ID,
//   $doctorId: ID,
// ){
//   priceList(typeServices:$typeServices, priceId:$priceId, centerId:$centerId, doctorId: $doctorId){
//     priceList{startDate created}
//     id
//     price1
//     price2
//     price3
//     price4
//     price5
//     doctorPercent
//     path
//     isActive
//     doctor{
//       id
//       lastName
//       firstName
//     }
//
//     medicalCenter{
//       id
//       name
//     }
//
//     service {
//       isActive
//       isGroup
//       children {
//         id
//       }
//       childrenCount
//       path
//       level
//       treeId
//       id
//       typeServices
//       name
//       code
//       code
//       order
//       color
//       parent {
//         id
//         typeServices
//         name
//         code
//         code
//         order
//         color
//         path
//         childrenCount
//         isActive
//       }
//     }
//   }
// }
// `


//новый запрос прайс-листа
export const GET_PRICE_LIST_OPTIMAL = gql`
query priceListOptimal(
  $typeServices: String!,
  $centerId: ID!,
  $doctorId: ID!,
){
  priceListOptimal(typeServices:$typeServices, centerId:$centerId, doctorId: $doctorId){

    id
    service_Path
    price1
    price2
    price3
    price4
    price5
    doctorPercent
    service_Id
    service_IsGroup
    service_Order
    service_Name
    service_Color

  }
}
`

//ускоренный новый запрос прайс-листа
export const MEDICAL_PRICE_LIST = gql`
query priceListNew(
  $typeServices: String!,
  $priceId: ID!,
  $centerId: ID,
  $doctorId: ID,
){
  priceListNew(typeServices:$typeServices, priceId:$priceId, centerId:$centerId, doctorId: $doctorId){

   priceList{startDate created}
    id
    price1
    price2
    price3
    price4
    price5
    doctorPercent
    path
    isActive

    doctor{
      id
      lastName
      firstName
    }

    medicalCenter{
      id
      name
    }

    service {

      isGroup

      treeId
      id

      name
      order
      color

      children {
        id
      }


      parent {
        id
        name

      }
    }

  }
}
`

//ускоренный старый запрос прайс-листа

// export const MEDICAL_PRICE_LIST = gql`
// query priceList(
//   $typeServices: String!,
//   $priceId: ID!,
//   $centerId: ID,
//   $doctorId: ID,
// ){
//   priceList(typeServices:$typeServices, priceId:$priceId, centerId:$centerId, doctorId: $doctorId){
//
//    priceList{startDate created}
//     id
//     price1
//     price2
//     price3
//     price4
//     price5
//     doctorPercent
//     path
//     isActive
//
//     doctor{
//       id
//       lastName
//       firstName
//     }
//
//     medicalCenter{
//       id
//       name
//     }
//
//     service {
//
//       isGroup
//
//       treeId
//       id
//
//       name
//       order
//       color
//
//       children {
//         id
//       }
//
//
//       parent {
//         id
//         name
//
//       }
//     }
//
//   }
// }
// `

export const POSITIONS = gql`
  query positions{
    positions{
      id
      name
    }
  }
`
export const CENTERS_POSITIONS = gql`
query centersAndPositions{
  medicalCenters{
    id
    name
  }
  allUsers{
    isActive
    firstName
    lastName
    id
    patronymic
    isSpecialist
    position{
      id
      name
    }
  }
  positions{
    id
    name
    users{
      isActive
      id
      firstName
      lastName
      patronymic
      isSpecialist
      position{
        id
        name
      }
    }
  }

}
`
export const PRICE_MODAL_INITIAL_DATA = gql`
query pricesByService($serviceId: ID, $priceListId: ID){
  pricesByService(serviceId: $serviceId, priceListId: $priceListId){
      id
      service{
        id
        name
        typeServices
        parent{
          name
        }
        code
        order
        color
        duration
      }
          doctor{
            id
            isSpecialist
            position{
              id
              name
            }
          }
          medicalCenter{
            id
            name
          }
          price1
          price2
          price3
          price4
          price5
          doctorPercent
    }
}
`

export const SERVICE_BY_ID = gql`
query serviceById($serviceId: ID!){
  serviceById(serviceId: $serviceId){
    name
    color
    order
    duration
    parent{
      name
    }
  }
}
`

export const GET_USERS = gql`
query allUsers{
  allUsers {
    id
    firstName
    lastName
    patronymic
    isActive
    isSpecialist
    position {name}
    
  }
}
`

export const MED_CENTERS = gql`
  query medicalCenterNumber{
    medicalCenters{
      id
      name
    }
  }
`