import React, {useEffect} from "react";
import { Box, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { theme } from "../../_common/theme/theme";

const SuccessPaymentPage = () => {

  useEffect(() => {
    document.title = 'Успешная оплата'
  }, [])

  return (
    <Box sx={{
      width: "100%",
      height: "60vh",
      minHeight: 400,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 1,
      p: 2,
    }}>
      <Typography>
        <CheckCircleIcon sx={{ fontSize: 150, color: theme.palette.secondary.light }} />
      </Typography>
      <Typography variant="h4" sx={{ textAlign: "center" }}>
        Оплата прошла успешно
      </Typography>
      <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
        Вы записаны в медицинский центр Татьяны Красюк.
      </Typography>
      <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
        Адрес: г. Москва, улица Усиевича, дом 18
      </Typography>
    </Box>
  );
};

export default SuccessPaymentPage;
