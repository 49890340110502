import React, { useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { useInstance } from "react-ioc";
import CreateWriteStore from '../store/CreateWrite.data.store';
import CommonInfo from './CommonInfo';
import InfoWriteCommentTemplates from './InfoWriteCommentTemplates';

const InfoWriteBlock = (observer(() => {
  const store = useInstance(CreateWriteStore)

  useEffect(() => {
    store.getInfoCommentTemplates()
  }, [store])

  return (
    <>
      <CommonInfo />
      <InfoWriteCommentTemplates />
    </>
  );
}));

export default InfoWriteBlock;