import React from 'react';
import { useInstance } from "react-ioc";
import { observer } from "mobx-react-lite";
import {
    Box,
    Button,
    TextField,
    Typography,
    Switch,
} from "@mui/material";
import SmsSendDataCreateWrite from '../../store/smsSendData.store';
import ArticleIcon from '@mui/icons-material/Article';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SheduleSmsTemplatesStore from '../../../sheduleSmsTemplates/store/sheduleSmsTemplates.store';
import { isPossibleSend } from '../../../../../../_common/helpers/countSmsDateForSheduleSend';
import CreateWriteStore from '../../store/CreateWrite.data.store';

const SmsTextsBlock = observer(() => {
    const store = useInstance(SmsSendDataCreateWrite)
    const sheduleSmsTemplatesStore = useInstance(SheduleSmsTemplatesStore)
    const createWriteStore = useInstance(CreateWriteStore)

    const handleSwitch = (index) => {
        store.switchSmsPermission(index)
    }

    const handleTemplateChoose = (index, textIndex) => {
        sheduleSmsTemplatesStore.setOpenModalFromSmsInWrite("new", index, textIndex, store.writeData)
    }

    const handleSmsDelete = (index, txtIndex) => {
        store.deleteSms(index, txtIndex)
    }

    const handleSmsAdd = (index) => {
        store.sddSms(index)
    }

    const changeSms = (index, txtIndex, value) => {
        store.changeSmsText(index, txtIndex, value)
    }

    return (
        <Box>
            {store?.smsTexts && store?.smsTexts?.map((smsBlock, index) => {
                const isPossibleToSend = isPossibleSend(createWriteStore.date, createWriteStore.timeStart, smsBlock.name)
                if (isPossibleToSend) {
                    return (
                        <Box key={`sms-block-${index}`} sx={{ pb: 1, borderBottom: "1px solid", borderColor: "primary.light4" }}>
                            <Box>
                                <label htmlFor={`contained-switch-send-sms-accordion-${index}`}>
                                    <Switch checked={smsBlock.permission} id={`contained-switch-send-sms-accordion-${index}`} onChange={() => handleSwitch(index)} />
                                    <Box sx={{ display: "inline" }}>
                                        <Typography variant={"subtitle2"} sx={{ display: "inline", fontSize: 14 }}>
                                            {smsBlock.name}
                                        </Typography>
                                    </Box>
                                </label>
                            </Box>
                            {smsBlock.text.length > 0 && smsBlock.text.map((sms, textIndex) => {
                                return (
                                    <Box key={`sms-block-${index}-${textIndex}`} sx={{ mt: 2 }}>
                                        <Button variant="outlined" size="small" onClick={() => handleTemplateChoose(index, textIndex)}>
                                            <Typography variant="button">
                                                Выбрать шаблон
                                            </Typography>
                                            <ArticleIcon />
                                        </Button>
                                        <TextField
                                            sx={{ mt: 2 }}
                                            label={"Текст сообщения"}
                                            value={sms && sms}
                                            size="small"
                                            focused
                                            fullWidth
                                            inputProps={{ maxLength: 600 }}
                                            multiline
                                            minRows={2}
                                            maxRows={4}
                                            onChange={(e) => changeSms(index, textIndex, e.target.value)}
                                        />
                                        <Button fullWidth variant="outlined" size="small" sx={{ mt: 2 }} onClick={() => handleSmsDelete(index, textIndex)}>
                                            <Typography variant="button">
                                                Удалить
                                            </Typography>
                                            <DeleteIcon />
                                        </Button>
                                    </Box>
                                )
                            })}
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{ mt: 2 }}
                                onClick={() => handleSmsAdd(index)}
                                disabled={smsBlock.text.length >= 5 ? true : false}
                            >
                                <Typography variant="button">
                                    Добавить еще смс
                                </Typography>
                                <AddIcon />
                            </Button>
                        </Box>

                    )
                } else {
                    return (
                        <Box key={`sms-block-${index}`} sx={{ display: "flex", flexWrap: "wrap", gap: 1, alignItems: "center" }}>
                            <label htmlFor={`contained-switch-send-sms-accordion-${index}`}>
                                <Switch disabled checked={false} id={`contained-switch-send-sms-accordion-${index}`} />
                                <Box sx={{ display: "inline" }}>
                                    <Typography variant={"subtitle2"} sx={{ display: "inline", fontSize: 14 }}>
                                        {smsBlock.name}.
                                    </Typography>
                                </Box>
                            </label>
                            <Typography>Отправка невозможна - время прошло.</Typography>
                        </Box>
                    )
                }

            })}

        </Box>

    );
});

export default SmsTextsBlock;