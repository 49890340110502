import React, { useState } from 'react';
import { observer } from "mobx-react-lite"
import SlideUpModal from "../../../../_components/UI/modals/SlideUpModal"
import { useInstance } from "react-ioc"
import PrePaymentDataStore from "../../stores/prePayment.data.store"
import { Grid } from "@mui/material"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import MedicalServicesIcon from "@mui/icons-material/MedicalServices"
import HomeIcon from "@mui/icons-material/Home"
import MedicalEstimate from "./MedicalEstimate"
import DomesticEstimate from "./DomesticEstimate"
import PrepaymentSendingConfirmation from "./PrepaymentSendingConfirmation"
import PrepaymentTab from "./PrepaymentTab"
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import ButtonMainTimered from "../../../../_components/UI/Buttons/ButtonMainTimered"


const Prepayment = (observer(() => {

  const store = useInstance(PrePaymentDataStore)

  const [medBtn, setMedBtn] = useState("primary.grey")
  const [domestBtn, setDomestBtn] = useState("primary.grey")
  const [prepBtn, setPrepBtn] = useState("primary.main")
  const [medBtnBorder, setMedBtnBorder] = useState("none")
  const [domestBtnBorder, setDomestBtnBorder] = useState("none")
  const [prepBorder, setPrepBorder] = useState("1px solid")


  const handleChangeBtn = (type) => {
    if (type === "medical") {
      store.setTab("medical")
      setMedBtn("primary.main")
      setDomestBtn("primary.grey")
      setPrepBtn("primary.grey")

      setMedBtnBorder("1px solid")
      setDomestBtnBorder("none")
      setPrepBorder("none")
    } else if (type === "domestic") {
      store.setTab("domestic")
      setMedBtn("primary.grey")
      setDomestBtn("primary.main")
      setPrepBtn("primary.grey")

      setMedBtnBorder("none")
      setDomestBtnBorder("1px solid")
      setPrepBorder("none")
    }
    else if (type === "prepayment") {
      store.setTab("prepayment")
      setMedBtn("primary.grey")
      setPrepBtn("primary.main")
      setDomestBtn("primary.grey")

      setMedBtnBorder("none")
      setDomestBtnBorder("none")
      setPrepBorder("1px solid")
    }
  }


  return (
    <SlideUpModal
      open={store.openModal}
      title={'Предоплата'}
      closeClick={() => {
        store.setOpenModal(false)
        store.setPrepaymentDataClear()

      }}
      // saveClick={() => {
      //   store.setOpenConfirmationModal(true)
      // }}

      customBtn={<ButtonMainTimered
        color={"contrastLight"}
        title={"Сохранить"}
        timerTitle={"Ожидайте"}
        onClick={()=>store.setOpenConfirmationModal(true)} />}
    >

      <Grid container sx={{mt: 4, mb: 2, pl: 1, pr: 1}} >


        <Grid container sx={{mb: 2, pl: 1, pr: 1, borderBottom: "1px solid #ECEDFF"}}>
          <Grid item>
            <Button
              sx={{borderBottom: prepBorder, borderColor: prepBtn, borderRadius: 0}}
              variant="text"
              size="large"
              onClick={() => handleChangeBtn("prepayment")}
            >
              <Typography
                sx={{color: prepBtn, display: {xs: "none", sm: "inline"}, mr: {xs: 0, sm: 1}}}
                variant={"subtitle1"}>
                Предоплата
              </Typography>
              <Typography sx={{color: prepBtn}}>
                <CreditScoreIcon />
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              sx={{borderBottom: medBtnBorder, borderColor: medBtn, borderRadius: 0}}
              variant="text"
              size="large"
              onClick={() => handleChangeBtn("medical")}
            >
              <Typography sx={{color: medBtn, display: {xs: "none", sm: "inline"}, mr: {xs: 0, sm: 1}}}
                          variant={"subtitle1"}>
                Медицинские услуги
              </Typography>
              <Typography sx={{color: medBtn}}>
                <MedicalServicesIcon />
              </Typography>
            </Button>
          </Grid>

          <Grid item>
            <Button
              sx={{borderBottom: domestBtnBorder, borderColor: domestBtn, borderRadius: 0}}
              variant="text"
              size="large"
              onClick={() => handleChangeBtn("domestic")}
            >
              <Typography sx={{color: domestBtn, display: {xs: "none", sm: "inline"}, mr: {xs: 0, sm: 1}}}
                          variant={"subtitle1"}>
                Бытовые услуги
              </Typography>
              <Typography sx={{color: domestBtn}}>
                <HomeIcon />
              </Typography>
            </Button>
          </Grid>
        </Grid>


        {!!store.specialist && store.specialist && <Grid container >

          {store.tab === "prepayment" && <Grid item xs={12}>
            <PrepaymentTab />
          </Grid>}

          {store.tab === "medical" && <Grid item xs={12}>
            <MedicalEstimate />
          </Grid>}

          {store.tab === "domestic" && <Grid item xs={12}>
            <DomesticEstimate />
          </Grid>}

        </Grid>}
      </Grid>


      {!!store.openConfirmationModal && <PrepaymentSendingConfirmation />}

    </SlideUpModal>
  );
}));

export default Prepayment;