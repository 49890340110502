import React, { useEffect } from 'react';
import { Outlet} from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, Grid, Paper, Toolbar } from "@mui/material";
import { Header } from "./header/Header";
import { SideBar } from "./sidebar/SideBar";
import { provider, useInstance } from "react-ioc";
import { observer } from "mobx-react-lite";
import NavStore from "./stores/nav.store";
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import CallsBanner from "../../CallsBanner/CallsBanner"
import CallsBannerStore from "./stores/calls.store"
import SmsTimerSendStore from '../../SmsTimerSend/store/sms-timer-send.store';
import SmsTimerSend from '../../SmsTimerSend/SmsTimerSend';
import RedirectSheduleTableStore from '../../../_common/stores/redirectSheduleTable.store';
import Chat from "../../Chat"
import StoreChatModal from "../../Chat/stores/storeChat"
import SmsBalanceStore from './stores/smsBalance.store';
import UserProfileStore from "../../../pages/admin/stores/user-profile.store"
import StoreBackToCallsSchedule from "./stores/backToCallsSchedule.store"
import StoreBackToCalls from "./stores/backToCalls.store"
import DataStoreListDays from "./stores/data.store"
import StoreTableModalDayList from "../../../pages/table/stores/storeTableModalDayList"
import authStore from "../../../_common/stores/auth.store";

const ItemText = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));


export const AuthLayout = provider(RedirectSheduleTableStore, NavStore, StoreChatModal,
  CallsBannerStore, SmsTimerSendStore, CallsBannerStore, SmsBalanceStore, UserProfileStore,
  StoreBackToCallsSchedule, StoreBackToCalls, DataStoreListDays, StoreTableModalDayList)(observer(() => {

    const nav = useInstance(NavStore)
  const smsTimerSendStore = useInstance(SmsTimerSendStore)
  const chatModal = useInstance(StoreChatModal)


  useEffect(() => {
    if (smsTimerSendStore.smsForSend.length > 0) {
      smsTimerSendStore.timerChange()
    }
  }, [smsTimerSendStore.smsInQueueAmmount])



  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box sx={{ display: 'flex', minHeight: { xs: 'calc(100vh - 64px)', md: 'calc(100vh - 60px)' } }}>
        {authStore.isAuth && <Header />}
        {authStore.isAuth && <SideBar />}
        <Box component="main" sx={{ p: { sm: 0, md: 3 }, width: {xs: "100vw", md: `calc(100vw - ${nav.drawerWidth}px)`} }}>
          <Toolbar />
          <Grid container sx={{ minHeight: '100%' }}>
            <Grid item xs={12}>
              <ItemText sx={{ p: nav.paperPadding, minHeight: '100%', overflowX: 'auto' }}>
                <Outlet />
              </ItemText>
            </Grid>
          </Grid>
        </Box>
        {smsTimerSendStore.openModal && authStore.isAuth && <SmsTimerSend />}
      </Box>
      {chatModal && authStore.isAuth && <Chat />}
      <CallsBanner />
    </LocalizationProvider>
  );
}));

