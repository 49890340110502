import { makeAutoObservable, toJS } from "mobx";
import {
    RECORD,
    COMMON_INFO_FOR_WRITE,
    INFOCOMMENTS,
    SPECIALIST_BY_DATE_CENTER,
    PREVIOUS_WRITE,
} from "../../../queries/modal.queries";
import { UPDATE_SHEDULE, DELETE_SMS, SEND_SMS } from "../../../mutations/modal.mutations";
import {
    client
} from "../../../../../_common/http/appoloClient";
import moment from "moment"
import { smsTimerValue } from "../../../../../_common/helpValues/smsConstants";

export default class ChangeWriteDataStore {

    state = {
        id: null,
        writeData : null,
        previousWrites: null,
        specialistId: "",
        centerId: "",
        date: "",
        timeStart: "",
        timeEnd: "",
        comment: "",
        statusIs: "",
        isStudent: false,
    }

    dataForChoose = {
        specialists: [],
        centers: [],
        statuses: [],
        infoComments: []
    }

    reset() {
        this.state = {
            id: null,
            writeData : null,
            previousWrites: null,
            specialistId: "",
            centerId: "",
            date: "",
            timeStart: "",
            timeEnd: "",
            comment: "",
            statusIs: "",
            isStudent: false,
        }
    
        this.dataForChoose = {
            specialists: [],
            centers: [],
            statuses: [],
        }
    }

    get writeId () {
        return this.state.id
    }

    setWriteId (id) {
        this.state.id = id
    }

    setSpecialistId (id) {
        this.state.specialistId = id
    }

    getSpecialists(centerId, date) {
        return new Promise((resolve)=>{
            if (centerId && date){
                client.query({
                query: SPECIALIST_BY_DATE_CENTER,
                variables: {
                    centerId: centerId,
                    date: moment(date).format("YYYY-MM-DD"),
                    isActive: true,
                }
            }).then((data) => {
                this.setSpecialists(data.data.tableBySpecialist)
                const isChousenSpecialistInDay = data.data.tableBySpecialist.filter((item) => item.id === this.state.specialistId)
                if (!isChousenSpecialistInDay?.length>0){
                    this.setSpecialistId("")
                }
                resolve(true)
            })
            }
        })
        
    }

    //get/set state

    get previousWrites () {
        return this.state.previousWrites
    }

    get writeData () {
        return this.state.writeData
    }

    get specialistId () {
        return this.state.specialistId
    }

    get centerId () { 
        return this.state.centerId
    }

    setCenterId (id) {
        this.state.centerId = id
        this.getSpecialists(id, this.state.date)
    }

    get date () {
        return this.state.date
    }

    setDate (date) {
        this.state.date = date
        this.getSpecialists(this.state.centerId, date)
    }

    get timeStart () {
        return this.state.timeStart
    }

    setTimeStart (time) {
        this.state.timeStart = time
    }

    get timeEnd () {
        return this.state.timeEnd
    }

    setTimeEnd (time) {
        this.state.timeEnd = time
    }

    get comment () {
        return this.state.comment
    }

    setComment (comment) {
        this.state.comment = comment
    }

    setAddTextToComment (text) {
        const freeSymbolsAmount = 300 - this.state.comment.length
                if (freeSymbolsAmount >= text.length) {
                    if (this.state.comment.length > 0) {
                        this.state.comment += `, ${text}`
                    } else {
                        this.state.comment = text
                    }
                }
    }

    get statusIs () {
        return this.state.statusIs
    }

    setStatusIs (id) {
        this.state.statusIs = id
    }

    get isStudent () {
        return this.state.isStudent
    }

    switchIsStudent () {
        this.state.isStudent = !this.state.isStudent
    }

    setIsStudent (isStudent) {
        this.state.isStudent = isStudent
    }

    //data for choose set/get

    get specialists () {
        return toJS(this.dataForChoose.specialists)
    }

    get centers () {
        return this.dataForChoose.centers
    }

    get statuses () {
        return this.dataForChoose.statuses
    }

    get infoComments () {
        return this.dataForChoose.infoComments
    }

    setSpecialists(specialists) {
        this.dataForChoose.specialists = specialists
    }

    setCenters(centers) {
        this.dataForChoose.centers = centers
    }

    setStatuses(statuses) {
        this.dataForChoose.statuses = statuses
    }

    setInfoComments(comments) {
        this.dataForChoose.infoComments = comments
    }

    async getPreviousWrites(id) {
        await client.query({
            query: PREVIOUS_WRITE,
            variables: {
                patientId: id
            }
        }).then((data) => {
            this.state.previousWrites = data.data.scheduleByPatient
        })
    }

    getWriteDataById (id) {
        return new Promise((resolve)=>{
            client.query({
                query: RECORD,
                variables: {
                    scheduleId: id,
                }
            }).then((data) => {
                this.state.id = id
                this.state.writeData = data?.data?.scheduleInfo
                // this.setCenterId(data?.data?.scheduleInfo.medicalCenter.id)
                this.state.centerId = data?.data?.scheduleInfo.medicalCenter.id
                this.state.date = data?.data?.scheduleInfo.date
                // this.setDate(data?.data?.scheduleInfo.date)
                this.setTimeStart(data?.data?.scheduleInfo.timeStart)
                this.setTimeEnd(data?.data?.scheduleInfo.timeEnd)
                this.setComment(data?.data?.scheduleInfo.description)
                this.setStatusIs(data?.data?.scheduleInfo.status.id)
                this.setIsStudent(data?.data?.scheduleInfo.isStudent)
                this.setSpecialistId(data?.data?.scheduleInfo.dayTable.specialist.id)
                if (data?.data?.scheduleInfo.patient){
                    this.getPreviousWrites(data?.data?.scheduleInfo.patient.id)
                }
                this.getSpecialists(data?.data?.scheduleInfo.medicalCenter.id, data?.data?.scheduleInfo.date).then(()=>{
                    resolve(true)
                })
            })
        })
        
    }

    getDataForChoose() {
        return new Promise((resolve)=>{
            client.query({
                query: COMMON_INFO_FOR_WRITE,
            }).then((data) => {
                this.setStatuses(data.data.scheduleStatuses)
                this.setCenters(data.data.medicalCenters)
                resolve(true)
            })
        }) 
    }

    async getInfoComments() {
        await client.query({
            query: INFOCOMMENTS,
        }).then((data) => {
            this.setInfoComments(data.data.commentOptions)
        })
    }

    findSpecialistDayTableID () {
            let table = []
            for (let i = 0; i < this.dataForChoose.specialists.length; i++) {
                if (this.dataForChoose.specialists[i].id === this.state.specialistId) {
                    table = this.dataForChoose.specialists[i].table
                    break
                }
            }
            if (table.length === 1) {
                return table[0].id
            } else if (table.length > 1) {
                for (let i = 0; i < table.length; i++) {
                    if (table[i].timeStart.slice(0, 5) <= this.state.timeStart && this.state.timeEnd <= table[i].timeEnd.slice(0, 5)) {
                        return table[i].id
                    }
                }
            }
            return table[0].id
    }

    async saveWrite(smsData) {
        return new Promise((resolve, reject)=>{
            const dayTableId = this.findSpecialistDayTableID()
            let smsSendNow = []
            let isDataChanged = false
            if (dayTableId!==this.state.writeData.dayTable.id){
                isDataChanged = true
            }
            if (this.state.statusIs!==this.state.writeData.status.id){
                isDataChanged = true
            }
            if (this.state.timeStart!==this.state.writeData.timeStart){
                isDataChanged = true
            }
            if (this.state.timeEnd!==this.state.writeData.timeEnd){
                isDataChanged = true
            }
            if (this.state.isStudent!==this.state.writeData.isStudent){
                isDataChanged = true
            }
            if (this.state.comment!==this.state.writeData.description){
                isDataChanged = true
            }
            if (isDataChanged){
                client.mutate({
                    mutation: UPDATE_SHEDULE,
                    variables: {
                        "input": {
                        scheduleEntryId: this.state.id,
                        dayTableId: dayTableId,
                        specialistId: this.state.specialistId,
                        statusId: this.state.statusIs,
                        centerId: this.state.centerId,
                        date: moment(this.state.date).format("YYYY-MM-DD"),
                        timeStart: this.state.timeStart,
                        timeEnd: this.state.timeEnd,
                        description: this.state.comment,
                        isStudent: this.state.isStudent
                        }
                    }
                }).catch((error) => {
                    if (error) alert(error)
                    reject(error)
                }).then((data)=>{
                    if (data){
                        let smsForDelete = []
                        let newMessages = []
                        smsData.messages.forEach((group)=>{
                            group.text.forEach((sms)=>{
                                if (sms.new){
                                    if (sms.text.length>0){
                                        newMessages.push({
                                            type: group.id,
                                            name: group.name,
                                            text: sms.text,
                                            permission: group.permission,
                                        })
                                    }
                                }else if (sms.forDelete){
                                    smsForDelete.push(sms.id)
                                }
                            })
                        })
                        smsForDelete.forEach((id)=>{
                            client.mutate({
                                mutation: DELETE_SMS,
                                variables: {
                                    smsId: id
                                }
                            }).catch((err)=>{
                                if (err) alert("Смс не удалена")
                            })
                        })
                        newMessages.forEach((sms, index)=>{
                            if (sms.name === "сейчас" && sms.permission){
                                if (sms.text.length > 0) {
                                    smsSendNow.push({
                                        write: this.state.writeData,
                                        patient: this.state.writeData.patient,
                                        phoneId: smsData.phone,
                                        text: sms.text,
                                        outputText: sms.text,
                                    })
                                }
                            }

                            else if (sms.name === "за 3 дня до записи") {
                                const day = moment(this.state.date)
                                    if (sms.text.length > 0) {
                                        client.mutate({
                                            mutation: SEND_SMS,
                                            variables: {
                                                input:{
                                                    patient: this.state.writeData.patient.id,
                                                    phone: smsData.phone,
                                                    text: sms.text,
                                                    dispatch: moment(day.subtract(3, 'days')).format("YYYY-MM-DD")+"T15:00:00",
                                                    scheduleEntry: this.state.writeData.id,
                                                    smsType: sms.type,
                                                }
                                            }
                                        })
                                    }
                            }else if (sms.name === "за день до записи") {
                                const day = moment(this.state.date)
                                    if (sms.text.length > 0) {
                                        client.mutate({
                                            mutation: SEND_SMS,
                                            variables: {
                                                input:{
                                                    patient: this.state.writeData.patient.id,
                                                    phone: smsData.phone,
                                                    text: sms.text,
                                                    dispatch: moment(day.subtract(1, 'days')).format("YYYY-MM-DD")+"T15:00:00",
                                                    scheduleEntry: this.state.writeData.id,
                                                    smsType: sms.type,
                                                }
                                            }
                                        })
                                    }
                            }else if (sms.name === "за 2 часа до записи") {
                                const day = moment(this.writeData.date).format("YYYY-MM-DD")
                                let time = +this.writeData.timeStart.slice(0, 2) - 2
                                if (time>7 && time<10){
                                    time = "0"+time+this.writeData.timeStart.slice(2, 5)
                                }else if (time > 10){
                                    time = ""+time+this.writeData.timeStart.slice(2, 5)
                                }else time = "07:00"
                                    if (sms.text.length > 0) {
                                        client.mutate({
                                            mutation: SEND_SMS,
                                            variables: {
                                                input:{
                                                    patient: this.state.writeData.patient.id,
                                                    phone: smsData.phone,
                                                    text: sms.text,
                                                    dispatch: day+"T"+time,
                                                    scheduleEntry: this.state.writeData.id,
                                                    smsType: sms.type,
                                                }
                                            }
                                        })
                                    }
                            }
                        })
                        resolve({
                            smsSendNow: smsSendNow,
                            writeData: data.data.updateSchedule.schedule
                            })
                    }
                    
                })
            }else{
                let smsForDelete = []
                    let newMessages = []
                    smsData.messages.forEach((group)=>{
                        group.text.forEach((sms)=>{
                            if (sms.new){
                                if (sms.text.length>0){
                                    newMessages.push({
                                        type: group.id,
                                        name: group.name,
                                        text: sms.text,
                                        permission: group.permission,
                                    })
                                }
                            }else if (sms.forDelete){
                                smsForDelete.push(sms.id)
                            }
                        })
                    })
                    smsForDelete.forEach((id)=>{
                        client.mutate({
                            mutation: DELETE_SMS,
                            variables: {
                                smsId: id
                            }
                        })
                    })
                    newMessages.forEach((sms, index)=>{
                        if (sms.name === "сейчас" && sms.permission){
                            if (sms.text.length > 0) {
                                smsSendNow.push({
                                    write: this.state.writeData,
                                    patient: this.state.writeData.patient,
                                    phoneId: smsData.phone,
                                    text: sms.text,
                                    outputText: sms.text,
                                    time: smsTimerValue + index*10,
                                    sended: false,
                                    show: true,
                                })
                            }
                        }

                        else if (sms.name === "за 3 дня до записи") {
                            const day = moment(this.state.date)
                                if (sms.text.length > 0) {
                                    client.mutate({
                                        mutation: SEND_SMS,
                                        variables: {
                                            input:{
                                                patient: this.state.writeData.patient.id,
                                                phone: smsData.phone,
                                                text: sms.text,
                                                dispatch: moment(day.subtract(3, 'days')).format("YYYY-MM-DD")+"T15:00:00",
                                                scheduleEntry: this.state.writeData.id,
                                                smsType: sms.type,
                                            }
                                        }
                                    })
                                }
                        }else if (sms.name === "за день до записи") {
                            const day = moment(this.state.date)
                                if (sms.text.length > 0) {
                                    client.mutate({
                                        mutation: SEND_SMS,
                                        variables: {
                                            input:{
                                                patient: this.state.writeData.patient.id,
                                                phone: smsData.phone,
                                                text: sms.text,
                                                dispatch: moment(day.subtract(1, 'days')).format("YYYY-MM-DD")+"T15:00:00",
                                                scheduleEntry: this.state.writeData.id,
                                                smsType: sms.type,
                                            }
                                        }
                                    })
                                }
                        }else if (sms.name === "за 2 часа до записи") {
                            const day = moment(this.writeData.date).format("YYYY-MM-DD")
                            let time = +this.writeData.timeStart.slice(0, 2) - 2
                            if (time>7 && time<10){
                                time = "0"+time+this.writeData.timeStart.slice(2, 5)
                            }else if (time > 10){
                                time = ""+time+this.writeData.timeStart.slice(2, 5)
                            }else time = "07:00"
                                if (sms.text.length > 0) {
                                    client.mutate({
                                        mutation: SEND_SMS,
                                        variables: {
                                            input:{
                                                patient: this.state.writeData.patient.id,
                                                phone: smsData.phone,
                                                text: sms.text,
                                                dispatch: day+"T"+time,
                                                scheduleEntry: this.state.writeData.id,
                                                smsType: sms.type,
                                            }
                                        }
                                    })
                                }
                        }
                })
                resolve({
                    smsSendNow: smsSendNow,
                    writeData: null
                    })
            }
            

        })
        
    }

    deleteWrite() {
        return new Promise((resolve, reject)=>{
            const dayTableId = this.findSpecialistDayTableID()
            client.mutate({
                mutation: UPDATE_SHEDULE,
                variables: {
                    "input": {
                    scheduleEntryId: this.state.id,
                    dayTableId: dayTableId,
                    statusId: this.state.statusIs,
                    date: moment(this.state.date).format("YYYY-MM-DD"),
                    timeStart: this.state.timeStart,
                    timeEnd: this.state.timeEnd,
                    isActive: false
                    }
                }
            }).catch((error) => {
                if (error) alert(error)
                reject(error)
            }).then((data)=>{
                if (data){
                    resolve(this.state.id)
                }
            })
        })
        
    }

    recoverWrite() {
        return new Promise((resolve, reject)=>{
            const dayTableId = this.findSpecialistDayTableID()
            client.mutate({
                mutation: UPDATE_SHEDULE,
                variables: {
                    "input": {
                    scheduleEntryId: this.state.id,
                    dayTableId: dayTableId,
                    statusId: this.state.statusIs,
                    date: moment(this.state.date).format("YYYY-MM-DD"),
                    timeStart: this.state.timeStart,
                    timeEnd: this.state.timeEnd,
                    isActive: true
                    }
                }
            }).catch((error) => {
                if (error) alert(error)
                reject(false)
            }).then((data)=>{
                if (data){
                    resolve(this.state.id)
                }
            })
        })
        
    }

    setPatientForSms() {
        return(
                {
                    surname: this.state.writeData.patient.lastName,
                    name: this.state.writeData.patient.firstName,
                    patronymic: this.state.writeData.patient.patronymic,
                    phones: this.state.writeData.patient.phones,
                }
        )
    }

    setDataForSmsCreate() {
        let writeData = {
            specialist: {
                surname: "",
                name: "",
                patronymic: "",
            },
            patient: {
                surname: "",
                name: "",
                patronymic: "",
            },
            date: "",
            time: ""
        }

        let specialist = {}
        for (let i = 0; i < this.dataForChoose.specialists.length; i++) {
            if (this.state.specialistId === this.dataForChoose.specialists[i].id) {
                specialist = this.dataForChoose.specialists[i]
            }
        }
        
        const patient = this.state.writeData?.patient

        writeData.date = moment(this.state.date).format("DD.MM.YYYY")
        writeData.time = this.state.timeStart
        writeData.specialist = {
            surname: specialist.lastName,
            name: specialist.firstName,
            patronymic: specialist.patronymic
        }
        writeData.patient = {
            surname: patient?.lastName,
            name: patient?.firstName,
            patronymic: patient?.patronymic
        }
        return writeData
    }

    constructor() {
        makeAutoObservable(this)
    }

}