import {gql} from "@apollo/client";

export const CHANGE_TABLE = gql`
mutation changeTable($dayTableIdList: [ID], $updateInput: [DayTableInput], $createInput: [DayTableInput]){
  deleteTable(dayTableIdList: $dayTableIdList){
    ok
    errors
  }
  updateTable(input: $updateInput){
    ok
    errors
  }
  createTable(input: $createInput){
    ok
    errors
  }
}
`