import {ApolloClient, ApolloLink, InMemoryCache, split} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws'
import {getMainDefinition} from "@apollo/client/utilities";
import { SubscriptionClient } from 'subscriptions-transport-ws'
import {onError} from "@apollo/client/link/error";
import authStore from "../stores/auth.store";


const { createUploadLink } = require('apollo-upload-client')

const wsLink = new WebSocketLink(
  new SubscriptionClient(process.env.REACT_APP_API_WSHOST, {
    // connectionParams: {
    //   authToken: `JWT ${authStore.token}` || null,
    // },
    reconnect: true,
    timeout: 999999999,
  })
);

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_HOST,
  credentials: 'include'
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      // console.log('error-grogu', err)
        switch (err.message) {
          case "You do not have permission to perform this action":
            console.log('[GROGU-MSG]: У вас нет прав доступа для выполнения этой операции.')
            authStore.logout()
            //return forward(operation);
        }
    }
  }

  // To retry on network errors, we recommend the RetryLink
  // instead of the onError link. This just logs the error.
  if (networkError) {
    console.log(`[GROGU-MSG]: ${networkError}`);
    authStore.logout()
  }
});

const splitLink = split(
  ({query}) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  uploadLink,
);

//const link = ApolloLink.from([withToken, authMiddleware, splitLink]);
const link = ApolloLink.from([errorLink, splitLink]);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
  query: {
    fetchPolicy: 'no-cache',
  },
  mutate: {
    fetchPolicy: 'no-cache',
  },
};


export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  defaultOptions
})