import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { useInstance } from "react-ioc";
import {
    Box,
    IconButton,
    Typography,
} from "@mui/material";
import ChangeWriteViewStore from '../store/ChangeWriteView.view.store';
import ChangeWriteDataStore from '../store/ChangeWriteData.data.store';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';


const DelayBlock = (observer(() => {
    const [wasOpenDeleteDelay, setWasOpenDeleteDelay] = useState(false)
    const dataStore = useInstance(ChangeWriteDataStore)
    const viewStore = useInstance(ChangeWriteViewStore)

    useEffect(() => {
        if (wasOpenDeleteDelay && viewStore.openDeleteDelayModal === false) {
            dataStore.getWriteDataById(dataStore.writeData.id)
        }
    }, [viewStore, viewStore.openDeleteDelayModal])

    const handleDeleteDelay = () => {
        setWasOpenDeleteDelay(true)
        viewStore.setOpenDeleteDelayModal(true)
    }

    const handleOpenCreateLatnessModal = () => {
        viewStore.setOpenCreateLatness(true)
    }


    return (
        <Box
            sx={{
                bgcolor: "primary.yellow",
                p: 1,
                borderRadius: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1
            }}
        >
            <Typography sx={{ color: "primary.main" }} variant="subtitle1">
                Опоздание на {dataStore.writeData?.delay} мин.
            </Typography>
            <Box>
                <IconButton sx={{ color: "primary.main" }} onClick={handleOpenCreateLatnessModal}>
                    <EditIcon />
                </IconButton>
                <IconButton sx={{ color: "primary.main" }} onClick={handleDeleteDelay}>
                    <DeleteForeverIcon />
                </IconButton>
            </Box>
        </Box>
    );
}));

export default DelayBlock;

