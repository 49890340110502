import {makeAutoObservable} from "mobx";
import {client} from "../http/appoloClient";

export default class UserStore {


    _userProfile = {
      lastName: "",
      firstName: "",
      patronymic: "",
      email: "test@test.ru",
      position: "",
      sip: "",
      birthday: null,
      socialNetworks: [],
    }

    get lastNameGet () {
      return this._userProfile.lastName
    }

    setLastName (lastName) {
      this._userProfile.lastName = lastName
    }

  constructor() {
    this._apolloClient = client
    this._isAuth = false
    this._user = null
    this._isLoading = false
    makeAutoObservable(this)
  }


  setAppoloClient(client) {
    this._apolloClient = client
  }

  setIsAuth(bool) {
    this._isAuth = bool
  }

  setUser(user) {
    this._user = user
  }

  setUserEmail(email) {
    localStorage.setItem('email', email)
  }

  setUserProfile(data) {
    this._userProfile = data
  }

  setIsLoading(bool) {
    this._isLoading = bool
  }

  get isAuth() {
    return this._isAuth
  }

  get user() {
    return this._user
  }

  get userProfile() {
    return this._userProfile
  }

  get isLoading() {
    return this._isLoading
  }

  getToken() {
    return localStorage.getItem('token')
  }

  getClient() {
    return this._apolloClient
  }

  getRefreshToken() {
    return localStorage.getItem('refresh_token')
  }

  getUserEmail() {
    return localStorage.getItem('email')
  }

  login(data, cb) {
    if (data.tokenAuth.success) {
      let user = data.tokenAuth.user
      localStorage.setItem('token', data.tokenAuth.token)
      localStorage.setItem('refresh_token', data.tokenAuth.refreshToken)
      this.setUserEmail(user.email)
      this.setIsAuth(true)
      this.setUser({
        id: user.pk,
        name: `${user.lastName} ${user.firstName}`,
        email: user.email
      })
      cb()
    } else {
    }
  }

  logout() {
    try {
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('email')
      this.setIsAuth(false)
      this.setUser(null)
      this._apolloClient.client.resetStore()
    } catch (e) {
    }
  }

}