import React, {useEffect} from 'react';
import {
    Box,
    Typography
} from "@mui/material";
import { useInstance } from "react-ioc";
import { observer } from "mobx-react-lite";
import SmsBalanceStore from '../stores/smsBalance.store';

const SmsBalance = observer(({ mobileMenu }) => {
    const smsBalanceStore = useInstance(SmsBalanceStore)

    useEffect(() => {
        if (smsBalanceStore.refetchBalance) {
          smsBalanceStore.getSmsBalance()
          smsBalanceStore.switchRefetchBalance()
        }
      }, [smsBalanceStore.refetchBalance])

    return (
        <>{smsBalanceStore?.balance &&
            <Box sx={{ m: [0, 1] }}>
                <Typography sx={{ display: "inline" }}>
                    Баланс:
                </Typography>
                <Typography sx={{ display: "inline" }}>
                    {" "}{smsBalanceStore?.balance}{'\u20BD'}
                </Typography>
            </Box>
        }
        </>
    );
});

export default SmsBalance