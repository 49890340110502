export const nowSmsSheduleIdentifyer = "Отправить при создании записи в расписании"

export const inDaySmsSheduleIdentifyer = "Отправить в день записи"

export const twoHoursSmsSheduleIdentifyer = "Отправить за 2 часа до записи"

export const threeDaysBeforSheduleIdentifyer = "Отправить за 3 дня до записи"

export const smsVariables = {
    specialistFullName: "#SpecialistFullName#",
    specialistSurnameInitials: "#SpecialistSurnameInitials#",
    patientFullName: "#PatientFullName#",
    patientSurnameInitials: "#PatientSurnameInitials#",
    patientNamePatronymic: "#PatientNamePatronymic#",
    date: "#date#",
    time: "#time#",
}

export const smsTimerValue = 120 //2 minutes