import { makeAutoObservable } from "mobx";

export default class CreateWriteViewStore {

    state = {
        openModal: false,
        isInfo: false,
        isNewPatient: false,
        infoFromShedule: null,
    }

    resetStore () {
        this.state.openModal = false
        this.state.isInfo = false
        this.state.isNewPatient = false
        this.state.infoFromShedule = null
    }

    get infoFromShedule() {
        return this.state.infoFromShedule
    }

    setInfoFromShedule(info) {
        this.state.infoFromShedule = info
    }

    setOpenModal() {
        this.state.openModal = true
    }

    closeModal() {
        this.state.openModal = false
        this.resetStore()
    }

    get openModal() {
        return this.state.openModal
    }

    setOpenModalGetInfo(info) {
        this.setInfoFromShedule(info)
        this.setOpenModal()
    }

    setIsInfo(boolean) {
        this.state.isInfo = !!boolean
    }

    get isInfo() {
        return this.state.isInfo
    }

    setIsNewPatient(boolean) {
        this.state.isNewPatient = !!boolean
    }

    get isNewPatient() {
        return this.state.isNewPatient
    }

    constructor() {
        makeAutoObservable(this)
    }

}