import { makeAutoObservable } from "mobx";

export default class CallsBannerStore {

  state = {
    openModal: false,
    callsArray: [],
    callsArrayIncoming: [],
    callsOutgoing: [],
    currentCall: null,
    activeCenterId: '1',
    refechCallState: false,
  }

  constructor() {
    makeAutoObservable(this)
  }


  setRefechCallStateTrue(){
    this.state.refechCallState = true
  }

  setRefechCallStateFalse(){
    this.state.refechCallState = false
  }

  get refechCallState() {
    return this.state.refechCallState
  }


  setAllCalls(call) {

    this.setRefechCallStateTrue()

    if (call.direction === "incoming") {
      let isExisted = false
      this.state.callsArrayIncoming.forEach((elem) => {
        if (elem.id === call.id) isExisted = true
      })
      isExisted === false && this.state.callsArrayIncoming.push(call)
    } else if (call.direction === "outgoing") {
      let isExisted = false
      this.state.callsOutgoing.forEach((elem) => {
        if (elem.id === call.id) isExisted = true
      })
      isExisted === false && this.state.callsOutgoing.push(call)
    }
  }

  setAccepted(call) {
    this.state.currentCall = call

    this.setRefechCallStateTrue()


    if (call.direction === "incoming") {
      for (let i = 0; i < this.state.callsArrayIncoming.length; i++) {
        if (call.id === this.state.callsArrayIncoming[i].id) {
          this.state.callsArrayIncoming.splice(i, 1)
        }
      }
    } else if (call.direction === "outgoing") {
      for (let i = 0; i < this.state.callsOutgoing.length; i++) {
        if (call.id === this.state.callsOutgoing[i].id) {
          this.state.callsOutgoing.splice(i, 1)
        }
      }
    }

  }

  async removeIncomming(call) {
    for (let i = 0; i < this.state.callsArrayIncoming.length; i++) {
      if (this.state.callsArrayIncoming[i].id === call.id) {
        this.state.callsArrayIncoming[i].status = call.status
        this.state.callsArrayIncoming[i].lazyRemoove = true
        break
      }
    }
    await setTimeout(() => {
      for (let i = 0; i < this.state.callsArrayIncoming.length; i++) {
        if (this.state.callsArrayIncoming[i].id === call.id) {
          this.state.callsArrayIncoming.splice(i, 1)
          break
        }
      }
    }, 10000)
  }

  async removeOutgoing(call) {
    for (let i = 0; i < this.state.callsOutgoing.length; i++) {
      if (this.state.callsOutgoing[i].id === call.id) {
        this.state.callsOutgoing[i].status = call.status
        this.state.callsOutgoing[i].lazyRemoove = true
        break
      }
    }
    await setTimeout(() => {
      for (let i = 0; i < this.state.callsOutgoing.length; i++) {
        if (this.state.callsOutgoing[i].id === call.id) {
          this.state.callsOutgoing.splice(i, 1)
          break
        }
      }
    }, 10000)
  }

  setAnyStatusRemoveCall(call) {
    if (this.state.currentCall?.id === call.id) this.state.currentCall = null
    if (call.direction === "incoming") {
      this.removeIncomming(call)
    } else if (call.direction === "outgoing") {
      this.removeOutgoing(call)
    }
  }

  setMutateCallsInfo(call) {
    this.setRefechCallStateTrue()

    if (call.status === "ACCEPTED") {
      this.setAccepted(call)
    } else if (["NOT_ACCEPTED", "TALKING_END", "ENDED", "MISSED"].includes(call.status)) {
      this.setAnyStatusRemoveCall(call)
    }


  }

  get currentCall() {
    return this.state.currentCall
  }

  get callsArray() {
    return this.state.callsArray
  }

  get callsArrayIncoming() {
    return this.state.callsArrayIncoming
  }

  get callsArrayOutgoing() {
    return this.state.callsOutgoing
  }

  get openModal() {
    return this.state.openModal
  }

  setOpenChatModal(open) {
    this.state.openModal = open
  }

  setfilteredArr(data) {
    this.state.callsArrayIncoming = data
  }

  setfilteredArrOut(data) {
    this.state.callsOutgoing = data
  }

  setCallArray(data) {
    let isExisted = false
    this.state.callsArray.forEach((elem) => {
      if (elem.id === data.id) isExisted = true
    })
    if (!isExisted) {
      this.state.callsArray.push(data)
      this.state.callsArrayIncoming = this.state.callsArray.filter((el) => el.direction === "incoming")
      this.state.callsOutgoing = this.state.callsArray.filter((el) => el.direction === "outgoing")
    }
  }

  setRemoveCall(call) {
    if (call.direction === "incoming") {
      for (let i = 0; i < this.state.callsArrayIncoming.length; i++) {
        if (call.id === this.state.callsArrayIncoming[i].id) {
          this.state.callsArrayIncoming.splice(i, 1)
        }
      }
    } else if (call.direction === "outgoing") {
      for (let i = 0; i < this.state.callsOutgoing.length; i++) {
        if (call.id === this.state.callsOutgoing[i].id) {
          this.state.callsOutgoing.splice(i, 1)
        }
      }
    }
  }

}