import {gql} from "@apollo/client";

export const CHAT_MESSAGES_SUBSCRIPTION = gql`
    subscription NewChatMessages($userId:ID) {
      chatMessages(userId:$userId) {     
         id
        text
        fromUser{
          id
          firstName
          lastName
          patronymic
        }
        toUser{
          id
          firstName
          lastName
          patronymic
        }
        created
        isRead
        isDeleted
        newMessagesCount
        newMessagesMessagesList {
          id
          created
          modified
          text
          fromUser{id}
          toUser{id}
          isRead
          isDeleted
        }
      }
    }
`;

export const ENDED_CHAT_SUBSCRIPTION = gql`

subscription messageRead($userId:ID){
    messageRead(userId:$userId){
      
      id
      created
      modified
      text
      fromUser{id}
      isRead
      isDeleted
      
      newMessagesCount
      
      newMessagesMessagesList{
        id
        created
        modified
        text
        fromUser{id}
        toUser{
          id
        }
        isRead
        isDeleted
      }
    }
  }
`