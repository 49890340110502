import React from 'react';
import ReactTextMask from 'react-text-mask';

const TextMaskCustom = React.forwardRef((props, ref) => {
  const { inputRef, ...other } = props;

  return (
    <ReactTextMask
      {...other}
      ref={(inputElement) => {
        if (ref) {
          ref.current = inputElement;
        }
        if (inputRef) {
          inputRef(inputElement);
        }
      }}
      placeholder={props.placeholder}
      mask={props.mask}
      guide={props.guide}
    />
  );
});

export default TextMaskCustom;
