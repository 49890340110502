import { Paper, Modal } from "@mui/material";
import { styled } from "@mui/material/styles";


const StyledPaper = styled(Paper)(({ theme }) => ({
  position: "absolute",
  width: "50%",
  left: "25%",
  top: "10%",
  maxHeight: "84%",
  overflow: "auto",
  background: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  borderRadius: "10px",
  padding: "24px",
  outline: "none",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    padding: "10px 10px 12px 10px",
  },
  [theme.breakpoints.down(1200)]: {
    width: "calc(100% - 140px)",
    maxHeight: "calc(100% - 140px)",
    left: "calc(50% - ((100% - 140px) / 2))",
    top: "calc(50% - ((100% - 140px) / 2))",
  },
  [theme.breakpoints.down("md")]: {
    width: "calc(100% - 40px)",
    maxHeight: "calc(100% - 40px)",
    left: "calc(50% - ((100% - 40px) / 2))",
    top: "calc(50% - ((100% - 40px) / 2))",
  },
  [theme.breakpoints.up("xl")]: {
    paddingTop: "44px",
    paddingLeft: "76px",
    paddingRight: "76px",
  },
  [theme.breakpoints.down(3839)]: {
    "& ::-webkit-scrollbar": {
      width: "6px",
      height: "6px",
    },

    "& ::-webkit-scrollbar-thumb": {
      background:
        theme.palette.mode === "light"
          ? "rgba(56, 69, 86, 0.15)"
          : "rgba(229, 229, 229, 0.15)",
      borderRadius: "15px",
      width: "6px",
      height: "6px",
    },

    "& ::-webkit-scrollbar-thumb:hover": {
      background:
        theme.palette.mode === "light"
          ? "rgba(56, 69, 86, 0.5)"
          : "rgba(229, 229, 229, 0.5)",
    },

    "& ::-webkit-scrollbar-track": {
      background: "transparent",
      borderRadius: "15px",
    },

    // Firefox
    "& div": {
      scrollbarWidth: "thin",
      scrollbarColor:
        theme.palette.mode === "light"
          ? "rgba(56, 69, 86, 0.15)"
          : "rgba(229, 229, 229, 0.15)",
    },
  },
  [theme.breakpoints.up(3840)]: {
    paddingRight: "24px",

    "& ::-webkit-scrollbar": {
      width: "12px",
      height: "12px",
    },

    "& ::-webkit-scrollbar-thumb": {
      background:
        theme.palette.mode === "light"
          ? "rgba(56, 69, 86, 0.15)"
          : "rgba(229, 229, 229, 0.15)",
      borderRadius: "15px",
      width: "12px",
      height: "12px",
    },

    "& ::-webkit-scrollbar-thumb:hover": {
      background:
        theme.palette.mode === "light"
          ? "rgba(56, 69, 86, 0.5)"
          : "rgba(229, 229, 229, 0.5)",
    },

    "& ::-webkit-scrollbar-track": {
      background: "transparent",
      borderRadius: "15px",
    },

    // Firefox
    "& div": {
      scrollbarWidth: "auto",
      scrollbarColor:
        theme.palette.mode === "light"
          ? "rgba(56, 69, 86, 0.15)"
          : "rgba(229, 229, 229, 0.15)",
    },
  },
}));




const ModalPrepayment = ({open, onClose, children}) => {

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
        <StyledPaper>
          {children}
        </StyledPaper>
    </Modal>
  );
};

export default ModalPrepayment;
