import React, {lazy} from "react";
import AuthPage from "../../pages/auth/auth.page";
import {SchedulePage} from "../../pages/schedule/schedule.page";
import RequireAuth from "./RequireAuth";
import SuccessPaymentPage from "../../publicPages/successPayment/SuccessPayment";
import ErrorPaymentPage from "../../publicPages/errorPayment/ErrorPayment";
import FourHundredFour from "../../publicPages/fourHundredFour/FourHundredFour";
import AdvancePaymentAgreement from "../../publicPages/advancePaymentAgreement/advancePaymentAgreement";

const lazyRetry = (componentImport) =>
  new Promise((resolve, reject) => {
    const storageKey = `retry-lazy-refreshed${btoa(componentImport.toString())}`;
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(storageKey) || 'false');
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(storageKey, 'false');
        if (component === undefined) {
          window.sessionStorage.setItem(storageKey, 'true');
          return window.location.reload(); // refresh the page
        }
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(storageKey, 'true');
          window.location.reload();
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });

// const UserProfilePage = lazy(() => import('../../pages/admin/user-profile.page'))
const ControlPanelPage = lazy(() => import('../../pages/admin/control-panel.page'))
const DebtPrepaidPage = lazy(() => import('../../pages/debt-prepaid/debt-prepaid.page'))
// const PatientsPage = lazy(() => import('../../pages/patients/patients.page'))
// const PatientCardPage = lazy(() => import('../../pages/patients/patient-card.page'))
// const DeptPage = lazy(() => import('../../pages/dept/dept.page'))
// const TablePage = lazy(() => import('../../pages/table/table.page'))
// const CallsPage = lazy(() => import('../../pages/calls/calls.page'))

// const StatisticsPage = lazy(() => import('../../pages/statistics/statistics.page'))


// const PricePage = lazy(() => import('../../pages/price/price.page'))

const StatisticsPage = lazy(() => lazyRetry( () => import('../../pages/statistics/statistics.page')))
const DeptPage = lazy(() => lazyRetry( () => import('../../pages/dept/dept.page')))
const PatientsPage = lazy(() => lazyRetry( () => import('../../pages/patients/patients.page')))
const TablePage = lazy(() => lazyRetry( () => import('../../pages/table/table.page')))
const PatientCardPage = lazy(() => lazyRetry( () => import('../../pages/patients/patient-card.page')))
const UserProfilePage = lazy(() => lazyRetry( () => import('../../pages/user-profile/user-profile.page')))
const CallsPage = lazy(() => lazyRetry( () => import('../../pages/calls/calls.page')))
const PricePage = lazy(() => lazyRetry( () => import('../../pages/price/price.page')))
const PatientsStatistics = lazy(() => lazyRetry( () => import('../../pages/statistics-patients/patients-statistics.page.jsx')))



export const HOME_ROUTE = '/'
export const AUTH_ROUTE = '/auth'
export const ADMIN_ROUTE = '/admin'
export const PROFILE_ROUTE = '/profile'
export const PATIENTS_ROUTE = '/patients'
export const PATIENT_CARD_ROUTE = '/patients/:id'
export const DEPT_ROUTE = '/dept'
export const TABLE_ROUTE = '/table'
export const CALLS_ROUTE = '/calls'
export const STATISTICS_ROUTE = '/statistics'
export const PRICE_ROUTE = '/price'
export const SUCCESS_PAYMENT_ROUTE = '/success-payment'
export const ERROR_PAYMENT_ROUTE = '/error-payment'
export const ADVANCE_PAYMENT_AGREEMENT = '/payment-agreement/:prepayment_id'
export const DEBT_PREPAID = '/debt_prepaid'
export const PATIENTS_STATISTICS = '/statistics/patients-statistics'

export const indexElement = <RequireAuth><SchedulePage/></RequireAuth>

export const privateRoutes = [
  { path: ADMIN_ROUTE, Component: <RequireAuth><ControlPanelPage/></RequireAuth> },
  { path: PROFILE_ROUTE, Component: <RequireAuth><UserProfilePage/></RequireAuth> },
  { path: PATIENTS_ROUTE, Component: <RequireAuth><PatientsPage/></RequireAuth> },
  { path: PATIENT_CARD_ROUTE, Component: <RequireAuth><PatientCardPage/></RequireAuth> },
  { path: DEPT_ROUTE, Component: <RequireAuth><DeptPage/></RequireAuth> },
  { path: TABLE_ROUTE, Component: <RequireAuth><TablePage/></RequireAuth> },
  { path: CALLS_ROUTE, Component: <RequireAuth><CallsPage/></RequireAuth> },
  { path: STATISTICS_ROUTE, Component: <RequireAuth><StatisticsPage/></RequireAuth> },
  { path: PRICE_ROUTE, Component: <RequireAuth><PricePage/></RequireAuth> },
  { path: DEBT_PREPAID, Component: <RequireAuth><DebtPrepaidPage/></RequireAuth> },
  { path: PATIENTS_STATISTICS, Component: <RequireAuth><PatientsStatistics/></RequireAuth> },
  { path: HOME_ROUTE, Component: <RequireAuth><SchedulePage/></RequireAuth> },

]

export const publicRoutes = [
  { path: AUTH_ROUTE, Component: <AuthPage/> },
  { path: SUCCESS_PAYMENT_ROUTE, Component: <SuccessPaymentPage/>},
  { path: ERROR_PAYMENT_ROUTE, Component: <ErrorPaymentPage/> },
  { path: ADVANCE_PAYMENT_AGREEMENT, Component: <AdvancePaymentAgreement/>},
  { path: '*', Component: <FourHundredFour/>}
]