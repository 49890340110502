import { makeAutoObservable, toJS } from "mobx";
import { client } from "../../../_common/http/appoloClient"
import { SEND_CHAT_MESSAGE } from "../../../_components/Chat/_mutations/chatMessages.mutation"
import { CHANGE_TABLE } from "../_mutations/tableModal.mutations"
import { inject } from "react-ioc"
import StoreChatModal from "../../../_components/Chat/stores/storeChat"
import TableViewStore from "./table.view-store"

export default class StoreTableModalDayList {

  tableViewStore = inject(this, TableViewStore);

  administrators = []
  specialists = []
  medicalCentars = []

  changedUsers = []
  addedUsers = []
  deletedUsers = []

  setChangedUsers(id) {
    var existChange = this.changedUsers.map((item) => {
      return item.id
    }).indexOf(id) + 1
    this.administrators.forEach((item) => {
      if (item.id === id && item.newWrite === false) {
        if (existChange > 0) {
          this.changedUsers[existChange - 1] = item
        } else {
          this.changedUsers.push(item)
        }
      }
      if (item.id === id && item.newWrite === true) {
        this.addedUsers.forEach((addedUser) => {
          if (addedUser.id === id) {
          }
        })
      }
    })
    this.specialists.forEach((item) => {
      if (item.id === id && item.newWrite === false) {
        if (existChange > 0) {
          this.changedUsers[existChange - 1] = item
        } else {
          this.changedUsers.push(item)
        }
      }
      if (item.id === id && item.newWrite === true) {
        this.addedUsers.forEach((addedUser) => {
          if (addedUser.id === id) {
          }
        })
      }
    })
  }

  setAddedUsers(id) {
    this.administrators.forEach((item) => {
      if (item.id === id) {
        this.addedUsers.push(item)
      }
    })
    this.specialists.forEach((item) => {
      if (item.id === id) {
        this.addedUsers.push(item)
      }
    })
  }

  setDeletedUsers(id) {
    this.administrators.forEach((item) => {
      if (item.id === id && item.newWrite === false) {
        this.deletedUsers.push(parseInt(item.id, 10))
      }
    })
    this.specialists.forEach((item) => {
      if (item.id === id && item.newWrite === false) {
        this.deletedUsers.push(parseInt(item.id, 10))
      }
    })
  }

  setMedicalCenters(data) {
    this.medicalCentars = data
  }

  setSortAdmins() {
    function fullName(lastName, firstName, patronymic) {
      var patronymicExist = patronymic && " " + patronymic
      if (patronymicExist === null) {
        patronymicExist = ''
      }
      return lastName + " " + firstName + patronymicExist
    }

    this.administrators.slice().sort((a, b) => (fullName(a.specialist.lastName, a.specialist.firstName, a.specialist.patronymic) > fullName(b.specialist.lastName, b.specialist.firstName, b.specialist.patronymic)) ? 1 : (fullName(b.specialist.lastName, b.specialist.firstName, b.specialist.patronymic) > fullName(a.specialist.lastName, a.specialist.firstName, a.specialist.patronymic)) ? -1 : 0)
  }

  setSortSpecialists() {
    function fullName(lastName, firstName, patronymic) {
      var patronymicExist = patronymic && " " + patronymic
      if (patronymicExist === null) {
        patronymicExist = ''
      }
      return lastName + " " + firstName + patronymicExist
    }

    this.specialists.slice().sort((a, b) => (fullName(a.specialist.lastName, a.specialist.firstName, a.specialist.patronymic) > fullName(b.specialist.lastName, b.specialist.firstName, b.specialist.patronymic)) ? 1 : (fullName(b.specialist.lastName, b.specialist.firstName, b.specialist.patronymic) > fullName(a.specialist.lastName, a.specialist.firstName, a.specialist.patronymic)) ? -1 : 0)
  }

  setAdministrators(data) {
    this.administrators = data
  }

  setSpecialists(data) {
    this.specialists = data
  }

  setNewId() {
    var adminsIds = []
    var spesialistIds = []
    if (this.administrators.length > 0) {
      adminsIds = this.administrators.map((item) => {
        return +item.id
      })
    }
    if (this.specialists.length > 0) {
      spesialistIds = this.specialists.map((item) => {
        return +item.id
      })
    }
    var ids = [...adminsIds, ...spesialistIds]
    if (adminsIds.length === 0 && spesialistIds.length === 0) {
      return 1
    }
    return (Math.max.apply(null, ids) + 1)
  }

  setDublicateAdministrator(id) {
    var newWrite = {}
    this.administrators.forEach((item) => {
      if (item.id === id) {
        newWrite = Object.assign({}, item)
      }
    })
    newWrite.id = this.setNewId()
    newWrite.newWrite = true
    this.administrators.push(newWrite)
    this.setSortAdmins()
    this.setAddedUsers(newWrite.id)
  }

  setDublicateSpecialist(id) {
    var newWrite = {}
    this.specialists.forEach((item) => {
      if (item.id === id) {
        newWrite = Object.assign({}, item)
      }
    })
    newWrite.id = this.setNewId()
    newWrite.newWrite = true
    this.specialists.push(newWrite)
    this.setSortSpecialists()
    this.setAddedUsers(newWrite.id)
  }

  setAddAdministrator(data) {
    this.administrators.push(data)
    this.setAddedUsers(data.id)
  }

  setAddSpecialist(data) {
    this.specialists.push(data)
    this.setAddedUsers(data.id)
  }

  setChangeUserTimeStart(time, id, type) {
    if (type === "admin") {
      var indexAdmin = this.administrators.map(item => {
        return item.id;
      }).indexOf(id)
      if (indexAdmin !== null) {
        this.administrators[indexAdmin].timeStart = time
      }
    }
    if (type === "specialist") {
      var indexSpecialist = this.specialists.map(item => {
        return item.id;
      }).indexOf(id)
      if (indexSpecialist !== null) {
        this.specialists[indexSpecialist].timeStart = time
      }
    }
  }

  setChangeUserTimeEnd(time, id, type) {
    if (type === "admin") {
      var indexAdmin = this.administrators.map(item => {
        return item.id;
      }).indexOf(id)
      if (indexAdmin !== null) {
        this.administrators[indexAdmin].timeEnd = time
      }
    }
    if (type === "specialist") {
      var indexSpecialist = this.specialists.map(item => {
        return item.id;
      }).indexOf(id)
      if (indexSpecialist !== null) {
        this.specialists[indexSpecialist].timeEnd = time
      }
    }
  }

  setDeleteAdministrator(id) {
    var index = this.administrators.map(item => {
      return item.id;
    }).indexOf(id);
    if (this.administrators[index].newWrite === true) {
      var indexDeleteFromNew = this.addedUsers.map(item => {
        return item.id;
      }).indexOf(id);
      this.addedUsers.splice(indexDeleteFromNew, 1)
    }
    this.administrators.splice(index, 1)
  }

  setDeleteSpecialist(id) {
    var index = this.specialists.map(item => {
      return item.id;
    }).indexOf(id);
    this.specialists.splice(index, 1)
    if (this.specialists[index]?.newWrite === true) {
      var indexDeleteFromNew = this.addedUsers.map(item => {
        return item.id;
      }).indexOf(id);
      this.addedUsers.splice(indexDeleteFromNew, 1)
    }
  }

  setCenterAdministrator(center, index) {
    this.administrators[index].medicalCenter = center
  }

  setCenterSpecialist(center, index) {
    this.specialists[index].medicalCenter = center
  }

  setNewUsersWrites() {
    this.administrators.forEach((item) => {
      item.newWrite = false
    })
    this.specialists.forEach((item) => {
      item.newWrite = false
    })
  }

  get administratorsGet() {
    return this.administrators
  }

  get specialistsGet() {
    return this.specialists
  }

  get medicalCentersGet() {
    return this.medicalCentars
  }


  get changedUsersGet() {
    return this.changedUsers
  }

  get addedUsersGet() {
    return this.addedUsers
  }

  get deletedUsersGet() {
    return this.deletedUsers
  }


  constructor() {
    makeAutoObservable(this)
  }


  addOrEditTableTime() {
    return new Promise((resolve, reject)=>{
    const deleteWrite = this.deletedUsers
    const updateWrite = []
    this.changedUsers.forEach((item) => {
      updateWrite.push(
        {
          dayTableId: +item.id,
          specialistId: +item.specialist.id,
          centerId: item.medicalCenter.id,
          date: item.date,
          timeStart: item.timeStart,
          timeEnd: item.timeEnd,
          role: item.role
        }
      )
    })
    const addedWrite = []
    this.addedUsers.forEach((item) => {
      addedWrite.push(
        {
          specialistId: +item.specialist.id,
          centerId: item.medicalCenter.id,
          date: item.date,
          timeStart: item.timeStart,
          timeEnd: item.timeEnd,
          role: item.role
        }
      )
    })
    client.mutate({
      mutation: CHANGE_TABLE,
      variables: {
        dayTableIdList: deleteWrite,
        updateInput: updateWrite,
        createInput: addedWrite,
      }
    }).catch((error) => {
      if (error?.message === "Существуют активные записи в расписании. Удаление невозможно!") {
        alert("Существуют активные записи в расписании. Удаление невозможно!")
      } else if (error?.message === "У выбранного специалиста занято указанное время!") {
        alert("У выбранного специалиста занято указанное время!")
      } else alert("Возникла ошибка при изменении данных")
      reject(error)
    }).then((data) => {
      if (data?.data){
        resolve(true)
      }
      this.tableViewStore.setRefetching(true)
      reject(false)
    })
  })
}

}