import React from 'react';
import { Box, Fab, Link, Typography } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"
import { PATIENTS_ROUTE } from "../../../_common/router/routes"
import CallIcon from '@mui/icons-material/Call';
import { useInstance } from "react-ioc"
import CallsBannerStore from "../../Layouts/auth-layout/stores/calls.store"
import { observer } from "mobx-react-lite"
import CloseIcon from '@mui/icons-material/Close';
import { fullNameString } from "../../../_common/helpers/nameGenerationString"


const CallsBannerItemOutgoing = observer(({
  banners,
  outgoing,
  showCallsOutgoing,
  setShowCallsOutgoing,
  setShowCalls,
  setCallDirectionCorrectName
}) => {

  const store = useInstance(CallsBannerStore)

  const callName = (status, direction) => {
    if (["NOT_ACCEPTED", "TALKING_END"].includes(status)) {
      if (status === "NOT_ACCEPTED") {
        return "Пропущенный звонок"
      } else return "Завершенный звонок"
    } else {
      return setCallDirectionCorrectName(direction)
    }
  }


  return (
    <>
      {banners.length === 1 && store.currentCall === null && <Box sx={{
        position: 'fixed',
        bottom: (theme) => theme.spacing(2),
        // right: (theme) => theme.spacing(2),
        display: "flex",
        alignItems: outgoing ? undefined : "flex-end",
        flexDirection: "column",
        gap: 2,
        zIndex: 2000,
        right: outgoing ? "370px" : "5px",
      }}>

        <Box
          sx={{
            width: "fit-content",
            bgcolor: banners[0]?.lazyRemoove ? "primary.deepred" : "#1773DF",
            opacity: banners[0]?.lazyRemoove ? 0 : 1,
            transition: "opacity 10s ease-in-out",
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px",
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "space-between",
            padding: "6px 16px",
            borderRadius: "4px",
            flexGrow: 1,
            color: "rgb(255, 255, 255)",
          }}>


            <Box sx={{display: "flex", alignItems: "baseline", gap: 1}}>

              {!!banners[0].patient?.isPrepaidService && <Box
                sx={{
                  width: 16,
                  height: 16,
                  color: "primary.white",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  bgcolor: "primary.lightred",
                  ml: 0.5,
                  border: "1px solid",
                  borderColor: "primary.white"
                }}
              >
                <Typography variant="button" sx={{fontSize: 12}}>
                  {'\u20BD'}
                </Typography>
              </Box>}

              <Box sx={{
                display: "flex",
                flexDirection: "column",
              }}>
                {callName(banners[0].status, banners[0].direction)}
                {banners[0].patient !== null &&
                  <Link underline="hover" component={RouterLink} sx={{color: "rgb(255, 255, 255)",}}
                        to={`${PATIENTS_ROUTE}/${banners[0].patient?.id}`}>
                    {fullNameString(banners[0].patient?.lastName,banners[0].patient?.firstName,banners[0].patient?.patronymic)}
                  </Link>}
                {banners[0].patient === null && <Box>{banners[0].caller}</Box>}
              </Box>
            </Box>

          <Box>
            <CallIcon />
          </Box>
        </Box>

      </Box>}

      {store.currentCall != null && store.currentCall?.direction === "outgoing" && <Box sx={{
        position: 'fixed',
        bottom: (theme) => theme.spacing(2),
        display: "flex",
        alignItems: outgoing ? undefined : "flex-end",
        flexDirection: "column",
        right: outgoing ? "515px" : "5px",
        gap: 2,
        zIndex: 2000,
      }}>

        <Box
          sx={{
            width: "fit-content",
            background: "#1773DF",
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px",
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "space-between",
            padding: "6px 16px",
            borderRadius: "4px",
            flexGrow: 1,
            color: "rgb(255, 255, 255)",

          }}>

          <Box sx={{display: "flex", alignItems: "baseline", gap: 1}}>

            {!!store.currentCall.patient?.isPrepaidService && <Box
              sx={{
                width: 16,
                height: 16,
                // position: "absolute",
                color: "primary.white",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "primary.lightred",
                ml: 0.5,
                border: "1px solid",
                borderColor: "primary.white"
              }}
            >
              <Typography variant="button" sx={{fontSize: 12}}>
                {'\u20BD'}
              </Typography>
            </Box>}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // paddingLeft: "10px",
              }}
            >
              {"Идет разговор"}
              {store.currentCall?.patient !== null &&
                <Link underline="hover" component={RouterLink} sx={{color: "rgb(255, 255, 255)",}}
                      to={`${PATIENTS_ROUTE}/${store.currentCall.patient?.id}`}>
                  {fullNameString(store.currentCall.patient?.lastName, store.currentCall.patient?.firstName, store.currentCall.patient?.patronymic)}
                </Link>}
              {store.currentCall.patient === null && <Box>{store.currentCall.caller}</Box>}
            </Box>
          </Box>

          <Box>
            <CallIcon />
          </Box>
        </Box>
      </Box>}


        {(banners.length > 1 || (banners.length === 1 && store.currentCall != null)) &&
          <Fab
            onClick={() => {
              setShowCallsOutgoing(!showCallsOutgoing)
              setShowCalls(false)
            }}
            sx={{
              background: outgoing ? "#1773DF" : "rgb(16, 185, 129)",
              color: "#fff",
              "&:hover": {
                backgroundColor: outgoing ? "#1773DF" : "rgb(16, 185, 129)",
              },
              position: 'fixed',
              bottom: (theme) => theme.spacing(2),
              right: outgoing ? "450px" : (theme) => theme.spacing(2),
              zIndex: 2000,
            }}
          >
            {showCallsOutgoing ? <CloseIcon /> : banners.length}
          </Fab>
        }


        {showCallsOutgoing && (banners.length > 1 || (banners.length === 1 && store.currentCall != null)) && <Box sx={{
          position: 'fixed',
          bottom: (theme) => theme.spacing(10),
          right: outgoing ? "450px" : (theme) => theme.spacing(2),
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "column",
          gap: 2,
          zIndex: 2000,
        }}>
          {
            banners.map((call) => {

            return (
              <Box
                key={call.id}
                sx={{
                  width: "fit-content",
                  bgcolor: call?.lazyRemoove ? "primary.deepred" : "#1773DF",
                  opacity: banners[0]?.lazyRemoove ? 0 : 1,
                  transition: "opacity 10s ease-in-out",
                  boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px",
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "6px 16px",
                  borderRadius: "4px",
                  flexGrow: 1,
                  color: "rgb(255, 255, 255)",
                }}>

                <Box sx={{display: "flex", alignItems: "baseline", gap: 1}}>

                  {!!call.patient?.isPrepaidService && <Box
                    sx={{
                      width: 16,
                      height: 16,
                      color: "primary.white",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      bgcolor: "primary.lightred",
                      ml: 0.5,
                      border: "1px solid",
                      borderColor: "primary.white"
                    }}
                  >
                    <Typography variant="button" sx={{fontSize: 12}}>
                      {'\u20BD'}
                    </Typography>
                  </Box>}

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {callName(call.status, call.direction)}
                    {call.patient !== null &&
                      <Link underline="hover" component={RouterLink} sx={{color: "rgb(255, 255, 255)",}}
                            to={`${PATIENTS_ROUTE}/${call.patient?.id}`}>
                        {fullNameString(call.patient?.lastName, call.patient?.firstName,call.patient?.patronymic)}
                      </Link>}
                    {call.patient === null && <Box>{call.caller}</Box>}
                  </Box>
                </Box>

                <Box>
                  <CallIcon />
                </Box>

              </Box>
            )
          })
        }
      </Box>}
    </>
  );
});

export default CallsBannerItemOutgoing;