

//функция для сортировки массивов из стора. Сортировка выполняется по фамилии сотрудников

export function sortArrayOfSpecByAlphabetWithStore(arr) {
  if (!Array.isArray(arr)) {
    console.log('Параметр должен быть массивом!');
    return [];
  }

  const sortedArray = arr.slice(0).sort((a, b) => {
    const lastNameA = a.lastName.toLowerCase();
    const lastNameB = b.lastName.toLowerCase();

    if (lastNameA < lastNameB) {
      return -1;
    }
    if (lastNameA > lastNameB) {
      return 1;
    }
    return 0;
  });

  return sortedArray;
}