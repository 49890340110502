import React from 'react';
import { observer } from "mobx-react-lite";
import { useInstance } from "react-ioc";
import {
    FormControl,
    Grid, InputLabel, MenuItem, Select,
    TextField,
    Typography,
    Switch,
    FormHelperText,
    Box
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import moment from "moment";
import { SurnameAndInitialsString } from '../../../../../_common/helpers/nameGenerationString';
import ChangeWriteDataStore from '../store/ChangeWriteData.data.store';
import ChangeWriteViewStore from '../store/ChangeWriteView.view.store';
import { styled } from '@mui/material/styles';
import { sortArrayOfSpecByAlphabetWithStore } from "../../../../../_common/helpers/sortArrayOfSpecByAlphabet"

const CustomTextField = styled(TextField)({
    '& textarea': {
        padding: 16
    },
    '.MuiInputBase-multiline': {
        padding: 0
    }
});


const CommonInfo = (observer(() => {
    const viewStore = useInstance(ChangeWriteViewStore)
    const store = useInstance(ChangeWriteDataStore)

    const handleChangeSpecialist = (newSpecialist) => {
        store.setSpecialistId(newSpecialist)
    }

    const handleChangeCenter = (newCenter) => {
        store.setCenterId(newCenter)
    }

    const handleChangeDate = (newDate) => {
        store.setDate(moment(newDate).format("YYYY-MM-DD"))
    }

    const handleChangeTimeStart = (newTime) => {
        store.setTimeStart(newTime)
    }

    const handleChangeTimeEnd = (newTime) => {
        store.setTimeEnd(newTime)
    }

    const handleChangeComment = (newText) => {
        store.setComment(newText)
    }

    const handleChangeStatus = (newStatus) => {
        store.setStatusIs(newStatus)
    }

    const handleChangeStudent = () => {
        store.switchIsStudent()
    }

    const isCameStatus = () => {
        if (store.writeData?.status?.id === "4" || store.writeData?.status?.name === "Пришел") {
            return true
        } else return false
    }

    const disableSpecialist = () => {
        if (isCameStatus() || store.specialists.length === 0) {
            return true
        } else return false
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                <FormControl fullWidth focused disabled={isCameStatus()}>
                    <InputLabel id="specialist-write-select-label">Специалист</InputLabel>
                    <Select
                        color={""}
                        value={store.specialistId || ''}
                        onChange={(e) => handleChangeSpecialist(e.target.value)}
                        labelId="specialist-write-select-label"
                        id="specialist-write-select"
                        label="Специалист"
                        size="small"
                    >
                        {store.specialists.length > 0 && sortArrayOfSpecByAlphabetWithStore(store.specialists).map((specialist) => {
                            return (
                                <MenuItem key={specialist.id} value={specialist.id}>
                                    {SurnameAndInitialsString(specialist.lastName, specialist.firstName, specialist.patronymic)}
                                </MenuItem>
                            )
                        })}
                    </Select>
                    {store.specialists.length === 0 && <FormHelperText>специалисты отсутствуют</FormHelperText>}
                    {(!store.specialistId || store.specialistId === "") && <FormHelperText>выберите специалиста</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ mt: 2 }}>
                <FormControl fullWidth focused disabled={isCameStatus()}>
                    <InputLabel id="center-write-select-label">Центр</InputLabel>
                    <Select
                        value={store.centerId && store.centerId}
                        onChange={(e) => handleChangeCenter(e.target.value)}
                        labelId="center-write-select-label"
                        id="center-write-select"
                        label="Центр"
                        size="small"
                    >
                        {store.centers.length > 0 && store.centers.map((center) => {
                            return (
                                <MenuItem key={center.id} value={center.id}>
                                    {center.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6} sm={4} sx={{ mt: 2 }}>
                <FormControl fullWidth focused>
                    <MobileDatePicker
                        label="Дата"
                        inputFormat="DD.MM.yyyy"
                        value={store.date && store.date}
                        onChange={(newValue) => handleChangeDate(newValue)}
                        renderInput={(params) => <TextField {...params} size="small" focused />}
                        disabled={isCameStatus()}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6} sm={4} sx={{ mt: 2 }}>
                <FormControl fullWidth focused>
                    <TextField
                        size="small"
                        focused
                        id="timeStart"
                        label="от"
                        type="time"
                        value={store.timeStart && store.timeStart}
                        onChange={(newValue) => {
                            handleChangeTimeStart(newValue.target.value);
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300,
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6} sm={4} sx={{ mt: 2 }}>
                <FormControl fullWidth focused>
                    <TextField
                        size="small"
                        focused
                        id="timeEnd"
                        label="до"
                        type="time"
                        value={store.timeEnd && store.timeEnd}
                        onChange={(newValue) => {
                            handleChangeTimeEnd(newValue.target.value);
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300,
                        }}
                    />
                </FormControl>
            </Grid>
            {isCameStatus() &&
                <Box sx={{ ml: 2 }}>
                    <Typography variant="caption">
                        *Статус записи сохранен как "Пришел". Невозможно поменять дату, специалиста, центр пока не будет сохранен другой статус.
                    </Typography>
                </Box>
            }
            <Grid item xs={12} sx={{ mt: 2 }}>
                <CustomTextField
                    label={viewStore.isInfo === true ? "Комментарий" : "Причина обращения"}
                    value={store.comment && store.comment}
                    size="small"
                    focused
                    fullWidth
                    inputProps={{ maxLength: 300 }}
                    multiline
                    minRows={2}
                    maxRows={4}
                    onChange={(e) => handleChangeComment(e.target.value)}
                />
            </Grid>
            {store.writeData?.isInfo === false &&
                <>
                    <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                        <FormControl fullWidth focused sx={{ mt: 1 }}>
                            <InputLabel id="status-write-select-label">Статус записи</InputLabel>
                            <Select
                                value={store.statusIs && store.statusIs}
                                onChange={(e) => handleChangeStatus(e.target.value)}
                                labelId="status-write-select-label"
                                id="status-write-select"
                                label="Статус записи"
                                size="small"
                            >
                                {store.statuses.length > 0 && store.statuses.map((status) => {
                                    return (
                                        <MenuItem key={status.id} value={status.id}>
                                            {status.name}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth focused sx={{ pb: 1, display: "flex", flexDirection: "row", alignItems: "end", height: "100%" }}>
                            <label htmlFor="student-write-switch">
                                <Switch size="small" checked={store.isStudent && store.isStudent} id="student-write-switch" onChange={handleChangeStudent} />
                                <Typography sx={{ display: "inline" }} variant={"body2"}>Ученическая запись</Typography>
                            </label>
                        </FormControl>
                    </Grid>
                </>
            }

        </Grid>
    );
}));

export default CommonInfo;

