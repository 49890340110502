import React from 'react';
import { observer } from "mobx-react-lite";
import { useInstance } from "react-ioc";
import {
    Box,
    Typography,
} from "@mui/material";
import ChangeWriteDataStore from '../store/ChangeWriteData.data.store';
import { Link as RouterLink, useLocation } from "react-router-dom";
import { PATIENTS_ROUTE } from "../../../../../_common/router/routes";
import { fullNameString } from '../../../../../_common/helpers/nameGenerationString';
import moment from "moment"
import ButtonMain from '../../../../../_components/UI/Buttons/ButtonMain';
import PrePaymentDataStore from '../../../stores/prePayment.data.store';
import StoreBackToCallsSchedule from "../../../../../_components/Layouts/auth-layout/stores/backToCallsSchedule.store"
import CallBtnPopover from './CallBtnPopover';


const PatientBlock = (observer(() => {
    const dataStore = useInstance(ChangeWriteDataStore)
    const prePaymentDataStore = useInstance(PrePaymentDataStore)
    const storeBackToCallsSchedule = useInstance(StoreBackToCallsSchedule)


    // let defaultPhone = dataStore?.writeData?.patient?.phones.filter((phone)=> phone?.isDefault && phone?.ruNumber)
    // let phoneCallPermission = defaultPhone

    const handlePrePaymentClick = () => {
        prePaymentDataStore.setOpenModal(true)
        prePaymentDataStore.setPatientId(dataStore?.writeData?.patient?.id)
        prePaymentDataStore.setScheduleEntryId(dataStore?.writeData?.id)
        prePaymentDataStore.setSpecialist(dataStore?.specialistId)
        prePaymentDataStore.setCenter(dataStore?.centerId)
    }

    // const handleCall = () => {
    //     startPhoneCall(authStore.currentMedicalCenterPhone, defaultPhone[0].id, "id")
    // }

    const { pathname } = useLocation();


    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box sx={{ mb: 1, p: 1, borderRadius: 1, display: "flex", justifyContent: 'space-between', alignItems: "center", bgcolor: "primary.light3" }}>
                <Box>
                    <Typography sx={{ color: "primary.main" }}
                        component={RouterLink}
                        to={`${PATIENTS_ROUTE}/${dataStore?.writeData?.patient?.id}`}
                        state={{ previousPath: pathname }}
                        onClick={() => {
                            storeBackToCallsSchedule.setRedirectFromPatient(true)
                        }}
                    >
                        {fullNameString(dataStore?.writeData?.patient?.lastName, dataStore?.writeData?.patient?.firstName, dataStore?.writeData?.patient?.patronymic)}
                    </Typography>
                    <Typography>
                        Возраст: {dataStore?.writeData?.patient?.age && dataStore?.writeData?.patient?.age} {dataStore?.writeData?.patient?.birthday && moment(dataStore?.writeData?.patient?.birthday).format("DD.MM.YYYY")}
                    </Typography>
                    {dataStore?.writeData?.patient?.isPrepaidService &&
                        <Typography>
                            По предоплате
                        </Typography>
                    }
                </Box>
                <CallBtnPopover />
            </Box>
            <ButtonMain
                onClick={handlePrePaymentClick}
                title={"Добавить предоплату"}
            // variant='text'
            />
        </Box>
    );
}));

export default PatientBlock;

