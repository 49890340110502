import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import ChangeWriteViewStore from '../../store/ChangeWriteView.view.store';
import { useInstance } from "react-ioc";
import { RECORD } from '../../../../queries/modal.queries';
import { UPDATE_SHEDULE } from '../../../../mutations/modal.mutations';
import { useMutation, useQuery } from "@apollo/client";
import ChangeWriteDataStore from '../../store/ChangeWriteData.data.store';
import { TextField, Typography, Box, Tooltip } from '@mui/material';

export default function CreateDelayModal() {
    const viewStore = useInstance(ChangeWriteViewStore)
    const dataStore = useInstance(ChangeWriteDataStore)

    const [latness, setLateness] = useState("")


    const { data } = useQuery(RECORD, {
        variables: {
            scheduleId: viewStore.infoFromShedule,
        }
    })

    const [addData, { data: updData, errors: updErrors }] = useMutation(UPDATE_SHEDULE);

    const handleClose = () => {
        viewStore.setOpenCreateLatness(false)
        dataStore.getWriteDataById(dataStore.writeData.id)
    };

    const handleSaveLatness = () => {
        addData({
            variables: {
                "input": {
                    dayTableId: data.scheduleInfo.dayTable.id,
                    scheduleEntryId: data.scheduleInfo.id,
                    statusId: data.scheduleInfo.status.id,
                    specialistId: data.scheduleInfo.specialist.id,
                    date: data.scheduleInfo.date,
                    timeStart: data.scheduleInfo.timeStart,
                    timeEnd: data.scheduleInfo.timeEnd,
                    delay: latness || 0
                }
            }
        }).catch((error) => {
            if (error) alert("Опоздание не было сохранено")
        }).then(() => {
            handleClose()
        })

    }

    const handleChangeLatness = (value) => {
        const inputValue = value
        if (inputValue === "" || (/^\d+$/.test(inputValue) && Number(inputValue) <= 120)) {
            setLateness(inputValue);
        }
    }

    return (
        <Dialog
            open={viewStore.openCreateLatness}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Опоздание
            </DialogTitle>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3, alignItems: "center", m: 2 }}>
                <Typography>
                    Задайте опоздание (не более 120 мин):
                </Typography>
                <TextField
                    label={"мин"}
                    size="small"
                    focused
                    value={latness}
                    onChange={(e) => handleChangeLatness(e.target.value)}
                    sx={{ width: 60 }}
                />
            </Box>
            <DialogActions>
                <Button onClick={handleClose}>Отмена</Button>
                <Tooltip title="Данные из редактирования записи не будут сохранены">
                    <Button onClick={handleSaveLatness} autoFocus>
                        Сохранить опоздание
                    </Button>
                </Tooltip>

            </DialogActions>
        </Dialog>
    );
}