export function handleCopyToClipBoard(copyValue) {
    if (navigator?.clipboard) {
        navigator.clipboard.writeText(copyValue)
    } else {
        const textField = document.createElement('textarea')
        textField.innerText = copyValue
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }
    return true
}