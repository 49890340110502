import React, {useEffect} from 'react';
import {useLocation, Navigate, useParams} from 'react-router-dom';
import authStore from "../stores/auth.store";
import {AUTH_ROUTE, DOUBLE_PAGE} from "./routes";
import {observer} from "mobx-react-lite";

const RequireAuth = observer(({children}) => {
  const location = useLocation();
  // const params = useParams();
  //
  // useEffect(() => {
  //
  //   // Если это страница карты пациента.
  //   // if (location.pathname.indexOf('/patients/') === 0) {
  //   //   let page_storage = localStorage.getItem(`page_patient_card_ls${params.id}`)
  //   //   let page_session = sessionStorage.getItem(`page_patient_card_s${params.id}`)
  //   //   if (!page_storage && !page_session) {
  //   //     localStorage.setItem(`page_patient_card_ls${params.id}`, location.key)
  //   //     sessionStorage.setItem(`page_patient_card_s${params.id}`, location.key)
  //   //     //localStorage.setItem('now', Date.now());
  //   //   } else {
  //   //     console.log('page_storage', page_storage)
  //   //     console.log('page_session', page_session)
  //   //   }
  //
  //     // if (!page_storage && page_session) {
  //     //   localStorage.setItem(`page_patient_card_ls${params.id}`, location.key)
  //     // }
  //     //
  //
  //
  //     // if (page_storage && !page_session){authStore.setIsDoublePatientCardPage(true)}
  //
  //     // window.session = location.key
  //     // localStorage.setItem('session', window.session)
  //     //
  //     // var onStorage = function (e) {
  //     //   console.log(e)
  //     //   if (e.key === 'session' && e.newValue !== window.session) {
  //     //     localStorage.setItem("multitab", window.session)
  //     //   }
  //     //
  //     //   if (e.key === "multitab" && e.newValue !== window.session) {
  //     //     window.removeEventListener("storage", onStorage)
  //     //     localStorage.setItem("session", localStorage.getItem("multitab"))
  //     //     localStorage.removeItem("multitab")
  //     //     window.location.assign('/double-page');
  //     //     console.log('Новая вкладка')
  //     //     // alert("Новая вкладка")
  //     //   }
  //     // }
  //     // window.addEventListener('storage', onStorage);
  //
  //
  //   } else {
  //     authStore.setIsDoublePatientCardPage(false)
  //   }
  //
  // }, [location])

  if (!authStore.isAuth) {
    return <Navigate to={AUTH_ROUTE} state={{from: location}}/>
  }

  // sessionStorage.setItem(`session_${params.id}`, location.key)
  // localStorage.setItem(`session_${params.id}`, sessionStorage.getItem(`session_${params.id}`))
  //
  // var onStorage = function (e) {
  //   console.log(e)
  //   if (e.key === `session_${params.id}` && e.newValue !== sessionStorage.getItem(`session_${params.id}`)) {
  //     localStorage.setItem(`multitab_${params.id}`, sessionStorage.getItem(`session_${params.id}`))
  //   }
  //
  //   if (e.key === `multitab_${params.id}` && e.newValue && e.newValue !== sessionStorage.getItem(`session_${params.id}`)) {
  //     window.removeEventListener("storage", onStorage)
  //     localStorage.setItem(`session_${params.id}`, localStorage.getItem(`multitab_${params.id}`))
  //     localStorage.removeItem(`multitab_${params.id}`)
  //     console.log('Новая вкладка')
  //     // alert("Новая вкладка")
  //   }
  // }
  // window.addEventListener('storage', onStorage);



  // window.onstorage = event => { // можно также использовать window.addEventListener('storage', event => {
  //     if (event.key !== `page_patient_card_${params.id}`) return;
  //     // alert(event.key + ':' + event.newValue + " at " + event.url);
  //     console.log('event.key', event.key)
  //     console.log('event.newValue', event.newValue)
  //     console.log('event.url', event.url)
  //     console.log('StorageEvent', event)
  //     // return <Navigate to={DOUBLE_PAGE}/>
  //     // window.close()
  //     // let page_storage = localStorage.getItem(`page_patient_card_${params.id}`)
  //     // let page_session = sessionStorage.getItem(`page_patient_card_${params.id}`)
  //     // if (page_storage && page_session){
  //     //   window.location.assign('/double-page');
  //     // }
  //   };

  // if (authStore.isDoublePatientCardPage) {
  //   return <Navigate to={DOUBLE_PAGE}/>
  // }

  return children
});

export default RequireAuth;