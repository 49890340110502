import {gql} from "@apollo/client";

export const USER_PROFILE = gql`
  query getUserProfile($email: String) {
    userProfile(email: $email) {
      id
      email
      firstName
      lastName
      patronymic
      birthday
      sip
      isSpecialist
      
      position {
        id
        name
      }
      
      socialNetworks {
        id
        nickname
        
        networkType {
        id
        type
        }
      }
    }
  }
`

export const USER_POSITIONS = gql`
  query positions{
  positions {
    id
    name
  }
}
`
export const USER_NETWORKS = gql`
  query socialNetworks {
  allSocialNetworks {
    id
    type
  }
}
`



