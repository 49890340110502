import {gql} from "@apollo/client";


export const GET_USERS = gql`
query allUsers{
  allUsers {
    id
    email
  }
}
`

export const GET_ALL_TEMPLATES = gql`
query allTemplates{
  allTemplates{
    id
    text
  }
}
`

export const SPEC_DAY = gql`
query SPEC_DAY($centerId: ID!, $dateStart: Date, $dateEnd: Date, $isActive: Boolean){
  table(centerId: $centerId, dateStart: $dateStart, dateEnd: $dateEnd, isActive: $isActive){
    specialist{
      id
      firstName
      lastName
      isActive
      email
      patronymic
    }
  }
}
`

export const USER_PROFILE_ID = gql`
query userId($email: String){
  userProfile(email: $email){
    id
  }
}
`