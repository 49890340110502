import React from 'react';
import { observer } from "mobx-react-lite";
import { useInstance } from "react-ioc";
import {
    Box,
    Typography,
} from "@mui/material";
import ChangeWriteDataStore from '../store/ChangeWriteData.data.store';
import { SurnameAndInitialsString } from '../../../../../_common/helpers/nameGenerationString';
import moment from "moment"
import { styled } from "@mui/material/styles";
import SheduleCalendarStore from '../../calendar/store/sheduleCalendar.store';

const StyledBoxRow = styled(Box)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.primary.light3,
    },
    '&:hover': {
        backgroundColor: theme.palette.primary.light2,
    }
}));


const PreviousWrites = (observer(() => {
    const dataStore = useInstance(ChangeWriteDataStore)
    const sheduleCalendarStore = useInstance(SheduleCalendarStore)

    const openPreviousWrite = (writeId, date) => {
        //set new date to calendar
        sheduleCalendarStore.setCurrentDate(date)
        dataStore.setWriteId(writeId)
    }

    const circleGen = (isActive, delay, isStudent, status) => {
        let circleColor = ''
        if (!isActive) circleColor = "primary.violetGrey"
        if (isStudent && isActive) {
            if (status.name === "Новая запись") circleColor = "primary.light4";
            if (status.name === "Подтвержденная запись") circleColor = "primary.blue";
            if (status.name === "Не пришел") circleColor = "primary.lightred2";
            if (status.name === "Пришел") circleColor = "primary.green";
        } else if (isActive && !isStudent) {
            if (status.name === "Новая запись") circleColor = "primary.light4";
            if (status.name === "Подтвержденная запись") circleColor = "primary.blue";
            if (status.name === "Не пришел") circleColor = "primary.lightred2";
            if (status.name === "Пришел") circleColor = "primary.green";
        }
        return (
            <>
                {delay && delay != "0" ?
                    <Box sx={{
                        backgroundColor: "primary.yellow",
                        width: 15,
                        height: 15,
                        borderRadius: '50%',
                        border: 1
                    }}>{''}</Box>
                    :
                    ""
                }
                <Box sx={{
                    backgroundColor: circleColor,
                    width: 15,
                    height: 15,
                    borderRadius: '50%',
                    border: 1
                }}>{''}</Box>
            </>

        )
    }

    const rows = (write) => {
        return (
            <StyledBoxRow key={write.id}
                onClick={() => openPreviousWrite(write.id, write.date)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: "100%"
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        flex: 1,
                        overflow: "hidden"
                    }}
                >
                    <Box sx={{ mr: 0.5, display:'flex', width: { xs: "auto" }, flexDirection: {xs: "column", sm: "row"} }}>
                        <Typography variant="caption" sx={{ mr: 0.5 }}>
                            {moment(write.date).format("DD.MM.YYYY")}
                        </Typography>
                        <Typography variant="caption">
                            {write.timeStart.slice(0, 5)}-{write.timeEnd.slice(0, 5)}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: "block", sm: 'flex' },
                            alignItems: 'center',
                            justifyContent: 'start',
                            overflow: "hidden",
                            flex: 1,
                        }}
                    >
                        <Box sx={{ ml: {xs: 0.5, sm: 0}, width: { sx: "auto", sm: 80 }, maxWidth: { xs: "none", sm: 100 }, overflow: "hidden", whiteSpace: "nowrap" }}>
                            {write?.specialist ?
                            <Typography variant="caption" sx={{ whiteSpace: "nowrap" }}>
                                {SurnameAndInitialsString(write.specialist?.lastName, write.specialist?.firstName, write.specialist?.patronymic)}
                            </Typography>
                            :
                            <Typography variant="caption" sx={{ whiteSpace: "nowrap" }}>
                                Нет врача
                            </Typography>
                        }
                            
                        </Box>
                        <Box sx={{ ml: 0, width: { xs: "auto", sm: 200 }, maxWidth: { xs: "none", sm: 200 }, overflow: "hidden", whiteSpace: "nowrap" }}>
                            <Typography variant="caption">
                                {write.description}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {circleGen(write.isActive, write.delay, write.isStudent, write.status)}
            </StyledBoxRow>
        )

    }


    return (
        <Box sx={{ mt: 2, mb: 2 }}>
            <Typography>
                Предыдущие записи:
            </Typography>
            <Box sx={{ mt: 1, maxHeight: 120, overflow: 'scroll', border: 2, borderColor: 'primary.light2' }}>
                {dataStore?.previousWrites && dataStore?.previousWrites.map((write) => {
                    return (
                        rows(write)
                    )
                })}
            </Box>
        </Box>
    );
}));

export default PreviousWrites;

