import React from 'react';
import { observer } from "mobx-react-lite";
import { useInstance } from "react-ioc";
import ChangeWriteDataStore from '../store/ChangeWriteData.data.store';
import ButtonMain from '../../../../../_components/UI/Buttons/ButtonMain';
import ChangeWriteViewStore from '../store/ChangeWriteView.view.store';

const Latness = observer(() => {
    const dataStore = useInstance(ChangeWriteDataStore)
    const viewStore = useInstance(ChangeWriteViewStore)

    const handleOpenCreateLatnessModal = () => {
        viewStore.setOpenCreateLatness(true)
    }

    const isPatientCome = () => {
        if (dataStore.writeData?.status?.id==="4" || dataStore.writeData?.status?.name==="Пришел"){
            return true
        }
        return false
    }

    return (
        <>
            {isPatientCome() &&
                <ButtonMain
                    title=" Задать опоздание"
                    sx={{mt: 2}}
                    fullWidth
                    onClick={handleOpenCreateLatnessModal}
                />
            }
        </>
    )
})

export default Latness