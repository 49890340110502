import { makeAutoObservable, toJS } from "mobx";
import {
    SMS_TEMPLETES,
    SMS_TYPES,
    WRITE_EXIST_SMS
} from "../../../queries/modal.queries";
import {
    client
} from "../../../../../_common/http/appoloClient";
import {
    nowSmsSheduleIdentifyer,
    inDaySmsSheduleIdentifyer,
    twoHoursSmsSheduleIdentifyer,
    threeDaysBeforSheduleIdentifyer
} from "../../../../../_common/helpValues/smsConstants";
import {
    smsTemplateConverter
} from "../../../../../_common/helpers/smsTemplatesConverter";
import { isPossibleSend } from "../../../../../_common/helpers/countSmsDateForSheduleSend";
import SmsSendDataCreateWrite from "../store/ChangeWriteData.data.store"
import { inject } from "react-ioc";



export default class SmsSendDataChangeWriteStore {
    dataStore = inject(this, SmsSendDataCreateWrite)

    patientData = {
        name: "",
        surname: "",
        patronymic: "",
        phones: [],
    }

    sendData = {
        smsTexts: [],
        writeData: null,
        savedCastomSend: false,
        phoneId: "",
    }

    sendActivate = {
        activateFindTemplates: false
    }

    reset() {
        this.patientData = {
            name: "",
            surname: "",
            patronymic: "",
            phones: [],
        }
    
        this.sendData = {
            smsTexts: [],
            writeData: null,
            savedCastomSend: false,
            phone: "",
        }
    
        this.sendActivate = {
            activateFindTemplates: false
        }
    }

    get smsTexts () {
        return this.sendData.smsTexts
    }

    setSmsTexts (smsTexts) {
        this.sendData.smsTexts = smsTexts
    }

    get patient () {
        return this.patientData
    }

    setPhone (phoneId) {
        this.sendData.phoneId = phoneId
    }

    setPhones (phones) {
        this.patientData.phones = phones
    }

    setPatient ({surname, name, patronymic, phones}) {
        let isPhoeInPhones = false
        for (let i = 0; i<phones.length; i++){
            if (phones[i].id===this.sendData.phoneId){
                isPhoeInPhones = true
                break
            }
        }
        const ruPhones = []
        phones?.forEach((phone)=>{
            if (phone?.isRus){
                ruPhones.push(phone)
            }
            ruPhones.push(phone)
        })
        this.setPhones(ruPhones)
        this.patientData.surname = surname
        this.patientData.name = name
        this.patientData.patronymic = patronymic
        if (ruPhones.length>0 && (isPhoeInPhones===false || this.sendData.phoneId==="")) {
            this.sendData.phoneId = ruPhones[0].id
        }
    }

    get phone () {
        return this.sendData.phoneId
    }

    get savedCastomSend() {
        return this.sendData.savedCastomSend
    }

    get smsActivateAndText() {
        return {
            phone: this.sendData.phoneId,
            sms: this.sendData.smsTexts
        }
    }

    activateSavedCastomSend() {
        this.sendData.savedCastomSend = true
    }

    get writeData() {
        return this.sendData.writeData
    }

    setWriteData(data) {
        this.sendData.writeData = data
    }

    get activateFindTemplates() {
        return this.sendActivate.activateFindTemplates
    }

    setActivateFindTemplates(value) {
        this.sendActivate.activateFindTemplates = value
    }

    //get smsTemplates

    async getSmsTemplates(writeData) {
        this.setWriteData(writeData)
        await client.query({
            query: SMS_TEMPLETES,
        }).then((data) => {
            let now = "",
                threeDays = "",
                inDay = "",
                twoHours = "",
                isAllFound = false;
            for (let i = 0; i < data.data.allSmsTemplates.length; i++) {
                if (data.data.allSmsTemplates[i].identifier === nowSmsSheduleIdentifyer) {
                    now = smsTemplateConverter(data.data.allSmsTemplates[i].text, writeData)
                }
                if (data.data.allSmsTemplates[i].identifier === threeDaysBeforSheduleIdentifyer) {
                    threeDays = smsTemplateConverter(data.data.allSmsTemplates[i].text, writeData)
                }
                if (data.data.allSmsTemplates[i].identifier === inDaySmsSheduleIdentifyer) {
                    inDay = smsTemplateConverter(data.data.allSmsTemplates[i].text, writeData)
                }
                if (data.data.allSmsTemplates[i].identifier === twoHoursSmsSheduleIdentifyer) {
                    twoHours = smsTemplateConverter(data.data.allSmsTemplates[i].text, writeData)
                }
                if (now.length > 0 && inDay.length > 0 && twoHours.length > 0 && threeDays.length > 0) {
                    isAllFound = true
                }
                if (isAllFound) {
                    break
                }
            }
            this.sendData.smsTexts.forEach((sms)=>{
                if (sms?.text[0]?.text){
                    if (sms?.name === "сейчас"){
                        sms.text[0].text = now
                    }else if (sms?.name === "за 2 часа до записи") {
                        sms.text[0].text = twoHours
                    }else if (sms?.name === "за день до записи") {
                        sms.text[0].text = inDay
                    }else if (sms?.name === "за 3 дня до записи") {
                        sms.text[0].text = threeDays
                    }
                }
            })
        })
    }

    async getSmsTypes () {
        await client.query({
            query: SMS_TYPES,
        }).then((data) => {
            const smsTypes = data?.data?.smsTypes?.map((type)=>{
                if (type?.name==="сейчас"){
                    return({...type, permission: false, text: [{new: true, text: ""}]})
                }else {
                    return({...type, permission: false, text: []})
                }
                })
            this.setSmsTexts(smsTypes)
        })
    }

    getCreatedSms (id) {
        return new Promise((resolve)=>{
            client.query({
                query: WRITE_EXIST_SMS,
                variables:{
                    scheduleEntryId: id
                }
            }).then((data) => {
                let smsArr = []
                data.data.smsByScheduleEntry.forEach((sms)=>{
                    if (sms?.smsType?.id){
                        const hasType = smsArr.findIndex(obj => obj.id === sms?.smsType?.id)
                        if (hasType===-1){
                            smsArr.push({
                                id: sms?.smsType?.id,
                                name: sms.smsType.name,
                                permission: true,
                                text: [{
                                    new: false, 
                                    text: sms?.text, 
                                    forDelete: false, 
                                    id: sms.id, 
                                    status: sms?.status,
                                    dispatch: sms?.dispatch
                                }]
                            })
                        }else {
                            smsArr[hasType]?.text.push({
                                new: false, 
                                text: sms?.text, 
                                forDelete: false, 
                                id: sms.id,
                                status: sms?.status,
                                dispatch: sms?.dispatch
                            })
                        }
                    }
                    
                })
                smsArr.forEach((sms)=>{
                    const hasType = this.sendData.smsTexts.findIndex(obj => obj.id === sms.id)
                    if (hasType!==-1){
                        this.sendData.smsTexts[hasType] = sms
                    }
                })
            }).then(()=>{
                resolve(true)
            })
        })
        
    }

    switchSmsPermission(index) {
        this.sendData.smsTexts[index].permission = !this.sendData.smsTexts[index].permission
    }

    changeSmsText(indexGroup, indexText, text) {
        this.sendData.smsTexts[indexGroup].text[indexText].text = text
    }

    sddSms(index) {
        this.sendData.smsTexts[index].text.push({new: true, text: ""})
    }

    deleteSms(gropIndex, textIndex) {
        if (this.sendData.smsTexts[gropIndex].text[textIndex].new){
            this.sendData.smsTexts[gropIndex].text.splice(textIndex, 1)
        }else{
            this.sendData.smsTexts[gropIndex].text[textIndex].forDelete = true
        }
        
    }

    //give sms for send

    get smsForSend() {
        let sms = this.sendData.smsTexts.filter((sms)=> sms.permission && isPossibleSend(this.dataStore.date, this.dataStore.timeStart, sms.name))
        return {
            phone: this.sendData.phoneId,
            messages: sms,
        }
    }

    get smsQtyForSend() {
        const switchType = (type) => {
            switch (type) {
                case "за 2 часа до записи":
                    return "2ч"
                case "за день до записи":
                    return "д"
                case "за 3 дня до записи":
                    return "3д"
                case "сейчас":
                    return "с"
                default:
                    return "Нет типа"
            }
        }
        const smsQtyArr = this.sendData.smsTexts?.map((type)=>{
            const smsQty = type?.text?.length>0 && type?.text?.reduce((acc, text) => {
                if (text?.new){
                    if (text?.text?.length>0 && type?.permission){
                        return  acc+1
                    }else return  acc
                }else{
                    if (!text?.forDelete){
                        return  acc+1
                    }else return acc
                }
            }, 0)
            return {
                type: type?.name,
                qty: smsQty || null
            }
        })
        const smsQtyStr = smsQtyArr?.length>0 && smsQtyArr?.reduce((acc, sms) => acc+(sms.qty ? ` ${switchType(sms.type)}-${sms.qty}` : ""),"")
        const smsQtyLongNameStr = smsQtyArr?.length>0 && smsQtyArr?.reduce((acc, sms) => acc+(sms.qty ? ` ${sms.type} - ${sms.qty},` : ""),"")
        return {shortName: smsQtyStr, longName: smsQtyLongNameStr}
    }


    constructor() {
        makeAutoObservable(this)
    }

}