import { client } from "../http/appoloClient";
import { START_PHONE_CALL } from "./mutations/startPhoneCall";

export default function startPhoneCall(centerNumber, recipientPhoneOrPhoneId, type = "number") {
    return new Promise((resolve, reject) => {
        const returnError = (err) => {
            if (err==="Номер, на который звоним должен начинаться с 7 и иметь 11 цифр!"){
                reject(err)
                alert("Неверный номер, номер должен начинатся с 7 и иметь 11 цифр")
            }else {
                reject(err)
                alert("Возникла ошибка при попытке набрать номер")
            }
        }
        if (type === "number") {
            client.mutate({
                mutation: START_PHONE_CALL,
                variables: {
                    input: {
                        internalNumber: centerNumber,
                        receiverNumber: recipientPhoneOrPhoneId,
                    },
                },
            }).catch((err)=>{
                returnError(err)
            }).then((data)=>{
                if (data?.data?.startCall?.ok){
                    resolve(data?.data?.startCall)
                }else if (data?.data?.startCall?.errors){
                    returnError(data?.data?.startCall?.errors)
                }
            })
        } else if (type === "id") {
            client.mutate({
                mutation: START_PHONE_CALL,
                variables: {
                    input: {
                        internalNumber: centerNumber,
                        receiverNumberId: recipientPhoneOrPhoneId,
                    },
                },
            }).catch((err)=>{
                returnError(err)
            }).then((data)=>{
                if (data?.data?.startCall?.ok){
                    resolve(data?.data?.startCall)
                }else if (data?.data?.startCall?.errors){
                    returnError(data?.data?.startCall?.errors)
                }
            })
        }
    })

}