import React from 'react';
import {createRoot} from 'react-dom/client';
import reportWebVitals from './vendor/reportWebVitals';
import {App} from "./App";
import {Theme} from "./_common/theme/theme";
import {ApolloProvider} from "@apollo/client";
import {client} from "./_common/http/appoloClient";

const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Theme>
      <ApolloProvider client={client}>
        <App/>
      </ApolloProvider>
    </Theme>
  </React.StrictMode>,
)
reportWebVitals();